import {PersonaService} from '../persona.service';

/**
 * EstimationModel
 *
 * @author Sebastian Pohle
 * @version 1.0
 * @since 05.09.2019
 *
 * The estimation model class provides static functions to calculate the annuity factor
 * or the monthly payment amount depending on a single payment amount.
 */
export class EstimationModel {

  private static intersection = -30.6260809836364;
  private static ageIn2019 = 0.187978831313832;
  private static inAgeIn2019 = -1.92708177240673;
  private static pensionEntry = 0.888004972627014;

  private static factorPayoutAmount = 10000;

  /**
   * This method calculates the annuity factor of a persona. The factor is calculated in the following manner:
   * Schnittpunkt + (Alter in 2019 * Alter der Person)
   *              + (In(Alter der Person) * Ln(Alter der Person))
   *              + (Renteneintritt * Alter Renteneintritt)
   * @param startOfPension  The age of the persona in which he/she/it will retire.
   * @param personaService  The persona service necessary to obtain the currently active persona.
   * @returns Returns a value which equals the lifelong monthly payment of 10.000€
   */
  public static calculateAnnuityFactor(startOfPension: number, personaService: PersonaService): number {

    if (!personaService) {
      throw new Error('Could not calculate the annuity factor because no personaService has been provided.');
    }

    const persona = personaService.getPersona();
    if (!persona) {
      throw new Error('Could not calculate the annuity factor because no persona has been provided');
    }

    // Get the current persona from the session storage
    const personaAge = personaService.getAgeFromBirthdate(persona.birthdate);
    return this.intersection
      + (this.ageIn2019 * personaAge)
      + (this.inAgeIn2019 * Math.log(personaAge))
      + (this.pensionEntry * startOfPension);
  }

  /**
   * This method converts the given singlePaymentAmount with the annuityFactor into a new amount
   * which will be payed out lifelong on a monthly base.
   * @param singlePaymentAmount The single payment amount which needs to be converted.
   * @param annuityFactor       The factor by which the conversion will be done.
   * @returns Returns the lifelong monthly payout amount.
   */
  public static getMonthlyAmount(singlePaymentAmount: number, annuityFactor: number): number {
    return singlePaymentAmount * (annuityFactor / this.factorPayoutAmount);
  }
}
