import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './_authentication/auth.guard';
import * as RoutingRoutes from './routes';

import {GrvComponent} from './pages/grv/grv.component';
import {BavComponent} from './pages/bav/bav.component';
import {PavComponent} from './pages/pav/pav.component';
import {PaymentMethodComponent} from './pages/payment-method/payment-method.component';
import {HomeComponent} from './pages/home/home.component';
import {AboutComponent} from './pages/about/about.component';
import {HelpComponent} from './pages/help/help.component';
import {LegalNoticeComponent} from './pages/legal-notice/legal-notice.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {LoginComponent} from './pages/login/login.component';
import {PersonaSelectionComponent} from './pages/persona-selection/persona-selection.component';
import {DetailsComponent} from './pages/details/details.component';
import {InfoComponent} from './pages/info/info.component';

const routes: Routes = [
  {
    path: 'payment-method',
    data: {breadcrumb: 'Zahlungsart', title: 'Gesamte Rente'},
    component: PaymentMethodComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'grv',
    data: {breadcrumb: 'Gesetzliche Rente', title: 'Gesetzliche Rente'},
    component: GrvComponent
  }, {
    path: 'bav',
    data: {breadcrumb: 'Betriebliche Vorsorge', title: 'Betriebliche Vorsorge'},
    component: BavComponent
  }, {
    path: 'pav',
    data: {breadcrumb: 'Private Vorsorge', title: 'Private Vorsorge'},
    component: PavComponent
  }, {
    path: 'details/:id',
    data: {breadcrumb: 'Details', title: 'Vertragsdetails'},
    component: DetailsComponent
  },
  {
    path: 'glossar',
    loadChildren: './pages/glossar/glossar.module#GlossarModule',
    data: {title: 'Glossar'}
  },
  {
    path: 'about',
    data: {breadcrumb: 'Über uns', title: 'Über uns'},
    component: InfoComponent
  },
  {
    path: 'tour',
    data: {breadcrumb: 'Tour', title: 'Tour'},
    component: HelpComponent
  },
  {
    path: 'legal',
    data: {breadcrumb: 'Impressum', title: 'Impressum'},
    component: LegalNoticeComponent
  },
  {
    path: 'privacy',
    data: {breadcrumb: 'Datenschutz', title: 'Datenschutz'},
    component: PrivacyComponent
  },
  {
    path: RoutingRoutes.Routes.LOGIN_ROUTE,
    data: {breadcrumb: 'Login', title: 'Anmelden'},
    component: LoginComponent
  },
  {
    path: RoutingRoutes.Routes.LOGOUT_ROUTE,
    data: {breadcrumb: 'Logout', title: 'Abmelden'},
    component: LoginComponent
  },
  {
    path: 'selectPersona',
    data: {breadcrumb: 'Auswahl Persona', title: 'Persona-Auswahl'},
    component: PersonaSelectionComponent
  },
  {
    path: RoutingRoutes.Routes.HOME_ROUTE,
    data: {breadcrumb: 'Willkommen', title: 'Startseite'},
    pathMatch: 'full',
    component: HomeComponent
  },
  {
    path: RoutingRoutes.Routes.INFO_ROUTE,
    data: {breadcrumb: 'Projektinformationen', title: 'Projektinformationen rentencheck.de'},
    pathMatch: 'full',
    component: InfoComponent
  },
  {
    path: '',
    pathMatch: 'full',
    component: InfoComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: RoutingRoutes.Routes.INFO_ROUTE
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled'
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
