import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccumulatedBavComponent} from './per-institution/accumulated-bav/accumulated-bav.component';
import {AccumulatedPavComponent} from './per-institution/accumulated-pav/accumulated-pav.component';
import {AccumulatedGrvComponent} from './per-institution/accumulated-grv/accumulated-grv.component';
import {AccumulatedPensionComponent} from './accumulated-pension.component';
import {PerInstitutionComponent} from './per-institution/per-institution.component';
import {AccordionModule} from 'primeng/primeng';
import {TableSummaryComponent} from './per-payment-type/table-summary/table-summary.component';
import {DbkDataViewModule, DbkInfoboxModule} from '@webgui-ng/components';
import {TableSummaryDemoComponent} from './per-payment-type/table-summary-demo/table-summary-demo.component';
import {TableSummaryColumnComponent} from './per-institution/table-summary-column/table-summary-column.component';
import {TableSummaryColumnDemoComponent} from './per-institution/table-summary-column-demo/table-summary-column-demo.component';
// tslint:disable-next-line
import {TableSummaryColumnTableItemComponent} from './per-institution/_components/table-summary-column-table-item/table-summary-column-table-item.component';


@NgModule({
  declarations: [
    AccumulatedBavComponent,
    AccumulatedPavComponent,
    AccumulatedGrvComponent,
    AccumulatedPensionComponent,
    PerInstitutionComponent,
    TableSummaryComponent,
    TableSummaryDemoComponent,
    TableSummaryColumnComponent,
    TableSummaryColumnDemoComponent,
    TableSummaryColumnTableItemComponent
  ],
  exports: [AccumulatedPensionComponent, TableSummaryComponent, TableSummaryColumnDemoComponent, TableSummaryDemoComponent],
  imports: [
    CommonModule,
    AccordionModule,
    DbkDataViewModule,
    DbkInfoboxModule
  ]
})
export class AccumulatedPensionModule {
}
