import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appMenubar]'
})
export class MenubarDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
