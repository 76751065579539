import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {PersonaService} from '../persona.service';
import {Router} from '@angular/router';
import {GoogleChartComponent} from 'angular-google-charts';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss']
})
export class AreaChartComponent implements OnInit, AfterViewInit {

  @ViewChild('singlePayment', {static: false}) public singlePayment: GoogleChartComponent;

  public type = 'SteppedAreaChart';
  public chartData = [
    ['63', 0, 0, 5000],
    ['64', 0, 0, 0],
    ['65', 0, 1000, 10000],
    ['66', 0, 1000, 0],
    ['67', 0, 1000, 0],
    ['68', 2500, 1000, 400],
    ['69', 2500, 1000, 400],
    ['71', 2500, 1000, 400],
    ['72', 2500, 1000, 400],
    ['73', 2500, 1000, 400],
    ['74', 2500, 1000, 400],
    ['75', 2500, 1000, 400],
    ['76', 2500, 1000, 400],
    ['78', 2500, 1000, 400],
    ['79', 2500, 1000, 400],
    ['80', 2500, 1000, 400],
  ];
  public chartDataCombinationSingleContinous = [
    ['63', 0, 0, 0, 5000],
    ['64', 0, 0, 0, null],
    ['65', 0, 1000, 0, 10000],
    ['66', 0, 1000, 0, null],
    ['67', 0, 1000, 0, null],
    ['68', 2500, 1000, 400, null],
    ['69', 2500, 1000, 400, null],
    ['71', 2500, 1000, 400, null],
    ['72', 2500, 1000, 400, null],
    ['73', 2500, 1000, 400, null],
    ['74', 2500, 1000, 400, null],
    ['75', 2500, 1000, 400, null],
    ['76', 2500, 1000, 400, null],
    ['78', 2500, 1000, 400, null],
    ['79', 2500, 1000, 400, null],
    ['80', 2500, 1000, 400, null],
  ];
  public chartDataContinous = [
    ['63', 0, 0, 0],
    ['64', 0, 0, 0],
    ['65', 0, 0, 0],
    ['66', 0, 1000, 0],
    ['67', 0, 1000, 0],
    ['68', 2500, 1000, 400],
    ['69', 2500, 1000, 400],
    ['71', 2500, 1000, 400],
    ['72', 2500, 1000, 400],
    ['73', 2500, 1000, 400],
    ['74', 2500, 1000, 400],
    ['75', 2500, 1000, 400],
    ['76', 2500, 1000, 400],
    ['78', 2500, 1000, 400],
    ['79', 2500, 1000, 400],
    ['80', 2500, 1000, 400],
  ];
  public chartDataSinglePayments = [
    ['63', 0, 0, 5000],
    ['64', 0, 0, 0],
    ['65', 0, 0, 30000],
    ['65', 0, 0, 0],
    ['66', 0, 0, 0],
    ['67', 0, 0, 0],
    ['68', 0, 15000, 0],
    ['69', 0, 0, 0],
    ['71', 0, 0, 0],
    ['72', 0, 10000, 0],
    ['73', 0, 0, 0],
    ['74', 0, 0, 0],
    ['75', 0, 0, 0],
    ['76', 0, 0, 0],
    ['78', 0, 0, 0],
    ['79', 0, 0, 0],
    ['80', 0, 0, 0],
  ];
  public columnNames = [
    'Jahr',
    'GRV',
    'bAV',
    'pAV'
  ];

  public columnNamesCombinationSingleContinous = [
    'Jahr',
    'GRV',
    'bAV',
    'pAV',
    'Einmalzahlung'
  ];
  public options = {
    title: 'Altersvorsorge (überlagert)',
    legend: {position: 'bottom'},
    hAxis: {
      title: 'Alter',
      titleTextStyle: {color: '#333'}
    },
    vAxis: {
      minValue: 0
    },
    connectSteps: false,
    colors: ['rgb(0, 157, 224)', 'rgb(0, 224, 157)', 'rgb(157, 224, 0)'],
    isStacked: false,
  };
  public options2 = {
    title: 'Altersvorsorge (addiert)',
    legend: {position: 'bottom'},
    hAxis: {
      title: 'Alter',
      titleTextStyle: {color: '#333'}
    },
    vAxis: {
      minValue: 0
    },
    connectSteps: false,
    colors: ['rgb(0, 157, 224)', 'rgb(0, 224, 157)', 'rgb(157, 224, 0)'],
    isStacked: true,
  };
  public optionsSC = {
    legend: {position: 'bottom'},
    hAxis: {
      title: 'Alter',
      titleTextStyle: {color: '#333'}
    },
    vAxis: {
      minValue: 0
    },
    connectSteps: false,
    colors: ['rgb(0, 157, 224)', 'rgb(0, 224, 157)', 'rgb(157, 224, 0)', '#B20000'],
    isStacked: true,
  };
  public options3 = {
    title: 'Altersvorsorge (stacked percent 100%)',
    legend: {position: 'bottom'},
    hAxis: {
      title: 'Alter',
      titleTextStyle: {color: '#333'}
    },
    vAxis: {
      minValue: 0
    },
    connectSteps: false,
    colors: ['rgb(0, 157, 224)', 'rgb(0, 224, 157)', 'rgb(157, 224, 0)'],
    isStacked: 'percent',
  };

  public width = 1200;
  public height = 700;

  public widthP = 800;
  public heightP = 600;

  public titleContinousPayment = 'Fortlaufende Zahlungen';
  public titleSinglePayment = 'Einmalzahlungen';
  public titleCombinationSingleContinous = 'Farbliche Unterscheidung Einmalzahlung und Fortlaufende Zahlung';

  public dynamicResize = true;

  constructor(private personaService: PersonaService, private router: Router) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  drawChart(selector: string) {
  }

}
