import {Component, OnInit} from '@angular/core';
import * as institution from '../institution.Institution';
import {Persona} from '../persona.Persona';
import {DbkDataViewColumnConfigData} from '@webgui-ng/components/lib/dbk-data-view/dbk-data-view-column-config-data';
import {Observable} from 'rxjs';
import {PersonaService} from '../persona.service';
import {MiscLabels} from '../misc.labels';
import {Institution} from '../institution.Institution';
import {PaymentType} from '../pensionService/enums/payment-type.enum';


@Component({
  selector: 'app-payment-method-list',
  templateUrl: './payment-method-list.component.html',
  styleUrls: ['./payment-method-list.component.scss']
})
export class PaymentMethodListComponent implements OnInit {

  MiscLabels = MiscLabels;
  InstitutionLabels = institution.InstitutionLabels;
  InstitutionTypeLabels = institution.InstitutionTypeLabels;

  persona: Persona;
  // Column configuration for the <dbk-data-view>
  columnsConfigData: DbkDataViewColumnConfigData[] = [
    {
      label: institution.InstitutionLabels.name,
      dataKey: 'name',
      columnClasses: 'text-center'
    },
    {
      label: institution.InstitutionLabels.retirementAge,
      dataKey: 'retirementAge',
      columnClasses: 'dbk-last-visible-md-column'
    },
    {
      label: institution.InstitutionLabels.paymentCycle,
      dataKey: 'paymentCycle'
    },
    {
      label: institution.InstitutionLabels.safePaymentsIfContinued,
      dataKey: 'value1',
    },
    {
      label: institution.InstitutionLabels.paymentsAlreadyReached,
      dataKey: 'value1',
      columnClasses: ''
    },
    {
      label: MiscLabels.contractDetailsLabel,
      dataKey: 'value1',
      columnClasses: 'dbk-last-visible-xxl-column dbk-last-visible-xl-column dbk-last-visible-lg-column dbk-last-visible-md-column'
    },
    {
      label: institution.InstitutionLabels.value1,
      dataKey: 'value1',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value2,
      dataKey: 'value2',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value3_1,
      dataKey: 'value3_1',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value3_2,
      dataKey: 'value3_2',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value3_3,
      dataKey: 'value3_3',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value3_4,
      dataKey: 'value3_4',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value3_5,
      dataKey: 'value3_5',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value3_6,
      dataKey: 'value3_6',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.productName,
      dataKey: 'productName',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.serviceType,
      dataKey: 'serviceType',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.dateOfEntry,
      dataKey: 'dateOfEntry',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.dateOfExit,
      dataKey: 'dateOfExit',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.annuityFactorGuaranteed,
      dataKey: 'annuityFactorGuaranteed',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.annuityFactorNotGuaranteed,
      dataKey: 'annuityFactorNotGuaranteed',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.taxAndSocialInsuranceContributions,
      dataKey: 'taxAndSocialInsuranceContributions',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.paymentCycleYears,
      dataKey: 'paymentCycleYears',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.additionalInformation,
      dataKey: 'additionalInformation',
      columnClasses: 'dbk-hidden-xxl-down'
    }
  ];

  private persona$: Observable<Persona>;
  public tableData = [];

  /**
   * Constructor of this statutory component.
   * @param personaService Service for persona data access
   */
  constructor(private personaService: PersonaService) {
  }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   */
  ngOnInit() {
    this.persona$ = this.personaService.getPersona$();
    this.persona = this.personaService.getPersona$().value;
    this.prepareInstitutionData();
  }


  /**
   * This method will be triggered in case that the persona object has changed.
   * It analyzes the new object and collects all institutions which then will be shown
   * inside the components table.
   */
  private prepareInstitutionData(): void {
    const columnKeys = ['bAV', 'pAV', 'GRV'];
    columnKeys.forEach((columnKey) => {
      // Obtain the institutions for the given column.
      const institutionType = { institution: columnKey };
      const institutions = this.persona[columnKey].institutions;
      institutions.forEach((institutionEntry) => {
        // Add the institution to the table data so that it can be visualized inside this component.
        // We also additionally add the current institution (grv,bav,pav) to the object, so that the
        // InstitutionHelper knows how to calculate the sum-values for the view.
        this.tableData.push(Object.assign(institutionEntry, institutionType));
      });
    });

    // Sort the institutions primarily by payment type (paymentCycle) and afterwards by
    // retirement age.
    this.tableData.sort((firstEntry: Institution, secondEntry: Institution) => {

      // If the payment cycles match, we now sort by retirement age.
      if (firstEntry.paymentCycle === secondEntry.paymentCycle) {
        const getAgeInNumber = (age) => {
          if (typeof age === 'string') {
            return parseInt(age, 10);
          } else {
            return age;
          }
        };
        return getAgeInNumber(firstEntry.retirementAge) - getAgeInNumber(secondEntry.retirementAge);
      }

      // Defined sort order: einmalig, periodisch, lebenslang, null (all others)
      const getTypeValue = (paymentCycle) => {
        switch (paymentCycle) {
          case PaymentType.single: return 0;
          case PaymentType.periodic: return 1;
          case PaymentType.lifelong: return 2;
          default: return 99;
        }
      };
      return getTypeValue(firstEntry.paymentCycle) - getTypeValue(secondEntry.paymentCycle);
    });
  }
}
