import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PersonaListComponent} from './personas/persona-list/persona-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CardModule} from 'primeng/card';
import {ButtonModule} from 'primeng/button';
import {
  AccordionModule,
  BreadcrumbModule,
  ChartModule,
  InputSwitchModule,
  MessageService,
  RadioButtonModule,
  SelectButtonModule,
  TabMenuModule,
  TabViewModule
} from 'primeng/primeng';
import {
  DbkApplicationModule,
  DbkButtonModule,
  DbkCalendarModule,
  DbkDataViewModule,
  DbkDividerModule,
  DbkGuidedTourModule,
  DbkHelpModule,
  DbkInfoboxModule,
  DbkPanelModule,
  DbkResponsiveContainerModule
} from '@webgui-ng/components';
import {TableModule} from 'primeng/table';
import {registerLocaleData} from '@angular/common';
import localeDEDE from '@angular/common/locales/de';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ChartComponent} from './chart/chart.component';
import {AreaChartComponent} from './area-chart/area-chart.component';
import {GoogleChartsModule} from 'angular-google-charts';
import {AccumulatedPensionModule} from './accumulated-pension/accumulated-pension.module';
import {PersonaComponent} from './personas/persona/persona.component';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {STORAGE_SERVICE, StorageService} from './storageService/storage.service';
import {SESSION_STORAGE} from 'ngx-webstorage-service';
import {PensionSummaryModule} from './pension-summary/pension-summary.module';
import {LoginComponent} from './pages/login/login.component';
import {PersonaSelectionComponent} from './pages/persona-selection/persona-selection.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {LegalNoticeComponent} from './pages/legal-notice/legal-notice.component';
import {AboutComponent} from './pages/about/about.component';
import {HelpComponent} from './pages/help/help.component';
import {GrvComponent} from './pages/grv/grv.component';
import {BavComponent} from './pages/bav/bav.component';
import {PavComponent} from './pages/pav/pav.component';
import {ToastModule} from 'primeng/toast';
import {MenubarDirective} from './menubar.directive';
import {HomeModule} from './pages/home/home.module';
import {ChartsModule} from './charts/charts.module';
import {SettingsToolboxModule} from './shared/settings-toolbox/settings-toolbox.module';
import {PensionModule} from './pension/pension.module';
import {PaymentMethodListComponent} from './payment-method-list/payment-method-list.component';
import {PaymentMethodComponent} from './pages/payment-method/payment-method.component';
import {PaymentMethodTableComponent} from './payment-method-list/payment-method-table/payment-method-table.component';
import {VisualizationToolsModule} from './visualization-tools/visualization-tools.module';
import {DetailsComponent} from './pages/details/details.component';
import {InstitutionComponent} from './institution/institution.component';
import {ContractComponent} from './contract/contract.component';
import {LoginFormComponent} from './_components/loginForm/login-form.component';
import {HttpClientModule} from '@angular/common/http';
import {fakeBackendProvider} from './_authentication/fake-backend';
import {GlossarModule} from './pages/glossar/glossar.module';
import {InternalMessageService} from './shared/internal-message-service/internal-message.service';
import {AgencyLogoModule} from './_components/agency-logo/agency-logo.module';
import {StockFotoModule} from './_components/stock-foto/stock-foto.module';
import {TitleService} from './shared/titleService/title.service';
import {InfoComponent} from './pages/info/info.component';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';

registerLocaleData(localeDEDE);

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
      name: 'cookieconsent_status',
      // domain: 'localhost',
      domain: 'sdavi-wa.azurewebsites.net',
      path: '/',
      expiryDays: 365
    },
    position: 'bottom-right',
    theme: 'classic',
    palette: {
      popup: {
        background: '#003f6f',
        text: '#ffffff',
        link: '#ffffff'
      },
      button: {
        background: '#009de0',
        text: '#ffffff',
        border: 'transparent'
      }
    },
    type: 'info',
    content: {
      message: 'Wir verwenden Cookies, um Ihnen den bestmöglichen Service zu bieten.',
      dismiss: 'Hinweis gelesen',
      deny: 'Refuse cookies',
      link: 'Weitere Informationen',
      href: 'legal',
      policy: 'Cookie Policy'
    }
  };

@NgModule({
  declarations: [
    AppComponent,
    PersonaListComponent,
    BavComponent,
    PavComponent,
    ChartComponent,
    AreaChartComponent,
    PersonaComponent,
    GrvComponent,
    FooterComponent,
    HeaderComponent,
    BreadcrumbComponent,
    LoginComponent,
    PersonaSelectionComponent,
    PrivacyComponent,
    LegalNoticeComponent,
    AboutComponent,
    HelpComponent,
    PaymentMethodComponent,
    GrvComponent,
    BavComponent,
    PavComponent,
    MenubarDirective,
    PaymentMethodListComponent,
    PaymentMethodComponent,
    PaymentMethodTableComponent,
    LoginFormComponent,
    PaymentMethodTableComponent,
    DetailsComponent,
    InstitutionComponent,
    ContractComponent,
    InfoComponent
  ],
  entryComponents: [HeaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CardModule,
    ButtonModule,
    RadioButtonModule,
    DbkResponsiveContainerModule,
    TableModule,
    DbkButtonModule,
    DbkDataViewModule,
    BrowserAnimationsModule,
    DbkApplicationModule,
    SelectButtonModule,
    TabMenuModule,
    DbkHelpModule,
    ChartModule,
    AccordionModule,
    GoogleChartsModule,
    DbkDividerModule,
    AccumulatedPensionModule,
    DbkCalendarModule,
    DbkInfoboxModule,
    BreadcrumbModule,
    InputSwitchModule,
    PensionSummaryModule,
    HttpClientModule,
    ReactiveFormsModule,
    PensionSummaryModule,
    ToastModule,
    TabViewModule,
    ChartsModule,
    SettingsToolboxModule,
    HomeModule,
    PensionModule,
    VisualizationToolsModule,
    DbkGuidedTourModule.forRoot(),
    GlossarModule,
    DbkPanelModule,
    GlossarModule,
    AgencyLogoModule,
    StockFotoModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [{
    provide: LOCALE_ID, useValue: 'de'
  },
    {
      provide: STORAGE_SERVICE, useExisting: SESSION_STORAGE
    },
    StorageService,
    fakeBackendProvider,
    StorageService,
    MessageService,
    InternalMessageService,
    TitleService
  ],
  exports: [
    GrvComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
