import { Component, OnInit } from '@angular/core';
import {StorageService} from '../../storageService/storage.service';
import {InternalMessageService} from '../../shared/internal-message-service/internal-message.service';
import {AuthenticationService} from '../../_authentication/authentication.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  public loggedIn = false;

  constructor(private internalMessageService: InternalMessageService,
              private storageService: StorageService,
              private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.loggedIn = this.authenticationService.getCurrentUser$().value !== null;
  }

  public startGuidedTour() {
    // The guided tour requires the user to be logged off. So do it now!
    this.authenticationService.logout();

    // Activate the guided tour so that every component knows what it has to do.
    this.storageService.setGuidedTourActive(true);

    // Start the tour via sending an internal message to the AppComponent. The AppComponent
    // then will then perform any necessary action.
    const message = {
      sender: 'HelpComponent',
      receiver: 'AppComponent',
      content: {
        command: 'startTour'
      }
    };
    this.internalMessageService.sendMessage(message);
  }

}
