import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {formatCurrency} from '@angular/common';
import {PersonaService} from '../../../persona.service';
import {Observable, Subscription} from 'rxjs';
import {AccumulatedPaymentBlocks} from '../../../pensionService/interfaces/accumulated-payment-blocks';
import {PensionService} from '../../../pensionService/pension.service';
import {AccumulatedPension} from '../../../pensionService/interfaces/accumulated-pension';
import {PaymentType} from '../../../pensionService/enums/payment-type.enum';
import {AccumulatedPensionDataPerPaymentTypeTableSummary} from '../table-summary/accumulated-pension-data-per-payment-type-table-summary';
import {Router} from '@angular/router';
import {Persona} from '../../../persona.Persona';
import {StorageService} from '../../../storageService/storage.service';
import {MiscLabels} from '../../../misc.labels';
import {InstitutionType} from '../../../institution.Institution';

@Component({
  selector: 'app-table-summary-demo',
  templateUrl: './table-summary-demo.component.html',
  styleUrls: ['./table-summary-demo.component.scss']
})
export class TableSummaryDemoComponent implements OnInit, OnDestroy {


  @Input() dynamicResize = true;
  @Input() public showDataForColumn: InstitutionType = InstitutionType.all;

  // todo make this reusable
  private UPPER_LIMIT_YEARS = 9999;
  private accumulatedNonSplittedPaymentBlocksSubscription: Subscription;

  private accumulatedPensionBlocks: AccumulatedPaymentBlocks;
  private personaSubscription: Subscription;
  private persona$: Observable<Persona>;
  private persona: Persona;
  private accumulatedPensionDataPerPaymentType: Array<AccumulatedPensionDataPerPaymentTypeTableSummary>;
  private scale: string;

  constructor(private pensionService: PensionService,
              private personaService: PersonaService,
              public router: Router,
              private storageService: StorageService) {
  }

  ngOnInit() {

    this.persona$ = this.personaService.usePersona();
    this.personaSubscription = this.persona$.subscribe(value => this.persona = value);

    this.accumulatedNonSplittedPaymentBlocksSubscription = this.pensionService.getAccumulatedPensionPerPaymentType(this.persona,
      this.showDataForColumn, false).subscribe((accumulatedPensionBlocks: AccumulatedPaymentBlocks) => {
      this.accumulatedPensionBlocks = accumulatedPensionBlocks;
      this.accumulatedPensionDataPerPaymentType = this.getAccumulatedPensionDataPerPaymentType();
    });
  }

  ngOnDestroy(): void {
    if (this.accumulatedNonSplittedPaymentBlocksSubscription) {
      this.accumulatedNonSplittedPaymentBlocksSubscription.unsubscribe();
    }
  }

  /**
   * return accumulated pension per payment type
   */
  public getAccumulatedPensionDataPerPaymentType(): Array<AccumulatedPensionDataPerPaymentTypeTableSummary> {

    const accumulatedPensionDataPerPaymentType: Array<AccumulatedPensionDataPerPaymentTypeTableSummary> = [];
    this.scale = this.storageService.getMonthlyAnnualScale();

    this.accumulatedPensionBlocks.lifelong.forEach((element: AccumulatedPension) => {
      accumulatedPensionDataPerPaymentType.push(this.createPensionData(this.persona.birthdate, element));
    });

    this.accumulatedPensionBlocks.single.forEach((element: AccumulatedPension) => {
      accumulatedPensionDataPerPaymentType.push(this.createPensionData(this.persona.birthdate, element));
    });

    return accumulatedPensionDataPerPaymentType;
  }

  /**
   * return period text
   *
   * @param birthDate The birthdate to use for creating text
   * @param element   The accumulated pension which will used to create the pension data object.
   */
  private createTextPeriod(birthDate: string, element: AccumulatedPension): string {
    const startDateAge = this.personaService.getAgeOfPersonAtDate(birthDate, element.startDate);
    let endDateAge = null;
    if (element.endDate && (element.endDate.getFullYear() < this.UPPER_LIMIT_YEARS)) {
      endDateAge = this.personaService.getAgeOfPersonAtDate(birthDate, element.endDate);
    }

    // Check what kind of text we need to create. The type of text depends on which payment type is used.
    if (element.paymentType === PaymentType.lifelong) {
      return MiscLabels.paymentLifelong.replace('%1', startDateAge.toString());
    } else if (element.paymentType === PaymentType.single) {
      return MiscLabels.paymentSingle.replace('%1', startDateAge.toString());
    } else {
      // Check if both years are available.
      if (!endDateAge) {
        console.log(element);
        throw new Error('No end date has been found for the periodic payments. Check the previous log for details');
      }
      return MiscLabels.paymentPeriodic
        .replace('%1', startDateAge.toString())
        .replace('%2', endDateAge.toString());
    }
  }

  /**
   * create an item of the table summary
   *
   * @param birthDate birthdate of persona
   * @param element   The accumulated pension which will used to create the pension data object.
   */
  private createPensionData(birthDate: string | Date, element: AccumulatedPension) {
    return {
      period: this.createTextPeriod(this.persona.birthdate, element),
      paymentType: this.getNewPaymentTypeText(element.paymentType),
      amount: formatCurrency(element.amount, 'DE', '€')
    };
  }

  /**
   * Depending on the current settings, the text of the payment intervals ('monatlich' or 'jährlich') need to be
   * adjusted. This method evaluates the current payment type and the corresponding settings and returns the appropriate
   * text.
   * @param paymentType The payment type which has to be converted to a string.
   * @returns Returns a more appropriate payment type text.
   */
  private getNewPaymentTypeText(paymentType: string): string {
    if (paymentType === PaymentType.lifelong) {
      // Return: lebenslang monatlich/jährlich
      return MiscLabels.lifelong + ' ' + (this.scale === 'monthly' ? MiscLabels.monthly : MiscLabels.annual);
    } else if (paymentType === PaymentType.periodic) {
      // Return: mehrere Jahre monatlich/jährlich
      return MiscLabels.periodicYears + ' ' + (this.scale === 'monthly' ? MiscLabels.monthly : MiscLabels.annual);
    }
    return paymentType.toString();
  }
}
