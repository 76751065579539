export enum GuidedToursLabels {

  // --------------------------------------------
  // Intro labels
  // --------------------------------------------
// --------------------------------------------
  // Intro labels
  // --------------------------------------------
  menuBar_title = 'Menüleiste',
  menuBar_text = 'Über die Menüleiste navigieren Sie zu den einzelnen Bereichen des Portals.',

  contentArea_title = 'Inhalt',
  contentArea_text = 'Der Inhaltsbereich versorgt Sie mit Informationen über rentencheck.de und die einzelnen '
    + 'Bereiche der Alterssicherung.',

  footer_title = 'Fußbereich',
  footer_text = 'Im Fußbereich finden Sie die Kontaktdaten zu rentencheck.de sowie die Datenschutzerklärung, ein Impressum und '
    + 'weiterführende Informationen.',

  login_title = 'Anmelden',
  login_text = 'Über den Knopf "Anmelden" können Sie sich in Ihrem persönlichen Bereich anmelden.',

  loginForm_title = 'Anmeldemaske',
  loginForm_text = 'Bitte tragen Sie Ihren Benutzernamen und Ihr Passwort zur Anmeldung in Ihrem persönlichen Bereich ein und '
    + 'melden Sich anschließend an.',

  loginForm_login_title = 'Login',
  loginForm_login_text = 'Klicken Sie nun den "Anmelden"-Knopf um sich mit den vorausgefüllten Daten anzumelden.',

  loginForm_persona_title = 'Auswahl Persona',
  loginForm_persona_text = 'Da es sich bei der Plattform bisher um einen Prototypen handelt können Sie hier aus verschiedenen Personen mit '
    + 'repräsentativen Vorsorgebiographien auswählen.',

  // --------------------------------------------
  // Logged in labels
  // --------------------------------------------
  pensionDetails_title = 'Detailinformationen',
  pensionDetails_text = 'Hier können Sie sich über die beiden auswählbaren Reiter Zahlungsart und Auszahlungsquelle Ihre '
    + 'Vorsorgeinformationen unterschiedlich dargestellt ansehen.',

  tablePaymentMethod_title = 'Zahlungsart',
  tablePaymentMethod_text = 'Hier sehen Sie Ihre aktuellen Vorsorgewerte nach Zahlungsart dargestellt. '
    + 'Mögliche Zahlungsarten sind: Einmalige, lebenslang wiederkehrende und periodische Zahlungen. '
    + 'Neben der Art der Zahlung wird auch das Lebensalter angeführt ab welchem diese Zahlung erfolgt.',

  tablePaymentSource_title = 'Auszahlungsquelle',
  tablePaymentSource_text = 'Hier sehen Sie Ihre aktuellen Vorsorgewerte nach Auszahlungsquelle dargestellt. '
    + 'Mögliche Bereiche sind: "Gesetzliche Rente", "Betriebliche Vorsorge" und "Private Vorsorge". '
    + 'Ihre Zahlungen sind den unterschiedlichen Bereichen zugeordnet und nach Zahlungsart getrennt aufgelistet. '
    + 'Neben dem Betrag wird zusätzlich das Alter ab wann Sie die Zahlung erhalten angezeigt.',

  chart_title = 'Darstellung Tabelle/Diagramm',
  chart_text = 'Mit diesem Schalter kann die Darstellung der Vorsorgeinformationen zwischen tabellarischer Darstellung und '
    + 'einem Diagramm umgeschaltet werden',

  settingsToolbox_title = 'Einstellungsbereich',
  settingsToolbox_text = 'Im Einstellungsbereich können Sie die Darstellung Ihrer Zahlungen konfigurieren. '
    + 'Es lassen sich die Darstellung der Beträge von monatlich auf jährlich umstellen. '
    + 'Gleichzeitig können Einmalzahlungen umgerechnet oder ausgeblendet werden.',

  detailedInformation_title = 'Erklärungen und Hinweise',
  detailedInformation_text = 'Unterhalb erhalten Sie zu den oben dargestellten Informationen weiterführende Erklärungen und Hinweise.',

  tableInformation_title = 'Vertragsübersicht',
  tableInformation_text = 'Hier werden Ihre Verträge tabellarisch aufgelistet. Über den Link '
    + '"Vertragsdetails" können Sie die detaillierten Informationen des jeweiligen Vertrages einsehen.',

  menuBarSpecific_title = 'Menüleiste als angemeldeter Benutzer',
  menuBarSpecific_text = 'Sobald Sie als Benutzer angemeldet sind, wird Ihnen ein zusätzlicher Menüpunkt "Gesamte Rente" angezeigt. '
    + 'Zusätzlich zu den allgemeinen Informationen erhalten Sie bei der Auswahl von "Gesetzliche Rente", "Betriebliche Vorsorge" oder '
    + '"Private Vorsorge" auch einen Überblick über Ihre Zahlungen aus dem jeweiligen Bereich.',
}
