import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {Routes} from '../routes';
import {PersonaService} from '../persona.service';
import {Persona} from '../persona.Persona';
import {GuidedToursLabels} from '../shared/guidedTours/guided-tours-labels';
import {ActivatedRoute, Router} from '@angular/router';
import {MiscLabels} from '../misc.labels';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() applicationTitle: string;
  @Input() menuModel: MenuItem[];

  public loginLogoutRoute: string;
  public isLoggedIn = false;
  public welcomeMessage = '';
  public tooltip = '';

  private personaSubscription: Subscription;
  public persona: Persona;

  GuidedToursLabels = GuidedToursLabels;
  Routes = Routes;
  MiscLabels = MiscLabels;

  /**
   * Default constructor.
   * @param personaService          The persona service is used to determine whether or not a persona has been selected (aka logged in).
   * @param router the application router
   */
  constructor(private personaService: PersonaService, private router: Router) {
    this.isLoggedIn = false;
  }


  /**
   * Lifecycle method that will be called if this component gets initialized.
   */
  ngOnInit() {
    this.personaService.usePersona();
    this.personaSubscription = this.personaService.getPersona$().subscribe((persona) => {
      this.persona = persona;
      if (persona) {
        this.isLoggedIn = true;
        this.loginLogoutRoute = Routes.LOGOUT_ROUTE;
        this.welcomeMessage = 'Willkommen ' + this.personaService.getFullname(persona);
        this.tooltip = 'Abmelden';
      } else {
        this.isLoggedIn = false;
        this.loginLogoutRoute = Routes.LOGIN_ROUTE;
        this.welcomeMessage = '';
        this.tooltip = 'Anmelden';
      }
    });
  }


  /**
   * Ng-Lifecycle method for OnDestroy.
   */
  ngOnDestroy(): void {
    if (this.personaSubscription) {
      this.personaSubscription.unsubscribe();
    }
  }

}
