import {Component, Input, OnInit} from '@angular/core';
import {AgencyService} from '../../agency.service';
import {Institution} from '../../institution.Institution';

@Component({
  selector: 'app-agency-logo',
  templateUrl: './agency-logo.component.html',
  styleUrls: ['./agency-logo.component.scss']
})
export class AgencyLogoComponent implements OnInit {

  @Input() public institution: Institution;

  constructor(private agencyService: AgencyService) { }

  ngOnInit() {
  }

}
