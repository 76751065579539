import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PensionComponent} from './pension.component';
import {PavPensionTableComponent} from './pav/pav-pension-table.component';
import {GrvPensionTableComponent} from './grv/grv-pension-table.component';
import {BavPensionTableComponent} from './bav/bav-pension-table.component';
import {AccordionModule, CardModule} from 'primeng/primeng';
import {DbkDataViewModule, DbkDividerModule, DbkHelpModule} from '@webgui-ng/components';
import {VisualizationToolsModule} from '../visualization-tools/visualization-tools.module';
import {PavTableComponent} from './pav/pav-table/pav-table.component';
import {BavTableItemComponent} from './bav/bav-table-item/bav-table-item.component';
import {GrvTableComponent} from './grv/grv-table/grv-table.component';
import {BavTableHeaderComponent} from './bav/bav-table-header/bav-table-header.component';
import {RouterModule} from '@angular/router';
import {AgencyLogoModule} from '../_components/agency-logo/agency-logo.module';


@NgModule({
  declarations: [PensionComponent, PavPensionTableComponent, GrvPensionTableComponent, BavPensionTableComponent, PavTableComponent,
    BavTableItemComponent, GrvTableComponent, BavTableHeaderComponent],
  exports: [
    PensionComponent,
    PavTableComponent,
    GrvPensionTableComponent,
    BavPensionTableComponent,
    PavPensionTableComponent,
  ],
  imports: [
    CommonModule,
    AccordionModule,
    DbkDataViewModule,
    VisualizationToolsModule,
    DbkHelpModule,
    CardModule,
    DbkDividerModule,
    RouterModule,
    AgencyLogoModule
  ]
})
export class PensionModule {
}
