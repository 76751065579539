import {Component, Input, OnInit} from '@angular/core';
import {Persona} from '../../persona.Persona';
import * as institution from '../../institution.Institution';
import {DbkDataViewColumnConfigData} from '@webgui-ng/components/lib/dbk-data-view/dbk-data-view-column-config-data';
import {PersonaService} from '../../persona.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PensionService} from '../../pensionService/pension.service';
import {DbkFormBuilder} from '@webgui-ng/components';
import {FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {MiscLabels} from '../../misc.labels';

@Component({
  selector: 'app-grv-pension-table',
  templateUrl: './grv-pension-table.component.html',
  styleUrls: ['./grv-pension-table.component.scss']
})
export class GrvPensionTableComponent implements OnInit {

  form: FormGroup;

  simpleDateValue: Date;

  persona: Persona;

  @Input() selectedTab = 'false';

  InstitutionLabels = institution.InstitutionLabels;
  InstitutionTypeLabels = institution.InstitutionTypeLabels;
  InstitutionHelpTexts = institution.InstitutionHelpTexts;
  MiscLabels = MiscLabels;

  // Column configuration for the <dbk-data-view>
  columnsConfigData: DbkDataViewColumnConfigData[] = [
    {
      label: institution.InstitutionLabels.name,
      dataKey: 'name',
      columnClasses: 'text-center'
    },
    {
      label: institution.InstitutionLabels.retirementAge,
      dataKey: 'retirementAge',
    },
    {
      label: institution.InstitutionLabels.paymentCycle,
      dataKey: 'paymentCycle',
    },
    {
      label: institution.InstitutionLabels.safePaymentsIfContinued,
      dataKey: 'value1',
    },
    {
      label: institution.InstitutionLabels.paymentsAlreadyReached,
      dataKey: 'value2',
    },
    {
      label: MiscLabels.contractDetailsLabel,
      dataKey: 'value1',
      columnClasses: 'dbk-last-visible-xxl-column dbk-last-visible-xl-column dbk-last-visible-lg-column dbk-last-visible-md-column'
    },
    {
      label: institution.InstitutionLabels.value1,
      dataKey: 'value1',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value2,
      dataKey: 'value2',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value3_1,
      dataKey: 'value3_1',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value3_2,
      dataKey: 'value3_2',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.dateOfEntry,
      dataKey: 'dateOfEntry',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.additionalFiles,
      dataKey: 'additionalFiles',
      columnClasses: 'dbk-hidden-xxl-down'
    },
  ];
  private persona$: Observable<Persona>;
  private personaSubscription: Subscription;


  /**
   * Constructor of this statutory component.
   * @param personaService Service for persona data access
   * @param route The current route which represents the current url
   * @param router The router which allows to navigate and redirect via url manipulation
   * @param pensionService The pension service
   */
  constructor(public personaService: PersonaService,
              public route: ActivatedRoute,
              public router: Router,
              public pensionService: PensionService,
              private fb: DbkFormBuilder) {
  }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   * todo: Evtl. persona as @input here and in bav and pav components
   */
  ngOnInit() {
    this.persona$ = this.personaService.usePersona();
    this.personaSubscription = this.persona$.subscribe(value => this.persona = value);

    this.form = this.fb.group({
      date: [this.simpleDateValue, Validators.required]
    });
  }

}
