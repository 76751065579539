import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {isNullOrUndefined} from 'util';
import {MenuItem} from 'primeng/api';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  readonly home = {icon: 'fa fa-home', routerLink: '/backToStart'};
  menuItems: MenuItem[];

  /**
   * constructor
   *
   * @param router app router
   * @param activatedRoute actual route
   */
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
  }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   */
  ngOnInit(): void {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => this.menuItems = this.createBreadcrumbs(this.activatedRoute.root));
  }

  /**
   * collect breadcrumbs items from route (ActivatedRoute) as param, using url as param
   * return MenuItem[] (breadcrumbs)
   *
   * @param route actual route
   * @param url actual url
   * @param breadcrumbs array of {label, url} obejects
   */
  private createBreadcrumbs(route: ActivatedRoute, url: string = '#', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      if (!isNullOrUndefined(label)) {
        breadcrumbs.push({label, url});
      }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

}
