import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {GlossarComponent} from './glossar.component';
import {EntryLinksComponent} from './_components/entry-links/entry-links.component';
import {EntryListComponent} from './_components/entry-list/entry-list.component';
import {LetterSelectComponent} from './_components/letter-select/letter-select.component';
import {DbkResponsiveContainerModule} from '@webgui-ng/components';
import {StockFotoModule} from '../../_components/stock-foto/stock-foto.module';

// Routes
export const ROUTES: Routes = [
  {
    path: '',
    data: { breadcrumb: 'Glossar' },
    component: GlossarComponent
  },
  {
    path: ':letter',
    data: { breadcrumb: 'Glossar'},
    component: GlossarComponent
  },
];


@NgModule({
  declarations: [
    GlossarComponent,
    EntryLinksComponent,
    EntryListComponent,
    LetterSelectComponent
  ],
  imports: [
    RouterModule.forChild(ROUTES),
    CommonModule,
    DbkResponsiveContainerModule,
    StockFotoModule
  ]
})
export class GlossarModule {
}
