import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {InternalMessage} from './internal-message';

@Injectable({ providedIn: 'root'})
export class InternalMessageService {

  private subject = new Subject<InternalMessage>();

  public sendMessage(message: InternalMessage) {
    this.subject.next(message);
  }

  public clearMessages() {
    this.subject.next(null);
  }

  public getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
