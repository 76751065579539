import {Component, OnInit, ViewChild} from '@angular/core';
import {UIChart} from 'primeng/chart';
import {PersonaService} from '../persona.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {

  @ViewChild('pensionChart', {static: false}) public extendedChart: UIChart;

  data: any;
  optionsStacked: any;

  constructor(private personaService: PersonaService, private router: Router) {
  }

  ngOnInit() {

    this.initChart();
  }

  initChart() {
    this.data = {
      labels: ['65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85',
        '86', '87', '88'],
      datasets: [
        {
          label: 'GRV',
          backgroundColor: 'rgb(0, 157, 224)',
          borderColor: 'rgb(0, 157, 224)',
          data: ['10000', '10000', '10000', '10000', '10000', '10000', '10000', '10000', '10000', '10000', '10000', '10000', '10000',
            '10000', '10000', '10000', '10000', '10000', '10000', '10000', '10000', '10000', '10000', '10000'],

        },
        {
          label: 'bAV',
          backgroundColor: 'rgb(0, 224, 157)',
          borderColor: 'rgb(0, 224, 157)',
          data: ['2000', '2000', '2000', '2000', '2000', '2000', '2000', '2000', '2000', '2000', '2000', '2000', '2000',
            '2000', '2000', '2000', '2000', '2000', '2000', '2000', '2000', '2000', '2000', '2000'],
          fill: '+2'

        },
        {
          label: 'pAV',
          backgroundColor: 'rgb(157, 224, 0)',
          borderColor: 'rgb(157, 224, 0)',
          data: ['5000', '5000', '5000', '5000', '5000', '5000', '5000', '5000', '5000', '5000', '5000', '5000', '5000',
            '5000', '5000', '5000', '5000', '5000', '5000', '5000', '5000', '5000', '5000', '5000'],
          fill: '+2'

        }
      ]
    };

    this.optionsStacked = {
      title: {
        display: true,
        text: 'Alterssicherung',
        fontSize: 16
      },
      legend: {
        position: 'bottom',
        onClick: null,
      },
      animation: false,
      scales: {
        xAxes: [{
          stacked: true
        }],
        yAxes: [{
          stacked: true
        }]
      }

    };
  }

}
