import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PensionService} from '../pensionService/pension.service';
import {PersonaService} from '../persona.service';
import {AccumulatedPensionPerInstitution} from '../pensionService/interfaces/accumulated-pension-per-institution';
import {Observable, Subscription} from 'rxjs';
import {PaymentBlocks} from '../pensionService/interfaces/payment-blocks';
import {AccumulatedPension} from '../pensionService/interfaces/accumulated-pension';
import moment from 'moment';
import {default as institution, Institution, InstitutionType} from '../institution.Institution';
import {formatCurrency} from '@angular/common';
import {PaymentType} from '../pensionService/enums/payment-type.enum';
import {AccumulatedPaymentBlocks} from '../pensionService/interfaces/accumulated-payment-blocks';
import {Router} from '@angular/router';
import {Persona} from '../persona.Persona';

@Component({
  selector: 'app-accumulated-pension',
  templateUrl: './accumulated-pension.component.html',
  styleUrls: ['./accumulated-pension.component.scss']
})
export class AccumulatedPensionComponent implements OnInit, OnDestroy {

  @Input() institutionType: InstitutionType;
  public accumulatedPensionPerInstitution$: Observable<AccumulatedPensionPerInstitution>;
  public accumulatedPensionPerInstitutionSubscription: Subscription;
  public accumulatedPensionPerPaymentTypeSubscription: Subscription;

  public paymentPointInformationSingle: AccumulatedPension[];
  public paymentPointInformationLifelong: AccumulatedPension[];
  public incompleteContracts: Institution[];
  public institutionHeader: string;
  private personaSubscription: Subscription;
  private persona: Persona;
  private persona$: Observable<Persona>;

  constructor(public personaService: PersonaService, private pensionService: PensionService,  public router: Router) {

  }

  /**
   * subscription on pensionService Observable<AccumulatedPensionPerInstitution>
   * generate text for accumulated Pension
   */
  ngOnInit() {


    this.persona$ = this.personaService.usePersona();
    this.personaSubscription = this.persona$.subscribe(value => this.persona = value);
    this.accumulatedPensionPerInstitution$ = this.pensionService.getPensionPerInstitution(
      this.persona, this.institutionType
    );

    this.accumulatedPensionPerInstitutionSubscription =
      this.accumulatedPensionPerInstitution$.subscribe((accumulatedPensionPerInstitution: AccumulatedPensionPerInstitution) => {
        switch (this.institutionType) {
          case InstitutionType.grv: {
            this.paymentPointInformationSingle =
              this.pensionService.getAccumulatedPensionData(accumulatedPensionPerInstitution.paymentBlock_grv, PaymentType.single);
            this.paymentPointInformationLifelong =
              this.pensionService.getAccumulatedPensionData(accumulatedPensionPerInstitution.paymentBlock_grv, PaymentType.lifelong);
            this.incompleteContracts = accumulatedPensionPerInstitution.paymentBlock_grv.incompleteContracts;
            this.institutionHeader = InstitutionType.grv;
            break;
          }
          case InstitutionType.bav: {
            this.paymentPointInformationSingle =
              this.pensionService.getAccumulatedPensionData(accumulatedPensionPerInstitution.paymentBlock_bav, PaymentType.single);
            this.paymentPointInformationLifelong =
              this.pensionService.getAccumulatedPensionData(accumulatedPensionPerInstitution.paymentBlock_bav, PaymentType.lifelong);
            this.incompleteContracts = accumulatedPensionPerInstitution.paymentBlock_bav.incompleteContracts;
            this.institutionHeader = InstitutionType.bav;
            break;
          }
          case InstitutionType.pav: {
            this.paymentPointInformationSingle =
              this.pensionService.getAccumulatedPensionData(accumulatedPensionPerInstitution.paymentBlock_pav, PaymentType.single);
            this.paymentPointInformationLifelong =
              this.pensionService.getAccumulatedPensionData(accumulatedPensionPerInstitution.paymentBlock_pav, PaymentType.lifelong);
            this.incompleteContracts = accumulatedPensionPerInstitution.paymentBlock_pav.incompleteContracts;
            this.institutionHeader = InstitutionType.pav;
            break;
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.accumulatedPensionPerInstitutionSubscription) {
      this.accumulatedPensionPerInstitutionSubscription.unsubscribe();
    }
    if (this.accumulatedPensionPerPaymentTypeSubscription) {
      this.accumulatedPensionPerPaymentTypeSubscription.unsubscribe();
    }
  }

}
