import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LetterSelectComponent} from './_components/letter-select/letter-select.component';
import {Subscription} from 'rxjs';
import {EntryListComponent} from './_components/entry-list/entry-list.component';
import {EntryLinksComponent} from './_components/entry-links/entry-links.component';

@Component({
  selector: 'app-glossar',
  templateUrl: './glossar.component.html',
  styleUrls: ['./glossar.component.scss']
})
export class GlossarComponent implements OnInit, OnDestroy {

  /**
   * Default constructor.
   */
  constructor() {
  }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   */
  ngOnInit() {
  }

  /**
   *
   */
  ngOnDestroy(): void {
  }

}
