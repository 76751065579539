import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PensionSummaryComponent } from './pension-summary.component';
import {AccordionModule} from 'primeng/primeng';
import {DbkDataViewModule} from '@webgui-ng/components';
import { PavSummaryComponent } from './pav-summary/pav-summary.component';
import { GrvSummaryComponent } from './grv-summary/grv-summary.component';
import { BavSummaryComponent } from './bav-summary/bav-summary.component';



@NgModule({
  declarations: [PensionSummaryComponent, PavSummaryComponent, GrvSummaryComponent, BavSummaryComponent],
  imports: [
    CommonModule,
    AccordionModule,
    DbkDataViewModule
  ]
})
export class PensionSummaryModule { }
