import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {Routes} from '../routes';


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  /**
   * Default constructor.
   * @param router                The router which is used to redirect the user to the login page in case that he is not
   *                              allowed to see the current page.
   * @param authenticationService The authentication service used to obtain a reference to the current logged in user.
   */
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  /**
   * The canActivate method which checks whether or not the user is allowed to enter a certain page.
   * @param route The route which got activated by the user.
   * @param state A snapshot of the current router state.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;

    // If a user was found, we assume that he is authorized to access the page.
    if (currentUser) {
      return true;
    }

    // Otherwise he is not allowed to enter it.
    this.router.navigate([Routes.LOGIN_ROUTE], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
