/**
 * Menus
 * @author Sebastian Pohle
 * @since 7.10.2019
 * @version 1.0
 *
 * This class primarily holds the menu structure of our application. Additionally,
 * every menu entry can be extended by an optional identifier which controls whether
 * or not the menu items will be shown to logged-out/logged-in/always to the user.
 */
export class Menus {

  /**
   * The enum describing which requirements a menu entry has in order to
   * be shown in the navigation bar.
   */
  public static MenuItemAuthenticationRequirements = {
    loggedIn: 'loggedIn',
    loggedOut: 'loggedOut',
    irrelevant: 'irrelevant'
  };

  /**
   * The primary data structure which holds all available menu entries.
   */
  public static menuEntries = [
    {
      label: 'Start',
      routerLink: '/home',
      requiresAuthentication: Menus.MenuItemAuthenticationRequirements.irrelevant,
      // icon: 'fa fa-home '
    },
    {
      label: 'Gesamte Rente',
      routerLink: '/payment-method',
      requiresAuthentication: Menus.MenuItemAuthenticationRequirements.loggedIn,
      icon: 'fa fa-database'

    },
    {
      label: 'Gesetzliche Rente',
      routerLink: '/grv',
      requiresAuthentication: Menus.MenuItemAuthenticationRequirements.irrelevant,
      icon: 'fa fa-institution color-grv--medium'
    },
    {
      label: 'Betriebliche Vorsorge',
      routerLink: '/bav',
      requiresAuthentication: Menus.MenuItemAuthenticationRequirements.irrelevant,
      icon: 'fa fa-industry color-bav--medium'
    },
    {
      label: 'Private Vorsorge',
      routerLink: '/pav',
      requiresAuthentication: Menus.MenuItemAuthenticationRequirements.irrelevant,
      icon: 'fa fa-group color-pav--medium'
    },
    {
      label: 'Glossar',
      routerLink: '/glossar',
      requiresAuthentication: Menus.MenuItemAuthenticationRequirements.irrelevant,
      // icon: 'fa fa-book'
    },
    {
      label: 'Tour',
      routerLink: '/tour',
      requiresAuthentication: Menus.MenuItemAuthenticationRequirements.irrelevant,
      // icon: 'fa fa-question'
    },
    /*
    {
      label: 'Logout',
      icon: 'fa fa-sign-out',
      routerLink: '/logout',
      requiresAuthentication: Menus.MenuItemAuthenticationRequirements.loggedIn
    },
    {
      label: 'Login',
      icon: 'fa fa-sign-in',
      routerLink: '/login',
      requiresAuthentication: Menus.MenuItemAuthenticationRequirements.loggedOut
    },
    */
    // {
    //   label: 'Auswahl Personas',
    //   routerLink: '/selectPersona',
    //   requiresAuthentication: Menus.MenuItemAuthenticationRequirements.loggedOut,
    //   icon: 'fa fa-users color-personaSelect--medium'
    // }
  ];
}
