import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import * as institution from '../institution.Institution';
import {MiscLabels} from '../misc.labels';

import {Agency} from '../agency.Agency';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit, OnDestroy {
  @Input() public contract: institution.Institution;
  @Input() public institutionType: string;
  @Input() public agency: Agency;
  InstitutionLabels = institution.InstitutionLabels;
  InstitutionType = institution.InstitutionType;
  MiscLabels = MiscLabels;

  constructor() {}

  ngOnInit() { }

  ngOnDestroy(): void {
  }

}
