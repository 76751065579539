import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {filter, map} from 'rxjs/operators';

const APP_TITLE = 'Rentencheck';
const SEPARATOR = ' > ';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  /**
   * Default constructor.
   *
   * @param router          The router which is used inside the application. We will listen to any events from it in order
   *                        to recon any page-change / navigation action.
   * @param activatedRoute  The current activated route.
   * @param title           The title of the page.
   */
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
  ) {
  }

  /**
   * This private helper method returns the text with the first letter being uppercased.
   * @param text  The text which we will uppercase the first letter.
   * @returns Returns the given text with the first character being uppercased.
   */
  private static ucFirst(text: string) {
    if (!text) {
      return text;
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  /**
   * The initialization method of this service. It should be called once in order to allow the service to attach
   * to the router of the application. It will then listen for any changes from the router in order to provide a
   * dynamic title to the application.
   */
  init() {
    this.router.events.pipe(
      // Filter the events because we are only interested in NavigationEnd events.
      filter((event) => event instanceof NavigationEnd),

      map(() => {
        let route = this.activatedRoute;
        // Iterate through the routing hierarchy (in case there is one) in order to find the very first route.
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),

      // Since there could possibly be several router in a page, we are only interested in the primary one since
      // this one handles the biggest part of our application.
      filter((route) => route.outlet === 'primary'),

      // Obtain the snapshot of the route and further analyze it in order to obtain the new title.
      map((route) => route.snapshot),
      map((snapshot) => {
        // Check if the title has been defined in app-routing.module.ts.
        if (snapshot.data.title) {
          // Check if the page has been called with an id (e.g. the contract details). If so, we add it to the title
          // since it possibly could give the user more information about WHERE he actually is.
          if (snapshot.paramMap.get('id') !== null) {
            return snapshot.data.title + SEPARATOR + snapshot.paramMap.get('id');
          } else if (snapshot.paramMap.get('letter') !== null) {
            // Otherwise, if a letter and maybe fragment have been given (e.g. for the glossar), we append these information
            // into the title.
            return snapshot.data.title + SEPARATOR + snapshot.paramMap.get('letter')
              + (snapshot.fragment ? SEPARATOR + snapshot.fragment : '');
          }
          // If no such id exists, we only return the title.
          return snapshot.data.title;
        } else {
          // If no title has been defined, we take a part of the url to create an approximation of where the user currently
          // is.
          // E.g.
          // - page is "payment-type", the title will be 'Payment-type - Rentencheck'.
          return this.router.url.split('/').reduce((acc, frag) => {
            if (acc && frag) {
              acc += SEPARATOR;
            }
            return acc + TitleService.ucFirst(frag);
          });
        }
      }))
      .subscribe((pathString) => this.title.setTitle(`${pathString} - ${APP_TITLE}`));
  }
}
