import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {AccumulatedPaymentBlocks} from '../../../pensionService/interfaces/accumulated-payment-blocks';
import {PensionService} from '../../../pensionService/pension.service';
import {PersonaService} from '../../../persona.service';
import {Router} from '@angular/router';
// tslint:disable-next-line
import {AccumulatedPension} from '../../../pensionService/interfaces/accumulated-pension';
import {AccumulatedPensionDataPerColumn} from '../../accumulated-pension-data-per-column';
import {InstitutionTypeLabels} from '../../../institution.Institution';
import {Persona} from '../../../persona.Persona';
import {StorageService} from '../../../storageService/storage.service';
import {PaymentType} from '../../../pensionService/enums/payment-type.enum';
import {MiscLabels} from '../../../misc.labels';

@Component({
  selector: 'app-table-summary-column-demo',
  templateUrl: './table-summary-column-demo.component.html',
  styleUrls: ['./table-summary-column-demo.component.scss']
})
export class TableSummaryColumnDemoComponent implements OnInit, OnDestroy {

  private UPPER_LIMIT_YEARS = 9999;

  // todo make reusable
  @Input() public showDataForColumn = 'all';
  private accumulatedPaymentBlocksForAllColumns$: Observable<AccumulatedPaymentBlocks[]>;
  private accumulatedPensionPerInstitutionSubscription: Subscription;
  private accumulatedPensionForAllColumns: AccumulatedPaymentBlocks[];
  private personaSubscription: Subscription;
  private persona: Persona;
  private persona$: Observable<Persona>;
  private accumulatedPensionDataPerColumn: Array<AccumulatedPensionDataPerColumn>;
  private scale: string;

  constructor(
    private pensionService: PensionService,
    private personaService: PersonaService,
    private storageService: StorageService,
    public router: Router
  ) {
  }

  // todo: Die subscription hier muss noch gefixt werden ;)

  ngOnInit() {
    // Retrieve personaID or redirect to persona list
    this.persona$ = this.personaService.usePersona();
    this.personaSubscription = this.persona$.subscribe(value => this.persona = value);

    this.accumulatedPaymentBlocksForAllColumns$ = this.pensionService.getAccumulatedPensionForAllColumns(this.persona, false);
    this.accumulatedPensionPerInstitutionSubscription =
      this.accumulatedPaymentBlocksForAllColumns$.subscribe((accumulatedPensionForAllColumns: AccumulatedPaymentBlocks[]) => {
        this.accumulatedPensionForAllColumns = [];
        switch (this.showDataForColumn) {
          case 'grv':
          case 'bav':
          case 'pav':
            this.accumulatedPensionForAllColumns[this.showDataForColumn] = accumulatedPensionForAllColumns[this.showDataForColumn];
            break;
          default:
            this.accumulatedPensionForAllColumns = accumulatedPensionForAllColumns;
        }
        this.accumulatedPensionDataPerColumn = this.getAccumulatedPensionDataPerColumn();
      });
  }

  ngOnDestroy(): void {
    if (this.accumulatedPensionPerInstitutionSubscription !== undefined) {
      this.accumulatedPensionPerInstitutionSubscription.unsubscribe();
    }
  }

  public getAccumulatedPensionDataPerColumn(): Array<AccumulatedPensionDataPerColumn> {

    const accumulatedPensionDataPerColumn: Array<AccumulatedPensionDataPerColumn> = [];
    this.scale = this.storageService.getMonthlyAnnualScale();

    // Push a fake row as grouping help
    let addEmptyRowEntry = true;

    if ((this.showDataForColumn === 'grv') || (this.showDataForColumn === 'all')) {
      accumulatedPensionDataPerColumn.push({
        fakeLine: true,
        fakeLineClass: 'grv',
        fakeLineContent: '<i class="fa fa-institution color-grv--medium" aria-hidden="true"></i>',
        paymentType: InstitutionTypeLabels.grv,
        age: null,
        amount: null
      });

      // tslint:disable-next-line
      this.accumulatedPensionForAllColumns['grv'].lifelong.forEach((element: AccumulatedPension) => {
        accumulatedPensionDataPerColumn.push({
          paymentType: this.getNewPaymentTypeText(element),
          age: this.createTextPeriod(this.persona.birthdate, element),
          amount: element.amount.toString()
        });
        addEmptyRowEntry = false;
      });
      // tslint:disable-next-line
      this.accumulatedPensionForAllColumns['grv'].single.forEach((element: AccumulatedPension) => {
        accumulatedPensionDataPerColumn.push({
          paymentType: this.getNewPaymentTypeText(element),
          age: this.createTextPeriod(this.persona.birthdate, element),
          amount: element.amount.toString()
        });
        addEmptyRowEntry = false;
      });

      if (addEmptyRowEntry) {
        accumulatedPensionDataPerColumn.push(this.getNoEntryIndicator());
      }
      // tslint:disable-next-line
      if (this.accumulatedPensionForAllColumns['grv'].singlePaymentsHidden) {
        accumulatedPensionDataPerColumn.push(this.getEntryNotShownIndicator());
      }
    }

    if ((this.showDataForColumn === 'bav') || (this.showDataForColumn === 'all')) {
      // Push a fake row as grouping help
      addEmptyRowEntry = true;
      accumulatedPensionDataPerColumn
      .push({
        fakeLine: true,
        fakeLineClass: 'bav',
        fakeLineContent: '<i class="fa fa-industry color-bav--medium" aria-hidden="true"></i>',
        paymentType: InstitutionTypeLabels.bav,
        age: null,
        amount: null
      });

      // tslint:disable-next-line
      this.accumulatedPensionForAllColumns['bav'].lifelong.forEach(
        (element: AccumulatedPension) => {
          accumulatedPensionDataPerColumn.push({
              paymentType: this.getNewPaymentTypeText(element),
              age: this.createTextPeriod(this.persona.birthdate, element),
              amount: element.amount.toString()
            }
          );
          addEmptyRowEntry = false;
        });

      // tslint:disable-next-line
      this.accumulatedPensionForAllColumns['bav'].single.forEach((element: AccumulatedPension) => {
        accumulatedPensionDataPerColumn.push({
          paymentType: this.getNewPaymentTypeText(element),
          age: this.createTextPeriod(this.persona.birthdate, element),
          amount: element.amount.toString()
        });
        addEmptyRowEntry = false;
      });

      if (addEmptyRowEntry) {
        accumulatedPensionDataPerColumn.push(this.getNoEntryIndicator());
      }

      // tslint:disable-next-line
      if (this.accumulatedPensionForAllColumns['bav'].singlePaymentsHidden) {
        accumulatedPensionDataPerColumn.push(this.getEntryNotShownIndicator());
      }
    }


    if ((this.showDataForColumn === 'pav') || (this.showDataForColumn === 'all')) {
      // Push a fake row as grouping help
      addEmptyRowEntry = true;
      accumulatedPensionDataPerColumn.push({
        fakeLine: true,
        fakeLineClass: 'pav',
        fakeLineContent: '<i class="fa fa-group color-pav--medium" aria-hidden="true"></i>',
        paymentType: InstitutionTypeLabels.pav,
        age: null,
        amount: null
      });
      // tslint:disable-next-line
      this.accumulatedPensionForAllColumns['pav'].lifelong.forEach((element: AccumulatedPension) => {
        accumulatedPensionDataPerColumn.push({
          paymentType: this.getNewPaymentTypeText(element),
          age: this.createTextPeriod(this.persona.birthdate, element),
          amount: element.amount.toString()
        });
        addEmptyRowEntry = false;
      });

      // tslint:disable-next-line
      this.accumulatedPensionForAllColumns['pav'].single.forEach((element: AccumulatedPension) => {
        accumulatedPensionDataPerColumn.push({
          paymentType: this.getNewPaymentTypeText(element),
          age: this.createTextPeriod(this.persona.birthdate, element),
          amount: element.amount.toString()
        });
        addEmptyRowEntry = false;
      });
      if (addEmptyRowEntry) {
        accumulatedPensionDataPerColumn.push(this.getNoEntryIndicator());
      }

      // tslint:disable-next-line
      if (this.accumulatedPensionForAllColumns['pav'].singlePaymentsHidden) {
        accumulatedPensionDataPerColumn.push(this.getEntryNotShownIndicator());
      }
    }

    return accumulatedPensionDataPerColumn;
  }

  /**
   * This method returns an object which can be used
   * to indicate that no entries for the current group exist.
   * @returns Returns an object which indicates that no entry for this group exists.
   */
  private getNoEntryIndicator(): AccumulatedPensionDataPerColumn {
    return {
      paymentType: 'Keine Einträge vorhanden',
      age: null,
      amount: null
    };
  }

  private getEntryNotShownIndicator(): AccumulatedPensionDataPerColumn {
    return {
      paymentType: 'Einmalzahlungen ausgeblendet',
      age: null,
      amount: null
    };
  }

  /**
   * Depending on the current settings, the text of the payment intervals ('monatlich' or 'jährlich') need to be
   * adjusted. This method evaluates the current payment type and the corresponding settings and returns the appropriate
   * text.
   * @param element The accumulated pension object that will be used to obtain the current payment type.
   * @returns Returns a more appropriate payment type text.
   */
  private getNewPaymentTypeText(element: AccumulatedPension): string {
    if (element.paymentType === PaymentType.lifelong) {
      // Return: lebenslang monatlich/jährlich
      return MiscLabels.lifelong + ' ' + (this.scale === 'monthly' ? MiscLabels.monthly : MiscLabels.annual);
    } else if (element.paymentType === PaymentType.periodic) {
      // Return: mehrere Jahre monatlich/jährlich
      return MiscLabels.periodicYears + ' ' + (this.scale === 'monthly' ? MiscLabels.monthly : MiscLabels.annual);
    }
    return element.paymentType.toString();
  }


  /**
   * return period text
   *
   * @param birthDate The birthdate to use for creating text
   * @param element   The accumulated pension which will used to create the pension data object.
   */
  private createTextPeriod(birthDate: string, element: AccumulatedPension): string {
    const startDateAge = this.personaService.getAgeOfPersonAtDate(birthDate, element.startDate);
    let endDateAge = null;
    if (element.endDate && (element.endDate.getFullYear() < this.UPPER_LIMIT_YEARS)) {
      endDateAge = this.personaService.getAgeOfPersonAtDate(birthDate, element.endDate);
    }

    // Check what kind of text we need to create. The type of text depends on which payment type is used.
    if (element.paymentType === PaymentType.lifelong) {
      return MiscLabels.paymentLifelong.replace('%1', startDateAge.toString());
    } else if (element.paymentType === PaymentType.single) {
      return MiscLabels.paymentSingle.replace('%1', startDateAge.toString());
    } else {
      // Check if both years are available.
      if (!endDateAge) {
        console.log(element);
        throw new Error('No end date has been found for the periodic payments. Check the previous log for details');
      }
      return MiscLabels.paymentPeriodic
        .replace('%1', startDateAge.toString())
        .replace('%2', endDateAge.toString());
    }
  }
}

