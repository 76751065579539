import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {AuthenticationService} from '../../_authentication/authentication.service';
import {Routes} from '../../routes';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import * as institution from '../../institution.Institution';
import {ContractService} from '../../contract.service';
import {AgencyService} from '../../agency.service';
import {Agency} from '../../agency.Agency';
import {PersonaService} from '../../persona.service';


@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {
  public loggedIn = false;
  public contract: institution.Institution;
  public institutionType: string;
  public agency: Agency;
  private paramSubscription: Subscription;
  private id: number;

  constructor(private contractService: ContractService,
              private agencyService: AgencyService,
              private personaService: PersonaService,
              private authenticationService: AuthenticationService,
              private location: Location,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.loggedIn = this.authenticationService.getCurrentUser$().value !== null;
    this.paramSubscription = this.route.paramMap.subscribe(params => {
      this.id = (+params.get('id'));
      this.contract = this.contractService.getContract(this.id);

      // If there is no contract for this persona with this id we redirect
      if (this.contract === null || this.contract === undefined) {
        this.authenticationService.navigateTo(Routes.HOME_ROUTE);
      }

      this.institutionType = this.contractService.getContractType(this.id);
      this.agency = this.agencyService.getAgency(this.contract.agencyID);
    });
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy(): void {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }

}
