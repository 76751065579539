import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {StorageService} from '../../storageService/storage.service';
import {BehaviorSubject} from 'rxjs';
import {DbkFormBuilder, GuidedTourService} from '@webgui-ng/components';
import {FormGroup} from '@angular/forms';
import {SelectItem} from 'primeng/api';
import {GuidedToursLabels} from '../guidedTours/guided-tours-labels';
import {SelectButton} from 'primeng/primeng';

@Component({
  selector: 'app-settings-toolbox',
  templateUrl: './settings-toolbox.component.html',
  styleUrls: ['./settings-toolbox.component.scss']
})
export class SettingsToolboxComponent implements OnInit, OnChanges {

  @ViewChild('switchTableDiagramm', {static: false}) switchTableDiagramm: SelectButton;

  @Output() handleChangeEmitter = new EventEmitter();

  @Input()
  childTemplate: TemplateRef<any>;

  /**
   * This attributes allows the user to control which settings will be shown and which not.
   * The strings have to separated by a comma.
   *
   * The currently supported settings are:
   * - singleToMonthly
   * - hideSingles
   */
  @Input() showSettings: string;
  public monthlySingleConversionEnabled = false;
  public monthlySingleConversion$ = new BehaviorSubject<boolean>(null);

  public hideSinglePaymentsEnabled = false;
  public hideSinglePayments$ = new BehaviorSubject<boolean>(null);

  public monthlyAnnualScaleEnabled = false;
  public monthlyAnnualScale$ = new BehaviorSubject<boolean>(null);

  // public hideSinglePayments;
  settingsToolboxForm: FormGroup;

  designs: SelectItem[];

  /**
   * The scaleOptions array holds possible values which the user can use to change
   * the scale of the chart and table. Currently it offers the possibility to switch
   * between a monthly and annual scale of the payment amounts.
   */
  public scaleOptions = [
    {label: 'Monatlich', value: 'monthly'},
    {label: 'Jährlich', value: 'annual'}
  ];
  private MONTHLY_SINGLE_CONVERSION_OPTION = 'singleToMonthly';
  private HIDE_SINGLE_PAYMENTS_OPTION = 'hideSingles';
  private MONTHLY_ANNUALY_SCALE_OPTION = 'monthlyAnnualScale';
  GuidedToursLabels = GuidedToursLabels;

  /**
   * Constructor for this settings toolbox.
   * @param storageService  The storage service needed for storing the settings of this toolbox.
   * @param fb DbkFormBuilder
   * @param guidedTourService The service for the guided tour
   */
  constructor(private storageService: StorageService,
              private fb: DbkFormBuilder,
              private guidedTourService: GuidedTourService) {
    this.designs = [];
    this.designs.push({title: 'Tabelle', value: 'table', icon: 'fa fa-table'});
    this.designs.push({title: 'Diagramm', value: 'chart', icon: 'fa fa-bar-chart'});
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.handleChange(changes);
  }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   */
  ngOnInit() {
    // Create the toolbox form.
    this.settingsToolboxForm = this.fb.group({
      design: [this.storageService.getPresentation() === undefined ? 'table' : this.storageService.getPresentation()],
      scale: [this.storageService.getMonthlyAnnualScale() === undefined ? 'monthly' : this.storageService.getMonthlyAnnualScale()],
      monthlySingleConversion: [this.storageService.getSingleMonthlyConversion()],
      hideSinglePayments: [this.storageService.getHideSinglePayments()]
    });

    // this.selectedDesign = (this.storageService.getPresentation() !== undefined) ? this.storageService.getPresentation() : 'table';

    // Analyze the settings in order to distinguish which settings are enabled and which are not.
    this.interpretShowSettings();


    if (this.monthlyAnnualScaleEnabled) {
      this.monthlyAnnualScale$.next(this.settingsToolboxForm.get('scale').value);
      this.settingsToolboxForm.get('scale').valueChanges.subscribe(value => {
        // Disable the monthly to single conversion in case that we selected 'monthly' as the scale.
        if (value === 'annual') {
          this.settingsToolboxForm.get('monthlySingleConversion').setValue(false);
          this.settingsToolboxForm.get('monthlySingleConversion').disable();
        } else {
          this.settingsToolboxForm.get('monthlySingleConversion').enable();
        }
        this.updateScale(value);
      });
      this.updateScale(this.settingsToolboxForm.get('scale').value);
    }

    if (this.monthlySingleConversionEnabled) {
      this.monthlySingleConversion$.next(this.settingsToolboxForm.get('monthlySingleConversion').value);
      this.settingsToolboxForm.get('monthlySingleConversion').valueChanges.subscribe(value => {
        // Disable 'hideSinglePayments' in case that the monthly to single conversion is done.
        if (value === true) {
          this.settingsToolboxForm.get('hideSinglePayments').setValue(false);
          this.settingsToolboxForm.get('hideSinglePayments').disable();
        } else {
          this.settingsToolboxForm.get('hideSinglePayments').enable();
        }
        this.updateMonthlySingleConversion(value);
      });
      this.updateMonthlySingleConversion(this.settingsToolboxForm.get('monthlySingleConversion').value);
    }

    if (this.hideSinglePaymentsEnabled) {
      this.hideSinglePayments$.next(this.settingsToolboxForm.get('hideSinglePayments').value);
      this.settingsToolboxForm.get('hideSinglePayments').valueChanges.subscribe(changes => {
        this.updateHideSinglePayments(changes);
      });
      this.updateHideSinglePayments(this.settingsToolboxForm.get('hideSinglePayments').value);
    }

    if (this.guidedTourService.isTourInProgress()) {
      this.guidedTourService.tourInAction().subscribe((step) => {
        if (step.name === 'loggedIn_chart') {
          // todo does not trigger on change and does not work this way as supposed to
          // this.settingsToolboxForm.get('design').setValue('chart');
          // this.switchTableDiagramm.dotheclick ....;
        }
      });
    }

    if (this.settingsToolboxForm.get('design').value === undefined) {
      this.settingsToolboxForm.get('design').setValue('table');
    }


  }
  private interpretShowSettings(): void {
    const splittedSettings = this.showSettings.toLowerCase().replace(/ /g, '').split(',');
    this.monthlySingleConversionEnabled = splittedSettings.includes(this.MONTHLY_SINGLE_CONVERSION_OPTION.toLowerCase());
    this.hideSinglePaymentsEnabled = splittedSettings.includes(this.HIDE_SINGLE_PAYMENTS_OPTION.toLowerCase());
    this.monthlyAnnualScaleEnabled = splittedSettings.includes(this.MONTHLY_ANNUALY_SCALE_OPTION.toLowerCase());

    if (this.storageService.getSingleMonthlyConversion() === true) {
      this.settingsToolboxForm.get('hideSinglePayments').setValue(false);
      this.settingsToolboxForm.get('hideSinglePayments').disable();
    } else {
      this.settingsToolboxForm.get('hideSinglePayments').enable();
    }

    if (this.storageService.getMonthlyAnnualScale() === 'annual') {
      this.settingsToolboxForm.get('monthlySingleConversion').setValue(false);
      this.settingsToolboxForm.get('monthlySingleConversion').disable();
    } else {
      this.settingsToolboxForm.get('monthlySingleConversion').enable();
    }

  }

  /**
   * This method is triggered in case that the user uses the single to monthly conversion switch.
   * @param event The event which will be emitted in case that the switch is used.
   */
  updateMonthlySingleConversion(event) {
    const isMonthlySingleConversion: boolean = this.settingsToolboxForm.get('monthlySingleConversion').value;
    this.storageService.setSingleMonthlyConversion(isMonthlySingleConversion);
    this.storageService.setHideSinglePayments(isMonthlySingleConversion ? false : this.settingsToolboxForm.get('hideSinglePayments').value);
  }

  /**
   * This method is triggered in case that the user uses the hide single payments switch.
   * @param event The event which will be emitted in case that the switch is used.
   */
  updateHideSinglePayments(event) {
    const isHideSinglePayments: boolean = this.settingsToolboxForm.get('hideSinglePayments').value;
    this.storageService.setHideSinglePayments(isHideSinglePayments);
    this.storageService.setSingleMonthlyConversion(isHideSinglePayments
      ? false
      : this.settingsToolboxForm.get('monthlySingleConversion').value);
  }

  /**
   * This method gets triggered in case that the user changes the current scale factor. Furthermore
   * this method will save the new value inside the storage service and trigger an update to any
   * subscribers.
   * @param event Event data in case that the selectButton has changed.
   */
  public updateScale(event): void {
    this.storageService.setMonthlyAnnualScale(event);
  }

  handleChange(e) {
    this.handleChangeEmitter.emit(e);
  }
  /**
   * This method checks which settings have to be enabled for this toolbox. Settings which are disabled will neither
   * be shown nor saved by this component.
   */

}
