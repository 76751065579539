export enum MiscLabels {
  accResults = 'Kumulierte Ergebnisse:',
  annual = 'jährlich',
  monthly = 'monatlich',
  single = 'einmalig',
  lifelong = 'lebenslang',
  periodicYears = 'mehrere Jahre',
  paymentInterval = 'Zahlungszeitraum',
  contractDetailsLabel = 'Vertragsdetails',
  contractOverview = 'Vertragsübersicht',
  paymentMethod = 'Zahlungsart',
  paymentSingle = 'Mit %1 Jahre',
  paymentPeriodic = 'Von %1 bis %2 Jahre',
  paymentLifelong = 'Ab %1 Jahre',
  headerHome = 'Überblick: So hoch könnte Ihr Einkommen im Ruhestand sein',
  headerGRVComponent = 'Überblick gesetzliche Rente: So hoch könnte Ihr Einkommen im Ruhestand sein',
  headerBAVComponent = 'Überblick betriebliche Vorsorge: So hoch könnte Ihr Einkommen im Ruhestand sein',
  headerPAVComponent = 'Überblick private Vorsorge: So hoch könnte Ihr Einkommen im Ruhestand sein',
  hint_taxes = 'Bitte beachten Sie, dass Sie gegebenenfalls Kranken- und Pflegeversicherungsbeiträge sowie Steuern zahlen müssen.',
  hint_inflation = 'Die Beträge sind wegen des Anstiegs der Lebenshaltungskosten und der damit verbundenen Geldentwertung (Inflation)' +
    ' in ihrer Kaufkraft nicht mit einem heutigen Einkommen in dieser Höhe vergleichbar (Kaufkraftverlust).',
  linkTextPrototypHome = 'Hier geht’s zum Prototyp',
  linkTextAvatare = 'Avatare von freepik - de.freepik.com'
}
