import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SettingsToolboxComponent} from './settings-toolbox.component';
import {InputSwitchModule, SelectButtonModule} from 'primeng/primeng';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DbkFormsModule, DbkGenericFormsModule, DbkGuidedTourModule} from '@webgui-ng/components';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


@NgModule({
  declarations: [SettingsToolboxComponent],
  imports: [
    CommonModule,
    InputSwitchModule,
    FormsModule,
    DbkFormsModule,
    ReactiveFormsModule,
    DbkGenericFormsModule,
    BrowserAnimationsModule,
    SelectButtonModule,
    DbkGuidedTourModule
  ],
  exports: [
    SettingsToolboxComponent
  ]
})
export class SettingsToolboxModule {
}
