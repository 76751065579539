import {Injectable} from '@angular/core';
import PersonasJSON from '../assets/personas.json';
// import PersonasJSON from '../assets/personas_testdata.json';
import {Persona} from './persona.Persona';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import moment from 'moment';
import {StorageService} from './storageService/storage.service';
import {Router} from '@angular/router';
import {Routes} from './routes';

@Injectable({
  providedIn: 'root'
})
export class PersonaService {

  private personas: Persona[];
  private persona$: BehaviorSubject<Persona>;

  constructor(private storageService: StorageService, public router: Router) {
    this.personas = PersonasJSON.Personas;
    this.persona$ = new BehaviorSubject<Persona>(null);
  }

  /**
   * Returns an observable of all the personas
   *
   * @param timeoutMilliseconds You can set a timeout to simulate a delay to backend to increase realistic feel.
   * @return Observable<Persona[]> Returns an observable which holds all persona data that is available right now.
   */
  getPersonas(timeoutMilliseconds: number): Observable<Persona[]> {
    return new Observable(observer => {
      setTimeout(() => {
        observer.next(this.personas);
      }, timeoutMilliseconds);
    });
  }

  /**
   * Returns an observable of a specific persona
   *
   * @return BehaviorSubject<Persona> Returns a subject containing exactly the persona that was requested.
   */
  getPersona$(): BehaviorSubject<Persona> {
    return this.persona$;
  }

  /**
   * Returns the currently logged in persona.
   * @returns Returns the currently logged in persona.
   */
  getPersona(): Persona {
    return this.persona$.value;
  }

  /**
   * Retrieves a Observable<Persona> if there is a persona id stored in session or redirect to home if not
   */
  public usePersona(): Observable<Persona> {
    // Retrieve personaID or redirect to persona list
    const personaID = this.storageService.getPersonaID();
    if (personaID === undefined) {
      this.persona$.next(null);
      return;
    }

    // Update the persona data with the new data in case that the data has changed.
    if (!this.persona$.value || this.persona$.value.id !== personaID) {
      this.persona$.next(this.personas.find(x => x.id === personaID));
    }
    return this.persona$;
  }


  /**
   * Returns the full name of the supplied persona
   *
   * @todo interface definition should be extended and the implementation moved to a persona class which implements the
   *
   * @param persona The persona object which holds the first- and lastname which will be concatenated in this function.
   * @return string Returns the full name (firstname lastname) of the given persona.
   */
  public getFullname(persona: Persona): string {
    return persona.firstname + ' ' + persona.lastname;
  }

  /**
   * Calculates the current age depending on the given birth date.
   *
   * @todo interface definition should be extended and the implementation moved to a persona class which implements the
   *
   * @param birthdateString The birth date e.g. 01.01.1970.
   * @return Returns the age depending on the given birthdate and our fictional year '2018'.
   */
  public getAgeFromBirthdate(birthdateString: string): number {
    // Dont use the current date since this will cause the data (e.g. biography etc.) to be invalid since they contain
    // fixed age values which refer to 2018.
    const currentDate: Date = new Date(Date.now());
    currentDate.setFullYear(2018);
    const birthDate = new Date(
      parseInt(birthdateString.split('.')[2], 10),
      parseInt(birthdateString.split('.')[1], 10) - 1,
      parseInt(birthdateString.split('.')[0], 10));

    const timeDiff = Math.abs(currentDate.getTime() - birthDate.getTime());
    return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
  }

  /**
   * Calculates the current age from a given date of birth
   *
   * @todo interface definition should be extended and the implementation moved to a persona class which implements the
   *
   * @param dateOfBirth The birth date e.g. 01.01.1964
   * @return number of age in years
   */
  public getAgeFromDateOfBirth(dateOfBirth: string | Date): number {
    if (typeof dateOfBirth === 'string') {
      dateOfBirth = new Date(dateOfBirth);
    }

    return moment().diff(dateOfBirth, 'years');
  }

  public getAgeOfPersonAtDate(dateOfBirth: string | Date, atDate: string | Date): number {
    const parseDate = (germanFormattedDate: string) => {
      const dateParts = germanFormattedDate.match(/(\d+)/g);
      // We need to decrement the month by 1 since it starts (as every array) with 0 (0 = jan, 1 = feb, ...)
      return new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
    };

    if (typeof dateOfBirth === 'string') {
      dateOfBirth = parseDate(dateOfBirth);
    }
    if (typeof atDate === 'string') {
      atDate = new Date(atDate);
    }
    return moment(atDate).diff(dateOfBirth, 'years');
  }

  /**
   * Returns the background text of the persona with the correct age in years parsed into the text
   *
   * @todo interface definition should be extended and the implementation moved to a persona class which implements the
   *
   * @param persona THe persona object which holds the necessary information.
   */
  getBackgroundText(persona: Persona) {
    const age = this.getAgeFromBirthdate(persona.birthdate);
    let text: string;
    text = persona.background;
    return text.replace('${age}', age.toString());
  }

}
