import {Injectable} from '@angular/core';
import {Institution, InstitutionType} from './institution.Institution';
import {PensionService} from './pensionService/pension.service';
import {PersonaService} from './persona.service';
import {Persona} from './persona.Persona';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  private persona = null;
  private contracts = [];
  private bavInformationsArray: Institution[] = [];
  private grvInformationsArray: Institution[] = [];
  private pavInformationsArray: Institution[] = [];

  constructor(private pensionService: PensionService,
              private personaService: PersonaService) {
    this.personaService.usePersona().subscribe((persona) => {
      this.persona = persona;
    });
  }

  /**
   * Returns the contract with the given id for the currently logged in persona or null if no contract could be found
   *
   * @param id the id of the contract we want to retrieve
   */
  public getContract(id: number): Institution {
    const contracts = this.pensionService.getInstitutions(this.persona, InstitutionType.all);
    let result = null;
    contracts.forEach((contract: Institution, index: number) => {
      if (contract.id === id) {
        result = contract;
      }
    });

    return result;
  }

  /**
   * Returns the type of contract
   *
   * @param id the identifier of the contract
   */
  public getContractType(id: number): string {
    const bavInformationsArray = PensionService.getInstitutionsFromPersona(this.persona, InstitutionType.bav);
    const grvInformationsArray = PensionService.getInstitutionsFromPersona(this.persona, InstitutionType.grv);
    const pavInformationsArray = PensionService.getInstitutionsFromPersona(this.persona, InstitutionType.pav);
    let included = false;
    included = this.checkForInclusion(id, grvInformationsArray);
    if (included) {
      return InstitutionType.grv.toLowerCase();
    }
    included = this.checkForInclusion(id, bavInformationsArray);
    if (included) {
      return InstitutionType.bav.toLowerCase();
    }
    included = this.checkForInclusion(id, pavInformationsArray);
    if (included) {
     return InstitutionType.pav.toLowerCase();
    }
  }

  /**
   * Checks wether the contract is included in the supplied array of contracts
   *
   * @param id the identifier of the contract
   * @param array the list of contracts to search in
   */
  private checkForInclusion(id: number, array: Institution[]) {
    let included = false;
    array.forEach((contract: Institution, index: number) => {
      if (contract.id === id) {
        included = true;
      }
    });

    return included;
  }
}
