export const INTRODUCTION_TOUR_OPTIONS = {
  steps: ['intro_menuBar@home', 'intro_content@home', 'intro_footer@home',
    'intro_login@home', 'login@login', 'login_persona@login', 'login_button@login']
};

export const LOGGED_IN_TOUR_OPTIONS = {
  steps: ['loggedIn_contentArea@home', 'loggedIn_tablePaymentMethod@home', 'loggedIn_tablePaymentSource@home',
    'loggedIn_toolbox@home', 'loggedIn_chart@home', 'loggedIn_commonInfos@home', 'loggedIn_tableInformation@payment-method',
    'intro_menuBar2@home']
};
