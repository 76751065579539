import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import GlossarJSON from '../../../../../assets/glossar.json';

@Component({
  selector: 'app-entry-list',
  templateUrl: './entry-list.component.html',
  styleUrls: ['./entry-list.component.scss']
})
export class EntryListComponent implements OnInit, OnDestroy {

  private routeSubscription: Subscription;
  public content: Map<string, string>;

  constructor(private route: ActivatedRoute) {
    this.content = new Map<string, string>();
  }

  ngOnInit() {
    this.routeSubscription = this.route.url.subscribe((url) => {
      this.content.clear();

      // The url object contains of several UrlSegments. We are interested in the first one which in the following
      // example will map to the 'X': -- > /glossary/X/
      if (url.length > 0) {
        const contentForLetter = GlossarJSON[url[0].path[0]];
        // tslint:disable-next-line:forin
        for (const key in contentForLetter) {
          this.content.set(key, contentForLetter[key]);
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

}
