import {Injectable} from '@angular/core';
import {PensionService} from '../../../pensionService/pension.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {PensionChartData} from './pension-chart-data';
import {Persona} from '../../../persona.Persona';
import {AccumulatedPension} from '../../../pensionService/interfaces/accumulated-pension';
import {ChartTable, Series} from './chart-table';
import {PaymentType} from '../../../pensionService/enums/payment-type.enum';
import {AccumulatedPaymentBlocks} from '../../../pensionService/interfaces/accumulated-payment-blocks';
import {formatCurrency} from '@angular/common';
import {StorageService} from '../../../storageService/storage.service';
import {InstitutionType} from '../../../institution.Institution';

/**
 * AdditionalChartInformation
 * @author Sebastian Pohle
 * @since 28.08.2019
 * @version 1.0
 *
 * This class is used to provide static information about how to style, color and name the different
 * institutions and their contracts.
 */
export class AdditionalChartInformation {

  public static grv = {
    lifelong: {
      color: '#E86E3C',
      text: 'Gesetzliche Rente',
      style: 'color: %1; fill-color: %1; fill-opacity: 1.0'
    }
  };

  public static bav = {
    lifelong: {
      color: '#0ECCCA',
      text: 'Betriebliche Rente',
      style: 'color: %1; fill-color: %1; fill-opacity: 1.0'
    },
    single: {
      color: '#0B9997',
      text: 'Betriebliche Einmalzahlungen',
      style: 'color: %1; fill-color: %1; fill-opacity: 1.0'
    },
    periodic: {
      color: '#04EDD2',
      text: 'Betriebliche Rate',
      style: 'color: %1; fill-color: %1; fill-opacity: 1.0'
    }
  };

  public static pav = {
    lifelong: {
      color: '#FFE045',
      text: 'Private Rente',
      style: 'color: %1; fill-color: %1; fill-opacity: 1.0'
    },
    single: {
      color: '#CCB337',
      text: 'Private Einmalzahlungen',
      style: 'color: %1; fill-color: %1; fill-opacity: 1.0'
    },
    periodic: {
      color: '#FFED91',
      text: 'Private Rate',
      style: 'color: %1; fill-color: %1; fill-opacity: 1.0'
    }
  };

  public static singlePayments = {
    color: '#ff955e',
    text: 'Einmalzahlungen',
    style: 'color: %1; fill-color: %1; fill-opacity: 1.0'
  };

  public static lifelongPayments = {
    color: '#90ff61',
    text: 'Lebenslange Zahlungen',
    style: 'color: %1; fill-color: %1; fill-opacity: 1.0'
  };
}

/**
 * PensionChartDataService
 * @author Sebastian Pohle
 * @since 23.08.2019
 * @version 1.1
 *
 * The pension chart data service provides the possibility to obtain pre-formatted data in form of a
 * PensionChartData object which can be used to populate a pension chart (Google Stepped Area Chart).
 */
@Injectable({
  providedIn: 'root'
})
export class PensionChartDataService {

  public static EMPTY_COLOR = 'gray';

  /**
   * This switch controls how much y-axis 'standard' - ticks will be at least added to the chart. A standard tick is used
   * to allow the user a better understanding of the chart dimensions.
   */
  public NUMBER_OF_Y_AXIS_TICKS = 5;

  /**
   * The chart ticks will be extended by this percentage. If the maximum tick value is 800, the ticks will be extended
   * at LEAST to 880. At LEAST because we will also round up to the next 100/1000.
   */
  public EXTEND_CHART_TICK_BY_FACTOR = 0.1; // 10%

  /**
   * This switch controls whether or not empty legend entries are shown or not. An empty legend entry is defined as
   * an entry which has no (or an empty) series associated to it.
   */
  public SHOW_EMPTY_LEGEND_ENTRIES = false;

  /**
   * MAX_AGE defines the maximum value (age of the persona) on the x-axis.
   */
  public MAX_AGE = 80;

  /**
   * MIN_AGE defines the minimum value on the x-axis.
   */
  public MIN_AGE = 63;

  /**
   * This subscription is used to monitor changes on the accumulated pension data.
   */
  public pensionServiceSubscription: Subscription = null;

  /**
   * The behaviour subject propagates changes on the PensionChartData to the corresponding subscriptions.
   */
  public data = new BehaviorSubject<PensionChartData>(null);


  /**
   * The constructor for the service object.
   * @param pensionService  The service responsible for obtaining further and (accumulated) pension data from the
   *                        persona.
   * @param storageService The service responsible for storing and retrieving values from sessionStorage/localStorage/...
   */
  constructor(private pensionService: PensionService, private storageService: StorageService) {
  }

  /**
   * Given a paymentName, this method prepares and returns a string which holds style information for a single
   * data point inside the chart.
   * @param paymentName The name of the payment (e.g. grv single/lifelong)
   * @returns           Returns a string containing information about how the data point needs to be styled (e.g. green color).
   */
  private static getDataPointStyle(paymentName: string): string {

    let dataPointStyle: string;
    switch (paymentName) {
      case 'grv_lifelongPayments':
        dataPointStyle = AdditionalChartInformation.grv.lifelong.style;
        dataPointStyle = dataPointStyle.replace(/%1/g, AdditionalChartInformation.grv.lifelong.color);
        break;
      case 'bav_lifelongPayments':
        dataPointStyle = AdditionalChartInformation.bav.lifelong.style;
        dataPointStyle = dataPointStyle.replace(/%1/g, AdditionalChartInformation.bav.lifelong.color);
        break;
      case 'bav_periodicPayments':
        dataPointStyle = AdditionalChartInformation.bav.periodic.style;
        dataPointStyle = dataPointStyle.replace(/%1/g, AdditionalChartInformation.bav.periodic.color);
        break;
      case 'bav_singlePayments':
        dataPointStyle = AdditionalChartInformation.bav.single.style;
        dataPointStyle = dataPointStyle.replace(/%1/g, AdditionalChartInformation.bav.single.color);
        break;
      case 'pav_lifelongPayments':
        dataPointStyle = AdditionalChartInformation.pav.lifelong.style;
        dataPointStyle = dataPointStyle.replace(/%1/g, AdditionalChartInformation.pav.lifelong.color);
        break;
      case 'pav_periodicPayments':
        dataPointStyle = AdditionalChartInformation.pav.periodic.style;
        dataPointStyle = dataPointStyle.replace(/%1/g, AdditionalChartInformation.pav.periodic.color);
        break;
      case 'pav_singlePayments':
        dataPointStyle = AdditionalChartInformation.pav.single.style;
        dataPointStyle = dataPointStyle.replace(/%1/g, AdditionalChartInformation.pav.single.color);
        break;
      case 'singlePayments':
        dataPointStyle = AdditionalChartInformation.singlePayments.style;
        dataPointStyle = dataPointStyle.replace(/%1/g, AdditionalChartInformation.singlePayments.color);
        break;
      case 'lifelongPayments':
        dataPointStyle = AdditionalChartInformation.lifelongPayments.style;
        dataPointStyle = dataPointStyle.replace(/%1/g, AdditionalChartInformation.lifelongPayments.color);
        break;
    }

    return dataPointStyle;
  }

  /**
   * This method takes the paymentName and translates it into a human readable form.
   * @param paymentName The name of the payment (e.g. grv single/lifelong)
   * @returns           Returns the human readable form of the requested text.
   */
  private static getColumnDescriptionForPayments(paymentName: string): string {

    switch (paymentName) {
      case 'grv_lifelongPayments':
        return AdditionalChartInformation.grv.lifelong.text;
      case 'bav_lifelongPayments':
        return AdditionalChartInformation.bav.lifelong.text;
      case 'bav_periodicPayments':
        return AdditionalChartInformation.bav.periodic.text;
      case 'bav_singlePayments':
        return AdditionalChartInformation.bav.single.text;
      case 'pav_lifelongPayments':
        return AdditionalChartInformation.pav.lifelong.text;
      case 'pav_periodicPayments':
        return AdditionalChartInformation.pav.periodic.text;
      case 'pav_singlePayments':
        return AdditionalChartInformation.pav.single.text;
      case 'singlePayments':
        return AdditionalChartInformation.singlePayments.text;
      case 'lifelongPayments':
        return AdditionalChartInformation.lifelongPayments.text;
    }
    return 'Unbekannt';
  }

  /**
   * Depending on the given paymentName and isEmpty indicator, this method returns a style object
   * which can be used to set the style for the series (used in 'options' for the chart).
   * @param paymentName The name of the payment (e.g. grv single/lifelong).
   * @param isEmpty     An indicator to determine whether or not the entry in the legend has to be grayed-out or not.
   * @returns           Returns a style object which can be used inside the options of a google chart.
   */
  private static getSeriesStyle(paymentName: string, isEmpty: boolean): object {

    const style = {
      color: '',
      areaOpacity: '1.0',
      labelInLegend: '',
      visibleInLegend: true
    };

    switch (true) {
      case paymentName.startsWith('pav'):
      case paymentName.startsWith('bav'):
      case paymentName.startsWith('grv'):
        PensionChartDataService.updateStyleForColumns(style, paymentName, isEmpty);
        break;
      case paymentName.startsWith('single'):
      case paymentName.startsWith('lifelong'):
        PensionChartDataService.updateStyleForPaymentInterval(style, paymentName, isEmpty);
        break;
    }
    return style;
  }

  /**
   * This method updates the given style object for either a 'grv_*', 'pav_*' or 'bav_*'
   * payment name.
   * @param style       The style object which will be modified/enriched with further information.
   * @param paymentName The paymentName which controls which information will be added.
   * @param isEmpty     An indicator to control whether or not the style has to be styled as empty.
   */
  private static updateStyleForColumns(style, paymentName: string, isEmpty: boolean): void {
    switch (paymentName) {
      case 'grv_lifelongPayments':
        style.color = isEmpty ? PensionChartDataService.EMPTY_COLOR : AdditionalChartInformation.grv.lifelong.color;
        style.labelInLegend = AdditionalChartInformation.grv.lifelong.text;
        break;
      case 'bav_lifelongPayments':
        style.color = isEmpty ? PensionChartDataService.EMPTY_COLOR : AdditionalChartInformation.bav.lifelong.color;
        style.labelInLegend = AdditionalChartInformation.bav.lifelong.text;
        break;
      case 'bav_periodicPayments':
        style.color = isEmpty ? PensionChartDataService.EMPTY_COLOR : AdditionalChartInformation.bav.periodic.color;
        style.labelInLegend = AdditionalChartInformation.bav.periodic.text;
        break;
      case 'bav_singlePayments':
        style.color = isEmpty ? PensionChartDataService.EMPTY_COLOR : AdditionalChartInformation.bav.single.color;
        style.labelInLegend = AdditionalChartInformation.bav.single.text;
        break;
      case 'pav_lifelongPayments':
        style.color = isEmpty ? PensionChartDataService.EMPTY_COLOR : AdditionalChartInformation.pav.lifelong.color;
        style.labelInLegend = AdditionalChartInformation.pav.lifelong.text;
        break;
      case 'pav_periodicPayments':
        style.color = isEmpty ? PensionChartDataService.EMPTY_COLOR : AdditionalChartInformation.pav.periodic.color;
        style.labelInLegend = AdditionalChartInformation.pav.periodic.text;
        break;
      case 'pav_singlePayments':
        style.color = isEmpty ? PensionChartDataService.EMPTY_COLOR : AdditionalChartInformation.pav.single.color;
        style.labelInLegend = AdditionalChartInformation.pav.single.text;
        break;
    }
  }

  /**
   * This method updates the given style object for either a 'singlePayments' or a 'lifelongPayments'
   * payment name.
   * @param style       The style object which will be modified/enriched with further information.
   * @param paymentName The paymentName which controls which information will be added.
   * @param isEmpty     An indicator to control whether or not the style has to be styled as empty.
   */
  private static updateStyleForPaymentInterval(style, paymentName: string, isEmpty: boolean): void {
    switch (paymentName) {
      case 'singlePayments':
        style.color = isEmpty ? PensionChartDataService.EMPTY_COLOR : AdditionalChartInformation.singlePayments.color;
        style.labelInLegend = AdditionalChartInformation.singlePayments.text;
        break;
      case 'lifelongPayments':
        style.color = isEmpty ? PensionChartDataService.EMPTY_COLOR : AdditionalChartInformation.lifelongPayments.color;
        style.labelInLegend = AdditionalChartInformation.lifelongPayments.text;
        break;
    }
  }

  /**
   * This structure is used to describe the core data of a chart. It can be further modified
   * by specific data like for columns or payment types.
   * @returns Returns an object which holds the primary information for a chart.
   */
  private static getCoreChartDataStructure() {
    return {
      data: [[null, 0]],
      columns: ['-', '-'],
      columnRoles: [],
      options: {
        fontSize: 16,
        fontName: 'Open Sans',
        title: '',
        vAxis: {
          title: 'Betrag in €',
        },
        chartArea: {width: '50%', left: '150'},
        hAxis: {title: 'Alter in Jahren'},
        isStacked: true,
        connectSteps: false,
        legend: {
          position: 'right'
        },
        aggregationTarget: 'auto',
        tooltip: {trigger: 'none'},
        selectionMode: 'multiple',
        annotations: {
          highContrast: false,
          textStyle: {
            color: '#000000'
          },
          style: 'point'
        }
      }
    };
  }

  /**
   * This method returns a PensionChartData object which holds the necessary information
   * to display the chart. It consists of the data itself and further information like
   * the chart options and column names.
   * @returns Returns the PensionChartObject for the current persona.
   */
  public getDataForPersona(persona: Persona, column): Observable<PensionChartData> {
    // Is there a subscription pending?
    if (this.pensionServiceSubscription) {
      this.pensionServiceSubscription.unsubscribe();
    }

    // Subscribe to the pension service for the new persona.
    if (column === 'paymentType') {
      this.pensionServiceSubscription = this.pensionService.getAccumulatedPensionPerPaymentType(persona).subscribe(personaData => {
        this.updateChartDataForPaymentType(personaData);
      });
    } else if (column === 'paymentType_grv') {
      this.pensionServiceSubscription = this.pensionService.getAccumulatedPensionPerPaymentType(persona,
        InstitutionType.grv).subscribe(personaData => {
        this.updateChartDataForPaymentType(personaData);
      });
    } else if (column === 'paymentType_pav') {
      this.pensionServiceSubscription = this.pensionService.getAccumulatedPensionPerPaymentType(persona,
        InstitutionType.pav).subscribe(personaData => {
        this.updateChartDataForPaymentType(personaData);
      });
    } else if (column === 'paymentType_bav') {
      this.pensionServiceSubscription = this.pensionService.getAccumulatedPensionPerPaymentType(persona,
        InstitutionType.bav).subscribe(personaData => {
        this.updateChartDataForPaymentType(personaData);
      });
    } else {
      this.pensionServiceSubscription = this.pensionService.getAccumulatedPensionForAllColumns(persona, true)
      .subscribe(columnData => {
        let dataSubset = [];
        switch (column) {
          case 'grv':
          case 'bav':
          case 'pav':
            dataSubset[column] = columnData[column];
            break;
          default:
            dataSubset = columnData;
        }
        this.updateChartDataForColumns(dataSubset);
      });
    }

    return this.data.asObservable();
  }

  /**
   * This method updates the ticks-option of the chart options in the following manner:
   * - the y axis will show the ticks from min to max
   * - all accumulatedValues 'near' a tick will override the tick so that the accumulated value is shown rather than
   *   the default tick
   *
   * @param options The options object which the ticks will be added to (options.vAxis.ticks)
   * @param min               The minimum value of the y-axis
   * @param max               The maximum value of the y-axis
   * @param accumulatedValues Any accumulated value that
   * @returns                 Returns the provided options-object with a manipulated series-property.
   */
  private updateTicks(options: object, min: number, max: number, accumulatedValues: number[]): object {

    if (!options) {
      throw new Error('No options have been provided. Consider passing options to "updateTicks()"');
    }

    // Check if a maximum value for the chart has been provided. If not, we are going to calculate an appropriate one.
    if (!max) {
      max = this.getMaximumTickValue(accumulatedValues);
    }

    // Calculate the epsilon environment depending on the max amplitude of the chart.
    // E.g. if the max value is 5000 (€), all accumulated values that differ by an amount of 250 (5000/20) will override
    // the default tick.
    const epsilonEnvironment: number = max / 20;

    // Calculate the step width for the y-axis markers.
    const stepWidth = max / this.NUMBER_OF_Y_AXIS_TICKS;

    let tickInEpsilonEnvironmentAdded = false;
    const ticks: number[] = [];
    for (let a: number = min; a <= max; a += stepWidth) {

      tickInEpsilonEnvironmentAdded = false;

      // Is there an accumulated value in the epsilon environment of 'a'?
      // If so, 'a' gets replaced by it.
      for (const accumulatedValue of accumulatedValues) {

        // E.g. accumulatedValue = 1200 and tick is 1000 and epsilon is 250, the accumulatedValue is taken
        if (accumulatedValue <= (a + epsilonEnvironment) && accumulatedValue >= (a - epsilonEnvironment)) {
          ticks.push(accumulatedValue);
          tickInEpsilonEnvironmentAdded = true;
        } else {
          // The accumulated value is not in the epsilon environment. Check if it is between the last and the
          // current a-value.
          // E.g. accumulatedValue = 1500, last a is 1000 and the current a is 2000 -> add it, too.
          if (accumulatedValue > (a - stepWidth) && accumulatedValue < a) {
            ticks.push(accumulatedValue);
          }
        }
      }

      // If at least one tick has been added, we dont have to add the default tick any more
      if (!tickInEpsilonEnvironmentAdded) {
        // Reduce the accuracy of the accumulatedValue to 2 decimals.
        ticks.push(a);
      }
    }

    // The maximum tick always has to be inserted to better finalize the chart.
    ticks.push(max);

    // Update and return the options.
    const vAxisKey = 'vAxis';
    const ticksKey = 'ticks';
    const tickFormat = 'format';
    options[vAxisKey][ticksKey] = ticks;
    options[vAxisKey][tickFormat] = '#,###';
    return options;
  }

  /**
   * This helper method calculates a maximum y-Tick value depending on the given array of values.
   * The algorithm works as follows:
   * - obtain the maximum tick value from the provided array
   * - get the fraction size (1/10/100/1000/10000/...) from the max value
   * - get the next larger fraction depending on the max value
   * - extend the fraction by our EXTEND_CHART_TICK_BY_FACTOR value and use this value as our maximum
   *
   * @param values The ticks which mark the sum of the pensions on the y-Axis.
   * @returns Returns a new maximum value for the y-Axis ticks.
   */
  private getMaximumTickValue(values: number[]): number {
    // Obtain the maximum value from the array and extend it by our factor.
    let max = Math.max(...values);
    if (max === 0) {
      return 1000;
    }
    max = Math.pow(10, Math.floor(Math.log10(max)));
    max = Math.ceil(Math.max(...values) / max) * max;
    max *= (1 + this.EXTEND_CHART_TICK_BY_FACTOR);
    return max;
  }

  /**
   * This method gets called in case that the underlying data for a certain payment type has changed
   * inside the pension service. It updates the chart data and propagates those changes to the chart.
   * @param accumulatedPaymentBlocks The new data which need to be prepared for the chart.
   */
  private updateChartDataForPaymentType(accumulatedPaymentBlocks: AccumulatedPaymentBlocks): void {

    // Prepare the value which will be used to populate our behaviour subject with data. If no further
    // data can be obtained in accumulatedPaymentBlocks, these default data will be returned to show the
    // user that no data is available.
    const pensionChartData = PensionChartDataService.getCoreChartDataStructure();

    // Take over the provided contract information into our paymentsMap which then will be used to iterate
    // through all entries and populate our chart.
    const paymentsMap = new Map<string, object[]>();
    if (accumulatedPaymentBlocks) {

      const singleKey = 'single';
      const lifelongKey = 'lifelong';

      if (accumulatedPaymentBlocks[singleKey]) {
        paymentsMap.set('singlePayments', accumulatedPaymentBlocks[singleKey]);
      }

      if (accumulatedPaymentBlocks[lifelongKey]) {
        paymentsMap.set('lifelongPayments', accumulatedPaymentBlocks[lifelongKey]);
      }
    }

    // Convert the data into a chart table object.
    const chartTable = this.getChartTableForData(paymentsMap);
    chartTable.sort();

    const seriesKey = 'series';
    const colorsKey = 'colors';

    pensionChartData.data = chartTable.toDataArray();
    pensionChartData.columns = chartTable.getColumns();
    pensionChartData.options[seriesKey] = chartTable.getSeriesStyles();
    pensionChartData.options[colorsKey] = chartTable.getSeriesStyles();

    // Update the ticks.
    // @ts-ignore
    pensionChartData.options = this.updateTicks(pensionChartData.options, 0,
      null, chartTable.getDistinctiveValues());

    this.data.next(pensionChartData);
  }

  /**
   * Depending on the provided paymentsMap, a chart table will be generated which holds the necessary data.
   * @param paymentsMap The payments map which holds all necessary data that need to be visualized.
   * @returns Returns a ChartTable object that bundles the information.
   */
  private getChartTableForData(paymentsMap: Map<string, object[]>): ChartTable {

    const specialHandling = {
      preventAnnotations: false
    };

    // Prepare the data table for our chart.
    const chartTable = new ChartTable(this.MIN_AGE, this.MAX_AGE);
    chartTable.enableToBeContinuedIndicator(true);

    // Iterate over all available payments regardless of the payment- and institution-type.
    paymentsMap.forEach((value, key) => {

      // Create a series for the chart for every available payment- and institution-type.
      const series = new Series();
      series.addColumnDescription({
        role: 'domain', type: 'string', index: 1, title: PensionChartDataService.getColumnDescriptionForPayments(key)
      });
      series.addColumnDescription({
        role: 'annotation', type: 'string', index: 2,
      });
      series.addColumnDescription({
        role: 'tooltip', type: 'string', index: 3,
      });
      series.addColumnDescription({
        role: 'style', type: 'string', index: 4
      });

      // Iterate through all (WE ASSUME SORTED!) entries for this payment type.
      if (!value || value.length === 0) {
        // There are no pension information available. Add an empty placeholder.
        series.addPlaceholderValue();
      } else {

        let addAnnotation = true;

        value.forEach((pensionEntry: AccumulatedPension) => {

          // Calculate the end date for the payment.
          let endDate: number;
          switch (pensionEntry.paymentType) {
            case PaymentType.periodic:
              endDate = pensionEntry.age;
              break;
            case PaymentType.lifelong:
              endDate = this.MAX_AGE;
              break;
            case PaymentType.single:
              endDate = pensionEntry.age;
              break;
          }

          addAnnotation = true;

          // In case of a periodic contract, only the first one will get an annotation.
          if (pensionEntry.paymentType === PaymentType.periodic) {
            if (specialHandling.preventAnnotations === true) {
              addAnnotation = false;
            } else {
              specialHandling.preventAnnotations = true;
            }
          }

          // Add the entries to our series object.
          for (let a = pensionEntry.age; a <= endDate; ++a) {
            // Only add data points for the area between MIN_AGE and MAX_AGE
            if (a < this.MIN_AGE || a > this.MAX_AGE) {
              continue;
            }
            series.insertValue(a.toString(), pensionEntry.amount, 'domain');
            series.insertValue(a.toString(), (addAnnotation ? formatCurrency(pensionEntry.amount, 'DE', '€',
              '', '1.0-0') : null), 'annotation');
            series.insertValue(a.toString(), formatCurrency(pensionEntry.amount, 'DE', '€'), 'tooltip');
            series.insertValue(a.toString(), PensionChartDataService.getDataPointStyle(key), 'style');
            addAnnotation = false;
          }
        });
      }

      // If data for this series is available or if we also have to show empty series, we now will add it to the chart.
      if (!series.isEmpty() || this.SHOW_EMPTY_LEGEND_ENTRIES) {
        series.setSeriesStyle(PensionChartDataService.getSeriesStyle(key, series.isEmpty()));
        chartTable.addSeries(series);
      }
    });

    return chartTable;
  }

  /**
   * If new accumulated pension data is available, this method can be used to update our chart data with it.
   * @param accumulatedPaymentBlocks The new accumulated pension data.
   */
  private updateChartDataForColumns(accumulatedPaymentBlocks: AccumulatedPaymentBlocks[]): void {

    // Prepare the value which will be used to populate our behaviour subject with data. If no further
    // data can be obtained in accumulatedPaymentBlocks, these default data will be returned to show the
    // user that no data is available.
    const pensionChartData = PensionChartDataService.getCoreChartDataStructure();

    // Take over the provided contract information into our paymentsMap which then will be used to iterate
    // through all entries and populate our chart.
    const paymentsMap = new Map<string, object[]>();
    if (accumulatedPaymentBlocks) {

      const grvKey = 'grv';
      const bavKey = 'bav';
      const pavKey = 'pav';

      if (accumulatedPaymentBlocks[grvKey]) {
        paymentsMap.set('grv_lifelongPayments', accumulatedPaymentBlocks[grvKey].lifelong);
      }

      if (accumulatedPaymentBlocks[bavKey]) {
        paymentsMap.set('bav_lifelongPayments', accumulatedPaymentBlocks[bavKey].lifelong);
        paymentsMap.set('bav_singlePayments', accumulatedPaymentBlocks[bavKey].single);
        paymentsMap.set('bav_periodicPayments', accumulatedPaymentBlocks[bavKey].periodic);
      }

      if (accumulatedPaymentBlocks[pavKey]) {
        paymentsMap.set('pav_lifelongPayments', accumulatedPaymentBlocks[pavKey].lifelong);
        paymentsMap.set('pav_singlePayments', accumulatedPaymentBlocks[pavKey].single);
        paymentsMap.set('pav_periodicPayments', accumulatedPaymentBlocks[pavKey].periodic);
      }
    }

    // Prepare the data table for our chart.
    const chartTable = this.getChartTableForData(paymentsMap);
    chartTable.sort();

    const seriesKey = 'series';
    const colorsKey = 'colors';

    pensionChartData.data = chartTable.toDataArray();
    pensionChartData.columns = chartTable.getColumns();
    pensionChartData.options[seriesKey] = chartTable.getSeriesStyles();
    pensionChartData.options[colorsKey] = chartTable.getSeriesStyles();

    // Update the ticks.
    // @ts-ignore
    pensionChartData.options = this.updateTicks(pensionChartData.options, 0,
      null, chartTable.getDistinctiveValues());

    this.data.next(pensionChartData);
  }

}
