/**
 * User
 * @author Sebastian Pohle
 * @since 15.10.2019
 * @version 1.0
 *
 * The user object which is used in the login process.
 */
export class User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  token: string;
  data: any;

  /**
   * Default constructor.
   * @param id        The id of the persona which is used in the login process.
   *                  This id matches the corresponding persona object.
   * @param username  The username aka name of the persona.
   * @param password  The top secret password of the persona.
   */
  constructor(id: number, username: string, password: string) {
    this.id = id;
    this.username = username;
    this.password = password;
  }
}
