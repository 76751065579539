import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {DbkDataViewTableModeItemComponent} from '@webgui-ng/components';
import * as institution from '../../../../institution.Institution';
import {AccumulatedPension} from '../../../../pensionService/interfaces/accumulated-pension';


@Component({
  selector: 'app-table-summary-column-table-item',
  templateUrl: './table-summary-column-table-item.component.html',
  styleUrls: ['./table-summary-column-table-item.component.scss']
})
export class TableSummaryColumnTableItemComponent extends DbkDataViewTableModeItemComponent  implements OnInit {

  @Input()
  accumulatedPension: AccumulatedPension;


  /**
   * Constructor of this table item component. The table item can be used as an element for bav table entries.
   */
  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   */
  ngOnInit() {
    this.cd.detectChanges();
  }
}
