import {Component, OnInit, ViewChild} from '@angular/core';
import {PensionChartComponent} from '../../charts/pension-chart/pension-chart.component';
import {AuthenticationService} from '../../_authentication/authentication.service';
import {MiscLabels} from '../../misc.labels';
import {Persona} from '../../persona.Persona';
import {PersonaService} from '../../persona.service';
import {Router} from '@angular/router';
import {StorageService} from '../../storageService/storage.service';
import {GuidedTourService} from '@webgui-ng/components';
import {GuidedToursLabels} from '../../shared/guidedTours/guided-tours-labels';
import {Routes} from '../../routes';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public loggedIn = false;
  public persona: Persona;

  MiscLabels = MiscLabels;
  GuidedToursLabels = GuidedToursLabels;

  @ViewChild('pensionChart', {static: true})
  pensionChartComponent: PensionChartComponent;


  public activeTabViewIndex = 0;
  public showChartExample = false;
  designs: SelectItem[];
  selectedDesign = 'table';
  private loginLogoutRoute: string;

  /**
   * Constructor
   * @param authenticationService The authentication service which will be used to monitor the logged-in state.
   * @param guidedTourService The service for creating a guided tour
   * @param storageService The storage service for setting state to storage
   * @param router The router
   */
  constructor(private authenticationService: AuthenticationService,
              private personaService: PersonaService,
              private storageService: StorageService,
              private router: Router,
              private guidedTourService: GuidedTourService) {
    this.designs = [];
    this.designs.push({title: 'Tabelle', value: 'table', icon: 'fa fa-table'});
    this.designs.push({title: 'Diagramm', value: 'chart', icon: 'fa fa-bar-chart'});
  }

  /**
   * Angular lifecycle method which gets called onInit.
   */
  ngOnInit() {
    this.loggedIn = this.authenticationService.getCurrentUser$().value !== null;
    this.persona = this.personaService.getPersona$().value;
    this.loginLogoutRoute = '/' + Routes.LOGIN_ROUTE;



    if (this.guidedTourService.isTourInProgress()) {
      this.guidedTourService.tourInAction().subscribe((step) => {
        this.showChartExample = false;
        if (step.name === 'loggedIn_tablePaymentSource') {
          this.activeTabViewIndex = 1;
        } else if (step.name === 'loggedIn_tablePaymentMethod') {
          this.showChartExample = true;
          this.activeTabViewIndex = 0;
        } else if (step.name === 'loggedIn_chart') {
          this.showChartExample = true;
          this.activeTabViewIndex = 2;
        }
      });
    }
    window.dispatchEvent(new Event('resize'));
  }

  // handleTabViewChange(e) {
  //   this.activeTabViewIndex = e.index;
  // }

  /**
   * This event handler gets called in case that the chart-tab has been activated. It is used to
   * redraw the chart depending on the amount of space which will be provided by the tab-area.
   * @param e as event
   */
  handleChange(e) {

    if (e.value === undefined) {
      if (this.storageService.getPresentation() === undefined) {
        this.selectedDesign = 'table';
      } else {
        this.selectedDesign = this.storageService.getPresentation();
      }
    } else {
      this.storageService.setPresentation(e.value);
      this.selectedDesign = e.value;
    }

    // this.pensionChartComponent.chart.wrapper.draw(this.pensionChartComponent.chart.getChartElement());
    window.dispatchEvent(new Event('resize'));
  }


}
