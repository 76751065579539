/**
 * RoleInformation
 *
 * @author Sebastian Pohle
 * @since 29.08.2019
 * @version 1.0
 */
export interface RoleInformation {
  roleIdx: number;
  roleString: string;
}

/**
 * ChartTable
 *
 * @author Sebastian Pohle
 * @since 23.08.2019
 * @version 1.0
 *
 * This class represents a 2-dimensional data table that can be used to populate
 * a stepped area chart with data.
 */
export class ChartTable {

  /**
   * This static property describes with which x-Axis value will be indicated that the data continues beyond the
   * actual max x-Axis value.
   */
  public static TO_BE_CONTINUED_INDICATOR = '...';

  /**
   * This property describes the minimum value of the x-Axis that will be rendered on the chart. If no values have
   * been provided for the interval between min-value and the actual start of the data OR the max value, all values
   * in between will be filled with null.
   */
  private xAxisMinValue = 0;

  /**
   * This property describes the maximum value of the x-Axis that will be rendered on the chart. If no values have
   * been provided for the interval between the actual start of the data OR the min value and the maximum value,
   * all values in between will be filled with null.
   */
  private xAxisMaxValue = 0;

  /**
   * The max y-Axis amplitude holds the largest value of any series for the domain-role.
   */
  private maxYAmplitude = 0;

  /**
   * The data inside the table is structured inside Series objects which themselves hold all necessary data for a single
   * series. This property holds all available series for the chart.
   */
  private series: Series[] = [];
  private emptySeriesValue = 0;
  private emptySeriesName = '-';

  /**
   * If this parameter is enabled, the table will have a last entry named TO_BE_CONTINUED_INDICATOR to indicate
   * that the chart continues with the same values.
   */
  private enableToBeContinuedEntry = false;

  /**
   * This method adds the ToBeContinued indicator to the given array and returns it.
   * @param array   The array which holds all chart data and where the indicator will be appended to.
   * @returns       Returns the given array with the ToBeContinued indicator appended as the last entry.
   */
  private static addToBeContinuedIndicator(array: any[][]): any[][] {

    // Obtain the last entry since this is the one whose entries will be used for the ToBeContinued indicator row.
    const lastIndex = array.length - 1;

    // Check if the indicator has already been added to it...
    if (array[lastIndex][0] === ChartTable.TO_BE_CONTINUED_INDICATOR) {
      return array;
    }

    array[lastIndex + 1] = [];
    for (let a = 0; a < array[lastIndex].length; ++a) {
      array[lastIndex + 1][a] = array[lastIndex][a];
    }
    array[lastIndex + 1][0] = ChartTable.TO_BE_CONTINUED_INDICATOR;
    return array;
  }


  /**
   * Constructor for the ChartTable object.
   * @param xAxisMinValue The minimum value for the x-Axis.
   * @param xAxisMaxValue The maximum value for the x-Axis.
   */
  constructor(xAxisMinValue, xAxisMaxValue) {
    this.setXAxisMinValue(xAxisMinValue);
    this.setXAxisMaxValue(xAxisMaxValue);
  }

  /**
   * Sets the min value for the x-Axis to minValue.
   * @param minValue The new min value for the x-Axis.
   */
  public setXAxisMinValue(minValue: any): void {
    this.xAxisMinValue = minValue;
  }

  /**
   * Sets the max value for the x-Axis to maxValue.
   * @param maxValue The new max value for the x-Axis.
   */
  public setXAxisMaxValue(maxValue: any): void {
    this.xAxisMaxValue = maxValue;
  }

  /**
   * Returns the min value for the x-Axis.
   * @returns The min value for the x-Axis.
   */
  public getXAxisMinValue(): any {
    return this.xAxisMinValue;
  }

  /**
   * Returns the max value for the x-Axis.
   * @returns The max value for the x-Axis.
   */
  public getXAxisMaxValue(): any {
    return this.xAxisMaxValue;
  }

  /**
   * Sets the enableToBeContinuedEntry to newValue.
   * @param newValue The new value of the enableToBeContinuedEntry parameter.
   */
  public enableToBeContinuedIndicator(newValue: boolean): void {
    this.enableToBeContinuedEntry = newValue;
  }

  /**
   * Returns the value which indicates whether or not the ToBeContinued entry
   * will be show.
   * @returns Returns the value of the ToBeContinued indicator.
   */
  public isToBeContinuedEnabled(): boolean {
    return this.enableToBeContinuedEntry;
  }

  /**
   * This method iterates through all series inside the chart and collects their title (or column description object
   * if no title has been defined). The collection will then be passed to the chart options in order to obtain the
   * correct amount of columns that provide information for the visualization.
   * @returns Returns a string array which holds all columns for the chart (e.g. ['X', 'Series 1', 'Series 2', ...]
   */
  public getColumns(): string[] {
    const columnNames: string[] = ['X'];
    const titleKey = 'title';

    if (this.isEmpty()) {
      columnNames.push(this.emptySeriesName);
    } else {
      this.series.forEach((series: Series) => {
        // Iterate through all columns of the series and add them to the column names.
        series.getColumnDescriptions().forEach((columnDescription) => {
          columnNames.push((columnDescription[titleKey] ? columnDescription[titleKey] : columnDescription));
        });
      });
    }

    return columnNames;
  }

  /**
   * If the chart holds no series, it is assumed to be empty.
   * @returns Returns true in case that no series has been added to the chart. Otherwise false.
   */
  public isEmpty(): boolean {
    return this.series.length === 0;
  }

  /**
   * In order to populate the chart with further data, you need to use this addSeries method. It takes the provided
   * series and pushes it into the series array.
   * @param series  The series object which will be added onto the chart.
   */
  public addSeries(series: Series): void {
    this.series.push(series);
    if (series.getMaxYAmplitude() > this.maxYAmplitude) {
      this.maxYAmplitude = series.getMaxYAmplitude();
    }
  }

  /**
   * This method iterates through all x-Axis entries and calculates the accumulated for every data point.
   * The distinct values will then be returned in order to be used on the y-Axis.
   * @returns Returns an array with all distinctive accumulated values.
   */
  public getDistinctiveValues(): number[] {
    const distinctiveValues: number[] = [];

    let sumForXValue: number;
    for (let a = this.xAxisMinValue; a <= this.xAxisMaxValue; ++a) {
      sumForXValue = 0;
      this.series.forEach((series: Series) => {
        const data = series.getData(a.toString(), 'domain');
        if (typeof data === 'string') {
          sumForXValue += parseInt(data, 10);
        } else {
          sumForXValue += data;
        }
      });
      distinctiveValues.push(sumForXValue);
    }
    return distinctiveValues;
  }

  /**
   * In contrast to the actual data of the ChartTable (which could be asymmetrical), this method
   * returns a symmetrical array in which all empty values are filled with null.
   *
   * @returns Returns the 2 dimensional symmetrically filled array representing this table.
   */
  public toDataArray(): any[][] {

    let returnArray = [];
    let xAxisPosition = 0;
    let yAxisPosition = 1;
    const indexKey = 'index';

    for (let a = this.xAxisMinValue; a <= this.xAxisMaxValue; ++a) {
      const xAxisString = a.toString();

      returnArray[xAxisPosition] = [xAxisString];

      yAxisPosition = 1;
      returnArray[xAxisPosition][0] = xAxisString;

      // Obtain all series values for the current x-Axis value specified by xAxisString.
      if (!this.isEmpty()) {
        this.series.forEach((series: Series) => {
          // Every series potentially has several columns (roles) that have to be taken into consideration.
          // Therefore we now iterate through all columns and add them into the return array.
          series.getColumnDescriptions().forEach((columnDescription) => {
            returnArray[xAxisPosition][yAxisPosition] = series.getData(xAxisString, columnDescription[indexKey]);
            yAxisPosition++;
          });
        });
      } else {
        returnArray[xAxisPosition][yAxisPosition] = this.emptySeriesValue;
      }

      xAxisPosition++;
    }

    // Add the TO_BE_CONTINUED_INDICATOR if necessary.
    if (this.enableToBeContinuedEntry) {
      returnArray = ChartTable.addToBeContinuedIndicator(returnArray);
    }
    return returnArray;
  }

  /**
   * This method calculates the next 'best' entry for the y-axis.
   * @returns Returns the max amplitude on the y-axis for the current data set or at least a value of 5000.
   */
  public getMaxAmplitude(): number {
    // Calculate the next best amplitude.
    return Math.max(Math.ceil(this.maxYAmplitude / 1000) * 1000, 5000);
  }

  /**
   * This method iterates through the series objects and appends their styles into an object array which then will
   * be returned.
   * @returns Returns an object array with all style objects from the series.
   */
  public getSeriesStyles(): object[] {
    const seriesStyles: object[] = [];
    if (!this.isEmpty()) {
      this.series.forEach((series: Series) => {
        seriesStyles.push(series.getSeriesStyle());
      });
    } else {
      const style = {
        color: '#000000',
        areaOpacity: '1.0',
        labelInLegend: '',
        visibleInLegend: true
      };
      seriesStyles.push(style);
    }
    return seriesStyles;
  }

  /**
   * Sorts the series depending on their occurrence on the x-Axis.
   */
  public sort(): void {
    this.series.sort((firstSeries, secondSeries) => {
        let first = parseInt(firstSeries.getLowestXAxisValue(), 10);
        if (isNaN(first)) {
          first = Number.MAX_VALUE;
        }
        let second = parseInt(secondSeries.getLowestXAxisValue(), 10);
        if (isNaN(second)) {
          second = Number.MAX_VALUE;
        }
        // If the starting point are equal, we sort secondarily by the amount (smallest values at the bottom).
        const difference = first - second;
        if (difference === 0) {
          let firstValue = firstSeries.getData(first.toString(), 'domain');
          if (typeof firstValue === 'string') {
            firstValue = parseInt(firstValue, 10);
          }
          let secondValue = secondSeries.getData(second.toString(), 'domain');
          if (typeof secondValue === 'string') {
            secondValue = parseInt(secondValue, 10);
          }
          return firstValue - secondValue;
        }
        return difference;
      }
    );
  }
}


/**
 * Series
 *
 * @author Sebastian Pohle
 * @since 26.08.2019
 * @version 1.0
 *
 * A series object holds all information that are required to describe a data set. Especially the data points on the
 * x- and y-Axis as well as additional information like style or annotations.
 */
export class Series {

  /**
   * This map can be accessed via a string-key (e.g. 'domain') which then will offer access to the column description.
   * A column description for example looks like:
   * {
   *  role:
   *  type:
   *  index:
   *  title:
   * }
   * For further information see: https://developers.google.com/chart/interactive/docs/roles
   */
  private columnDescriptions: Map<string, object> = new Map<string, object>();

  /**
   * The data map holds the actual data for the series. Not only the data points for the y-Axis but also the
   * data for any additional data like stlye- or annotation-role.
   */
  private data: Map<string, object> = new Map<string, object>();

  /**
   * The lowest x-Axis values describes the value at which the 'first' data point exists.
   */
  private lowestXAxisValue: string;

  /**
   * The maximum y Amplitude describes the highest y value of the cur
   */
  private maxYAmplitude = 0;

  /**
   * Every series has two styles which will either apply to the data points (saved inside 'data') and a style which
   * applies to the legend entry. The seriesStyle object is used for the second part -> the legend.
   */
  private seriesStyle: object;

  /**
   * This variable indicates whether or not the chart series only contains a placeholder value. If this value is
   * set to true (via addPlaceholderValue()), the series will be interpreted as 'empty' even though it contains
   * the placeholder.
   */
  private containsPlaceholderValue = false;

  /**
   * Default constructor for the series.
   */
  constructor() {
  }

  /**
   * This method is used to add another column description for the series. If a column description with the same role
   * exists, the old one name has already been used, the old
   * value will be overwritten.
   * @param description The description object (column) that will be added to the series.
   */
  public addColumnDescription(description: object): void {
    const roleKey = 'role';
    const name = description[roleKey];
    this.columnDescriptions.set(name, description);
  }

  /**
   * This method returns a map containing all column descriptions of the series. The map itself can be accessed
   * via the role type (e.g. 'domain', 'annotation',...).
   * @returns Returns a map containing all column descriptions of the series.
   */
  public getColumnDescriptions(): Map<string, object> {
    return this.columnDescriptions;
  }

  /**
   * Returns the first (lowest) x-Axis value at which the first data point of the series begins.
   * @returns Returns the string representation of the first x-Axis value on which the data series begins.
   */
  public getLowestXAxisValue(): string {
    return this.lowestXAxisValue;
  }

  /**
   * Insertion method to add a new data point into the series. The value will be added at x-Axis position 'xAxisValue'
   * with an amplitude of 'yAxisValue' (if the columnIdentifier is domain), or the corresponding value for other roles.
   * @param xAxisValue        The x-Axis position at which the data will be added.
   * @param yAxisValue        The y-Axis position (or role information) which will be added.
   * @param roleIdentifier    The role under which the new data point will be saved.
   */
  public insertValue(xAxisValue: string, yAxisValue: number | string, roleIdentifier: string | number): void {

    // The method can either be called with the name or index of the role under which the new data point will be added.
    // Both information are necessary so we map the string to index or vice versa.
    const roleInformation: RoleInformation = this.getRoleInformation(roleIdentifier);

    // In case of a new domain value, we need to verify that the lowest x-Axis value will be saved.
    if (!this.lowestXAxisValue || xAxisValue < this.lowestXAxisValue) {
      this.lowestXAxisValue = xAxisValue;
    }

    // Obtain the data object for the given x-Axis entry and save the given value for it.
    let entry = this.data.get(xAxisValue);
    if (!entry) {
      entry = {};
    }
    entry[roleInformation.roleIdx] = yAxisValue;
    this.data.set(xAxisValue, entry);

    // Depending on the role of the saved data, we can determine the new maximum amplitude of this series.
    if (roleInformation.roleString === 'domain' && yAxisValue > this.maxYAmplitude && typeof yAxisValue === 'number') {
      this.maxYAmplitude = yAxisValue;
    }
  }

  /**
   * This method returns the maximum y-Axis amplitude of the series.
   * @returns The maximum amplitude on the y-Axis for the role 'domain'.
   */
  public getMaxYAmplitude(): number {
    return this.maxYAmplitude;
  }

  /**
   * This method takes either the name of a column and determines the corresponding index or vice versa. Therefore the
   * previously saved column descriptions will be used.
   *
   * @param roleIdentifier  Either the name or the index of a certain role.
   * @returns               Returns an object of type RoleInformation which contains both, the role name and index.
   */
  public getRoleInformation(roleIdentifier: number | string): RoleInformation {

    const roleInfo: RoleInformation = {
      roleIdx: 0,
      roleString: ''
    };

    if (typeof roleIdentifier === 'string') {
      roleInfo.roleIdx = this.getIndexByRole(roleIdentifier);
      roleInfo.roleString = roleIdentifier;
    } else {
      roleInfo.roleIdx = roleIdentifier;
      roleInfo.roleString = this.getRoleByIndex(roleIdentifier);
    }

    // Verify that the column exists.
    const maxColumnCount = this.columnDescriptions.size;
    if (roleInfo.roleIdx < 0 || roleInfo.roleIdx > maxColumnCount) {
      console.log('Info: Column index is out of bounds. Index ' + roleInfo.roleIdx +
        ' and max column count is ' + maxColumnCount + '. Doing nothing');
    }

    return roleInfo;
  }

  /**
   * This method maps the given roleName to its corresponding index.
   * @param roleName  The name of the role whose index we want to obtain.
   * @returns         Returns the index for the role with name 'roleName'.
   */
  private getIndexByRole(roleName: string): number {
    const columnDescription = this.columnDescriptions.get(roleName);
    const indexKey = 'index';
    if (columnDescription) {
      return columnDescription[indexKey];
    }
    return -1;
  }

  /**
   * This method maps the given role index to its corresponding name.
   * @param roleIdx The index of the role whose name we want to obtain.
   * @returns       Returns the name for the role with index 'roleIdx'.
   */
  private getRoleByIndex(roleIdx: number): string {
    const indexKey = 'index';
    const roleKey = 'role';

    for (const keyValue of this.columnDescriptions.keys()) {
      const columnDescription = this.columnDescriptions.get(keyValue);
      if (columnDescription[indexKey] === roleIdx) {
        return columnDescription[roleKey];
      }
    }
    throw new Error('Unknown role found');
  }

  /**
   * Returns the data specified by xAxisValue and the given role. If no data has been saved for this
   * combination, this method will return null (or a null-corresponding value).
   * @param xAxisValue  The x-Axis point which we want to obtain data from.
   * @param role        The role whose data we want to obtain.
   * @returns           Returns the data that is saved at the given xAxisValue and role.
   */
  public getData(xAxisValue: string, role: string | number): string | number {

    let roleIdx: number;
    let roleString: string;
    if (typeof role === 'string') {
      roleIdx = this.getIndexByRole(role);
      roleString = role;
    } else {
      roleIdx = role;
      roleString = this.getRoleByIndex(roleIdx);
    }

    // Check if there is any saved data for this x-Axis value.
    const data = this.data.get(xAxisValue);
    if (data) {
      return data[roleIdx];
    }

    // If no data has been found, we return an empty value. This values depends on which
    // type/role has been accessed.
    switch (roleString) {
      case 'domain':
        return 0;
      case 'style':
        return 'stroke-width: 0';
      case 'annotation':
      default:
        return null;
    }
  }

  /**
   * A placeholder value is an indicator which will be added at the end (on x-Axis) of the series.
   * The placeholder will take the same data from the last x-Axis value to indicate that these values would continue
   * this way.
   */
  public addPlaceholderValue(): void {
    this.insertValue(ChartTable.TO_BE_CONTINUED_INDICATOR, 0, 'domain');
    this.containsPlaceholderValue = true;
  }

  /**
   * Sets the series style to the given style object.
   * @param style The style of the series that will be used inside the legend.
   */
  public setSeriesStyle(style: object): void {
    this.seriesStyle = style;
  }

  /**
   * Returns the series style in order to be used inside the chart legend.
   * @returns The series style.
   */
  public getSeriesStyle(): object {
    return this.seriesStyle;
  }

  /**
   * This method returns true in case that the series holds no data points. If any data has been added,
   * this method will return false.
   * @return Returns true in case that the series holds any data. Otherwise false.
   */
  public isEmpty(): boolean {
    return this.containsPlaceholderValue || this.data.size === 0;
  }

}
