import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {User} from './user';
import {StorageService} from '../storageService/storage.service';
import {PersonaService} from '../persona.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  private readonly currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  /**
   * Default constructor.
   * @param http            The http client is used to send the login request to our fake backend.
   * @param storageService  The storage service initializes the session after a successful login.
   * @param personaService  After the successful login, the current persona object will be loaded.
   * @param messageService  The message service which is used to send a toast notification to the user.
   * @param router          The router object to navigate and reroute
   */
  constructor(private http: HttpClient,
              private storageService: StorageService,
              private personaService: PersonaService,
              private messageService: MessageService,
              public router: Router) {

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    if (this.currentUserSubject.value) {
      this.storageService.setPersonaID(this.currentUserSubject.value.id);
    }
  }

  /**
   * This method returns a reference to the behaviour subject which holds the current user.
   * @returns Returns the behaviour subject of the current user.
   */
  public getCurrentUser$(): BehaviorSubject<User> {
    return this.currentUserSubject;
  }

  /**
   * This method returns the reference to the current user.
   * @returns Returns the reference to the current user.
   */
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   * Navigates to the supplied route
   *
   * @param route the route we want to navigate to
   */
  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  /**
   * This method performs the login with the help of the given username and password.
   * @param username  The user name of the persona which we want to login.
   * @param password  The password of the persona.
   */
  public login(username, password) {
    return this.http.post<any>(`./users/authenticate`, {username, password})
      .pipe(map(user => {

        this.storageService.clear();
        this.storageService.initSession(user.id);
        this.personaService.usePersona();

        // Store the user details and in the local storage to keep user logged in between page refreshes.
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  /**
   * This method performs the logout for the current user.
   */
  public logout(): void {
    // Check if we need to perform the logout.
    if (this.currentUserSubject.value === null) {
      return;
    }

    // Remove user from local storage and set current user to null.
    localStorage.removeItem('currentUser');
    this.storageService.clear();
    this.currentUserSubject.next(null);

    this.messageService.add({
      severity: 'info',
      detail: 'Sie wurden erfolgreich abgemeldet',
      closable: true,
      life: 2000
    });
  }
}
