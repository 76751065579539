import { Component, OnInit } from '@angular/core';
import {InstitutionType, InstitutionTypeLabels} from '../../../institution.Institution';
import {MiscLabels} from '../../../misc.labels';

@Component({
  selector: 'app-accumulated-grv',
  templateUrl: './accumulated-grv.component.html',
  styleUrls: ['./accumulated-grv.component.scss']
})
export class AccumulatedGrvComponent implements OnInit {

  grv = InstitutionType.grv;
  InstitutionTypeLabels = InstitutionTypeLabels;
  institutionHeader = InstitutionTypeLabels.grv;
  MiscLabels = MiscLabels;

  constructor() { }

  ngOnInit() {
  }

}
