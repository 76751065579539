import {Component, OnInit, ViewChild} from '@angular/core';
import {PensionChartComponent} from '../../charts/pension-chart/pension-chart.component';
import {MiscLabels} from '../../misc.labels';
import {Persona} from '../../persona.Persona';
import {PersonaService} from '../../persona.service';
import {GuidedToursLabels} from '../../shared/guidedTours/guided-tours-labels';
import {StorageService} from '../../storageService/storage.service';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {

  public persona: Persona;

  selectedDesign = 'table';

  MiscLabels = MiscLabels;
  GuidedToursLabels = GuidedToursLabels;

  @ViewChild('pensionChart', {static: false}) pensionChartComponent: PensionChartComponent;

  constructor(private personaService: PersonaService, private storageService: StorageService) { }

  ngOnInit() {
    this.persona = this.personaService.getPersona$().value;
  }

  /**
   * This event handler gets called in case that the chart-tab has been activated. It is used to
   * redraw the chart depending on the amount of space which will be provided by the tab-area.
   * @param e as event
   */
  handleChange(e) {
    window.dispatchEvent(new Event('resize'));
    if (e.value === undefined) {
      if (this.storageService.getPresentation() === undefined) {
        this.selectedDesign = 'table';
      } else {
        this.selectedDesign = this.storageService.getPresentation();
      }
    } else {
      this.storageService.setPresentation(e.value);
      this.selectedDesign = e.value;
    }
    // this.pensionChartComponent.chart.wrapper.draw(this.pensionChartComponent.chart.getChartElement());

  }

}
