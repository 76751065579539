import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Persona} from '../persona.Persona';
import {SelectItem} from 'primeng/api';
import {InstitutionTypeLabels} from '../institution.Institution';
import {PersonaService} from '../persona.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-pension-summary',
  templateUrl: './pension-summary.component.html',
  styleUrls: ['./pension-summary.component.scss']
})
export class PensionSummaryComponent implements OnInit, OnDestroy {

  private personaSubscription: Subscription;
  public persona: Persona;
  private paramSubscription: Subscription;

  pension: SelectItem;

  InstitutionTypeLabels = InstitutionTypeLabels;
  private persona$: Observable<Persona>;

  constructor(public personaService: PersonaService, public route: ActivatedRoute, public router: Router) {
  }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   */
  ngOnInit() {
    // Retrieve personaID or redirect to persona list
    this.persona$ = this.personaService.usePersona();
    this.personaSubscription = this.persona$.subscribe(value => this.persona = value);
  }

  /**
   * Lifecycle method that will be called if this component gets destroyed.
   */
  ngOnDestroy(): void {
    if (this.personaSubscription) {
      this.personaSubscription.unsubscribe();
    }
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }

}
