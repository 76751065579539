import {Component, OnInit} from '@angular/core';
import {InstitutionType, InstitutionTypeLabels} from '../../../institution.Institution';
import {MiscLabels} from '../../../misc.labels';

@Component({
  selector: 'app-accumulated-bav',
  templateUrl: './accumulated-bav.component.html',
  styleUrls: ['./accumulated-bav.component.scss']
})
export class AccumulatedBavComponent implements OnInit {

  bav = InstitutionType.bav;
  InstitutionTypeLabels = InstitutionTypeLabels;
  MiscLabels = MiscLabels;
  institutionHeader = InstitutionTypeLabels.bav;

  constructor() {
  }

  ngOnInit() {
  }

}
