import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Persona} from '../../persona.Persona';
import {DbkDataViewColumnConfigData} from '@webgui-ng/components/lib/dbk-data-view/dbk-data-view-column-config-data';
import {PersonaService} from '../../persona.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as institution from '../../institution.Institution';
import {Observable, Subscription} from 'rxjs';
import {MiscLabels} from '../../misc.labels';

@Component({
  selector: 'app-pav-pension-table',
  templateUrl: './pav-pension-table.component.html',
  styleUrls: ['./pav-pension-table.component.scss']
})
export class PavPensionTableComponent implements OnInit {

  InstitutionLabels = institution.InstitutionLabels;
  InstitutionTypeLabels = institution.InstitutionTypeLabels;
  InstitutionHelpTexts = institution.InstitutionHelpTexts;
  MiscLabels = MiscLabels;

  @Input() selectedTab = 'false';

  // @ViewChild(DbkDataViewComponent)
  // dv: DbkDataViewComponent;

  persona: Persona;
  // Column configuration for the <dbk-data-view>
  columnsConfigData: DbkDataViewColumnConfigData[] = [
    {
      label: institution.InstitutionLabels.name,
      dataKey: 'name',
      columnClasses: 'text-center'
    },
    {
      label: institution.InstitutionLabels.retirementAge,
      dataKey: 'retirementAge'
    },
    {
      label: institution.InstitutionLabels.paymentCycle,
      dataKey: 'paymentCycle',
      columnClasses: 'dbk-last-visible-md-column'
    },
    {
      label: institution.InstitutionLabels.safePaymentsIfContinued,
      dataKey: 'value1',
    },
    {
      label: institution.InstitutionLabels.paymentsAlreadyReached,
      dataKey: 'value1',
      columnClasses: 'dbk-last-visible-xxl-column dbk-last-visible-xl-column dbk-last-visible-lg-column dbk-last-visible-md-column'
    },
    {
      label: MiscLabels.contractDetailsLabel,
      dataKey: 'value1',
      columnClasses: 'dbk-last-visible-xxl-column dbk-last-visible-xl-column dbk-last-visible-lg-column dbk-last-visible-md-column'
    },
    {
      label: institution.InstitutionLabels.value1,
      dataKey: 'value1',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value2,
      dataKey: 'value2',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value3_1,
      dataKey: 'value3_1',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value3_2,
      dataKey: 'value3_2',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value3_3,
      dataKey: 'value3_3',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value3_4,
      dataKey: 'value3_4',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value3_5,
      dataKey: 'value3_5',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.value3_6,
      dataKey: 'value3_6',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.productName,
      dataKey: 'productName',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.serviceType,
      dataKey: 'serviceType',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.dateOfEntry,
      dataKey: 'dateOfEntry',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.dateOfExit,
      dataKey: 'dateOfExit',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.annuityFactorGuaranteed,
      dataKey: 'annuityFactorGuaranteed',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.annuityFactorNotGuaranteed,
      dataKey: 'annuityFactorNotGuaranteed',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.taxAndSocialInsuranceContributions,
      dataKey: 'taxAndSocialInsuranceContributions',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.paymentCycleYears,
      dataKey: 'paymentCycleYears',
      columnClasses: 'dbk-hidden-xxl-down'
    },
    {
      label: institution.InstitutionLabels.additionalInformation,
      dataKey: 'additionalInformation',
      columnClasses: 'dbk-hidden-xxl-down'
    }
  ];
  public cols: any;
  private persona$: Observable<Persona>;
  private personaSubscription: Subscription;

  /**
   * Constructor of this statutory component.
   * @param personaService Service for persona data access
   * @param route The current route which represents the current url
   * @param router The router which allows to navigate and redirect via url manipulation
   */
  constructor(public personaService: PersonaService, public route: ActivatedRoute, public router: Router) {
  }


  // changeLayout(event: Event, layout: string) {
  //   this.dv.changeLayout(layout);
  //   event.preventDefault();
  // }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   * todo: Evtl. persona as @input here and in bav and pav components
   */
  ngOnInit() {
    this.persona$ = this.personaService.usePersona();
    this.personaSubscription = this.persona$.subscribe(value => this.persona = value);

    // Define the p-table column and header values for dynamically constructing the p-table
    this.cols = [
      {field: 'name', header: this.InstitutionLabels.name},
      {field: 'retirementAge', header: this.InstitutionLabels.retirementAge},
      {field: 'dateOfEntry', header: this.InstitutionLabels.dateOfEntry},
      {field: 'value1', header: this.InstitutionLabels.value1},
      {field: 'value2', header: this.InstitutionLabels.value2},
      {field: 'value3_1', header: this.InstitutionLabels.value3_1},
      {field: 'value3_2', header: this.InstitutionLabels.value3_2},
      {field: 'additionalFiles', header: this.InstitutionLabels.additionalFiles}
    ];
  }

}
