import {Institution} from './institution.Institution';

export interface Persona {
  id: number;
  biotype: string;
  lastname: string;
  firstname: string;
  birthdate: string;
  zip: string;
  clientNumber: string;
  socialSecurityNumber: string;
  gender: string;
  incomeLevel: string;
  background: string;
  GRVText: string;
  bAVText: string;
  pAVText: string;
  behaviourAndWishes: string;
  image: string;
  isOfficial?: boolean;
  // todo Split Institution interface into three separate interfaces GRVInstitution, bAVInstitution, pAVInstitution
  GRV: {
    institutions: Institution[];
  };
  bAV: {
    institutions: Institution[];
  };
  pAV: {
    institutions: Institution[];
  };
}

export enum BioTypeText {
  a = 'Alles solide aufgebaut - der langjährig Versicherte',
  b = 'Was kommt als Nächstes? - die Berufsanfängerin',
  c = 'Kinder sind ihr Leben - Lehrerin und Mutter',
  two = 'Offen für Neues - die Akademikerin',
  three = 'Mobil für die Medizin - Mitglied in mehreren Versorgungswerken',
  four = 'Beruf und Familie vereinbaren - die Teilzeiterwerbstätige',
  five = 'Schwieriger Arbeitsmarkt - die geringfügig Beschäftigte',
  six = 'Immer für andere da - erst im Dienstleistungssektor, dann für die Familie',
  seven = 'Die Familie ist ihr wichtig - die Zuverdienerin',
  eight = 'Es hat nicht immer alles so geklappt - ein wechselhaftes Berufsleben',
}

export enum IncomeLevelLabel {
  high = 'Hoch',
  medium = 'Mittel',
  low = 'Niedrig',
}
