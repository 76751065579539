import {Inject, Injectable, InjectionToken} from '@angular/core';
import {isStorageAvailable, StorageService as WebStorageService} from 'ngx-webstorage-service';
import {BehaviorSubject} from 'rxjs';

export const STORAGE_SERVICE =
  new InjectionToken<WebStorageService>('STORAGE_SERVICE');

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  // hold information about the availability of the sessionStorage
  public sessionStorageAvailable: boolean;

  // Key for addressing sessionStorage persona id
  private PERSONA_ID = 'PERSONA_ID';
  // Key for addressing landing page selection perColumn / perPayment
  private TOGGLE_COLUMN_PAYMENTTYPE = 'TOGGLE_COLUMN_PAYMENTTYPE';

  // Key for addressing guided tour automatic start
  private GUIDED_TOUR_ACTIVE = 'START_GUIDED_TOUR';
  private DEFAULT_USERNAME = 'Christina Neubert';
  private DEFAULT_PASSWORD = 'password';

  // ----------------------------------------------------------
  // Keys for addressing settingsToolbox settings
  private HIDE_SINGLE_PAYMENTS = 'HIDE_SINGLE_PAYMENTS';
  private MONTHLY_SINGLE_CONVERSION_KEY = 'CONVERT_MONTHLY_SINGLE';
  private MONTHLY_ANNUAL_SCALE_KEY = 'MONTHLY_ANNUAL_SCALE';
  private PRESENTATION_KEY = 'PRESENTATION';
  // ----------------------------------------------------------

  private PREALLOCATE_LOGIN_NAME_WITH = 'PREALLOCATE_LOGIN_NAME_WITH';
  private PREALLOCATE_LOGIN_PW_WITH = 'PREALLOCATE_LOGIN_PW_WITH';

  private subjects$: BehaviorSubject<any>[] = [];

  constructor(@Inject(STORAGE_SERVICE) private storage: WebStorageService) {
    this.sessionStorageAvailable = isStorageAvailable(sessionStorage);
    this.setGuidedTourActive(false);
  }

  /**
   * This method is used to save the given value inside the storage itself and
   * to update the corresponding behaviour subject.
   *
   * @param key index for which a value has to be set
   * @param value the value to be set in the storage
   */
  public save(key: string, value: any) {
    this.set(key, value);
    this.set$(key, value);
  }

  /**
   * This method sets the actual value for the storage and the given key.
   * @param key   Key which is used to identify the storage location.
   * @param value The value which will be saved.
   */
  private set(key: string, value: any) {
    this.storage.set(key, value);
  }

  /**
   * This method updates the value of the behaviour subject and therefore triggers
   * an update for all subscribers.
   * @param key   Key which is used to identify the storage location.
   * @param value The value which will be saved.
   */
  private set$(key: string, value: any): void {
    if (this.subjects$[key] && this.subjects$[key].value !== value) {
      this.subjects$[key].next(value);
    }
  }

  /**
   * Gets the value for the specified key
   *
   * @param key index we want to have the value for
   */
  public get(key: string): any {
    return this.storage.get(key);
  }

  /**
   * This method returns an subject which can be used to track changes on the property.
   * @param key The name of the property/observable we want to obtain.
   * @returns Returns a behaviour subject which propagates value changes for this key.
   */
  public get$(key: string): BehaviorSubject<any> {
    const value = this.storage.get(key);
    this.updateSubjects(key, value, true);
    return this.subjects$[key];
  }

  /**
   * removes all entries from storage
   */
  public clear() {
    // Get current state of tour
    const userTookTour = this.isGuidedTourActive();
    this.storage.clear();

    // Make sure we keep this state even if we switch personas
    this.setGuidedTourActive(userTookTour);

    // We are not allowed to just delete the subjects since there
    // may be other services/components/whatsoever that are subscribed
    // to them. These objects may not resubscribe if a new subject from
    // the storage is available! So we only reset their content data here
    // in order to let the subscription stay intact.
    for (const subject of this.subjects$) {
      subject.next(null);
    }
  }

  /**
   * gets the current persona id from the storage via the key PERSONA_ID
   */
  public getPersonaID(): number {
    return this.get(this.PERSONA_ID);
  }

  /**
   * sets the current persona id to the storage via the key PERSONA_ID
   * @param id persona id to set to storage
   */
  public setPersonaID(id: number) {
    this.set(this.PERSONA_ID, id);
  }

  public getLandingPageToggleColumnPaymenttype(): string {
    return this.get(this.TOGGLE_COLUMN_PAYMENTTYPE);
  }

  public setLandingPageToggleColumnPaymenttype(value: string) {
    this.set(this.TOGGLE_COLUMN_PAYMENTTYPE, value);
  }

  /**
   * This method updates the subjects responsible for propagating changes for 'key'. If such observable
   * does not exist, it will only be created in case that 'createIfNonExistent' is true. Otherwise and
   * (in cases where the value did not change), this method does nothing.
   * @param key                 The name of the observable that has to be update.
   * @param value               The new value for our observable.
   * @param createIfNonExistent Whether or not a new observable has to be created if it does not exist already.
   */
  private updateSubjects(key: string, value: any, createIfNonExistent: boolean): void {
    if (!this.subjects$[key] && createIfNonExistent) {
      this.subjects$[key] = new BehaviorSubject<any>(value);
    }

    if (this.subjects$[key] && this.subjects$[key].value !== value) {
      this.subjects$[key].next(value);
    }
  }

  /**
   * Initialize session with persona id and default values
   *
   * @param personaID the persona id for which the session is initialized for
   */
  public initSession(personaID: number) {
    this.setPersonaID(personaID);
    this.setLandingPageToggleColumnPaymenttype('perColumn');
    this.setHideSinglePayments(false);
    this.setSingleMonthlyConversion(false);
  }

  /**
   * gets the current single payment visibility state via the key HIDE_SINGLE_PAYMENTS
   */
  public getHideSinglePayments(): boolean {
    return this.get(this.HIDE_SINGLE_PAYMENTS);
  }

  /**
   * gets the current single payment visibility state via the key HIDE_SINGLE_PAYMENTS
   */
  public getHideSinglePayments$(): BehaviorSubject<boolean> {
    return this.get$(this.HIDE_SINGLE_PAYMENTS);
  }

  /**
   * sets the current single payment visibility state via the key HIDE_SINGLE_PAYMENTS
   */
  public setHideSinglePayments(hide = true): void {
    this.save(this.HIDE_SINGLE_PAYMENTS, hide);
  }

  /**
   * This method sets the monthly/annual scale factor to the provided
   * value.
   * @param scale The new scale factor.
   */
  public setMonthlyAnnualScale(scale: string): void {
    this.save(this.MONTHLY_ANNUAL_SCALE_KEY, scale);
  }

  /**
   * Obtains and returns the behaviour subject which can be used to subscribe to any
   * changes of the scale factor.
   * @returns Returns a behaviour subject which provides access to the scale factor.
   */
  public getMonthlyAnnualScale$(): BehaviorSubject<string> {
    return this.get$(this.MONTHLY_ANNUAL_SCALE_KEY);
  }



  /**
   * Reads the current scale factor from the setting and returns its value.
   * @returns Returns the current scale factor.
   */
  public getMonthlyAnnualScale(): string {
    return this.get(this.MONTHLY_ANNUAL_SCALE_KEY);
  }

  /**
   * This method sets the presentation table or diagramm to the provided
   * value.
   * @param design The new scale factor.
   */
  public setPresentation(design: string): void {

    this.save(this.PRESENTATION_KEY, design);
  }

  /**
   * Obtains and returns the behaviour subject which can be used to subscribe to any
   * changes of the scale factor.
   * @returns Returns a behaviour subject which provides access to the scale factor.
   */
  public getPresentation$(): BehaviorSubject<string> {
    return this.get$(this.PRESENTATION_KEY);
  }

  /**
   * Reads the current scale factor from the setting and returns its value.
   * @returns Returns the current scale factor.
   */
  public getPresentation(): string {
    return this.get(this.PRESENTATION_KEY);
  }

  /**
   * Returns the current state of the Single to Monthly
   * Conversion switch.
   * @returns Returns the current state of the conversion.
   */
  public getSingleMonthlyConversion(): boolean {
    return this.get(this.MONTHLY_SINGLE_CONVERSION_KEY);
  }

  /**
   * Returns a behaviour subject which can be used to subscribe
   * to any changes the monthly single conversion switch.
   * @returns Returns a behaviour subject for the monthly single conversion.
   */
  public getSingleMonthlyConversion$(): BehaviorSubject<boolean> {
    return this.get$(this.MONTHLY_SINGLE_CONVERSION_KEY);
  }

  /**
   * This method enables or disables the conversion from single
   * to monthly payments.
   * @param conversion  Indicator whether or not the conversion will be done.
   */
  public setSingleMonthlyConversion(conversion = true): void {
    this.save(this.MONTHLY_SINGLE_CONVERSION_KEY, conversion);
  }

  /**
   * Returns the state of guided tour started
   * @returns Returns true in case that the guided tour is active. Otherwise false.
   */
  public isGuidedTourActive(): boolean {
    return this.get(this.GUIDED_TOUR_ACTIVE);
  }

  /**
   * Sets the state of the guided tour started
   * @param value The new state of the guided tour. If it is set to TRUE, the guided tour will be started at the next
   *              possible point.
   */
  public setGuidedTourActive(value: boolean): void {
    this.save(this.GUIDED_TOUR_ACTIVE, value);
  }

  /**
   * This method sets the user name and password which will then be used during the login process
   * to prefill the login fields.
   * @param name      The name of the preallocated user.
   * @param password  The password of the preallocated user.
   */
  public setPreallocateLoginWith(name: string, password: string): void {
    this.save(this.PREALLOCATE_LOGIN_NAME_WITH, name);
    this.save(this.PREALLOCATE_LOGIN_PW_WITH, password);
  }

  /**
   * This method obtains the user data from the storage and returns it wrapped inside an object.
   * @returns Returns an object containing the username and password in the format: { name: xyz, password: jgn }.
   */
  public getPreallocateLoginWith(): object {
    const userData = {name: '', password: ''};

    const name = this.get(this.PREALLOCATE_LOGIN_NAME_WITH);
    const password = this.get(this.PREALLOCATE_LOGIN_PW_WITH);

    // Default behaviour: If no persona was selected, we preallocate the value with Kowalke data.
    userData.name = name ? name : this.DEFAULT_USERNAME;
    userData.password = password ? password : this.DEFAULT_PASSWORD;

    return userData;
  }
}
