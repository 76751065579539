import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Institution} from '../institution.Institution';
import {Agency} from '../agency.Agency';

@Component({
  selector: 'app-institution',
  templateUrl: './institution.component.html',
  styleUrls: ['./institution.component.scss']
})
export class InstitutionComponent implements OnInit, OnDestroy {
  @Input() agency: Agency;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
}
