import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {DbkDataViewTableModeItemComponent} from '@webgui-ng/components';
import * as institution from '../../../institution.Institution';
import {Institution} from '../../../institution.Institution';
import {MiscLabels} from '../../../misc.labels';

@Component({
  selector: 'app-pav-table',
  templateUrl: './pav-table.component.html',
  styleUrls: ['./pav-table.component.scss']
})
export class PavTableComponent extends DbkDataViewTableModeItemComponent implements OnInit {

  InstitutionLabels = institution.InstitutionLabels;
  MiscLabels = MiscLabels;

  @Input()
  institution: Institution;


  /**
   * Constructor of this table item component. The table item can be used as an element for bav table entries.
   */
  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   */
  ngOnInit() {
    this.institution.isExpanded = false;
    this.cd.detectChanges();
  }
}
