import {Injectable} from '@angular/core';
import {Persona} from '../persona.Persona';
import {AccumulatedPensionPerInstitution} from './interfaces/accumulated-pension-per-institution';
import {Institution, InstitutionType} from '../institution.Institution';
import moment from 'moment';
import {AccumulatedPension} from './interfaces/accumulated-pension';
import {PaymentType} from './enums/payment-type.enum';
import {PaymentBlocks} from './interfaces/payment-blocks';
import {PersonaService} from '../persona.service';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {ContractType} from './interfaces/contract';
import {AccumulatedPaymentBlocks} from './interfaces/accumulated-payment-blocks';
import {EstimationModel} from './estimation-model';
import {StorageService} from '../storageService/storage.service';

@Injectable({
  providedIn: 'root'
})
export class PensionService {

  // todo make those reusable
  private ERROR_KEY = 'ERROR_KEY';
  private DATE_INDEX_FORMAT = 'YYYY-MM-DD';
  private DATE_END_OF_LIFE = '9999-12-31T00:00:00';

  private persona: Persona;

  // All these behaviour subjects can be obtained via 'getAccumulatedPensionPerPaymentType'.
  // Depending on the parameters 'splitPeriodic' and 'institutionType', the corresponding behaviour subject will
  // be returned.
  private getAccumulatedPensionPerPaymentTypeData$ = new BehaviorSubject<AccumulatedPaymentBlocks>(null);
  private getAccumulatedPensionPerPaymentTypeDataNonSplitted$ = new BehaviorSubject<AccumulatedPaymentBlocks>(null);

  private getAccumulatedPensionPerPaymentTypeDataForGRV$ = new BehaviorSubject<AccumulatedPaymentBlocks>(null);
  private getAccumulatedPensionPerPaymentTypeDataForGRVNonSplitted$ = new BehaviorSubject<AccumulatedPaymentBlocks>(null);

  private getAccumulatedPensionPerPaymentTypeDataForBAV$ = new BehaviorSubject<AccumulatedPaymentBlocks>(null);
  private getAccumulatedPensionPerPaymentTypeDataForBAVNonSplitted$ = new BehaviorSubject<AccumulatedPaymentBlocks>(null);

  private getAccumulatedPensionPerPaymentTypeDataForPAV$ = new BehaviorSubject<AccumulatedPaymentBlocks>(null);
  private getAccumulatedPensionPerPaymentTypeDataForPAVNonSplitted$ = new BehaviorSubject<AccumulatedPaymentBlocks>(null);

  private singleMonthlySubscription: Subscription;
  private hideSinglePaymentsSubscription: Subscription;
  private annualMonthlyScaleSubscription: Subscription;

  /**
   * The behaviour subject which holds the data for all accumulated payment blocks. It will be set inside
   * getAccumulatedPensionForAllColumns.
   */
  private getAccumulatedPensionForAllColumnsData$ = new BehaviorSubject<AccumulatedPaymentBlocks[]>(null);
  private getAccumulatedPensionForAllColumnsDataNotSplit$ = new BehaviorSubject<AccumulatedPaymentBlocks[]>(null);

  // -------------------------------------------------------------
  // Settings that control the behaviour of this pension service
  /**
   * This switch controls whether or not single payments shall be
   * converted into monthly payments.
   */
  private settingConvertMonthlySinglePayments = false;
  /**
   * This switch controls whether or not single payments shall be
   * hidden from the data or not.
   */
  private settingHideSinglePayments = false;
  /**
   * This selectButton select monthly or annualy amount
   */
  private monthlyAnnualScale = 'monthly';

  // -------------------------------------------------------------

  /**
   * Constructor of this pension service object.
   * @param personaService  The persona service responsible for accessing the current logged in persona.
   * @param storageService  The storage service necessary to access the relevant settings for the pension service.
   */
  constructor(private personaService: PersonaService, private storageService: StorageService) {

    // Obtain the current logged in persona. Since the service itself only gets deleted in case of an
    // application shutdown, we dont need to store the subscription and unsubscribe later on.
    this.personaService.usePersona().subscribe((persona) => {

      this.persona = persona;
      if (this.persona) {
        // initialisation from storage
        this.settingHideSinglePayments = storageService.getHideSinglePayments();
        this.settingConvertMonthlySinglePayments = storageService.getSingleMonthlyConversion();
        this.monthlyAnnualScale = this.storageService.getMonthlyAnnualScale();

        this.subscribeToSettings();
      } else {
        this.unsubscribeToSettings();
      }
    });
  }


  /**
   * retrieve the amount from occurrence of paymentBlockAsDate as param
   * in paymentBlockMapTypeForInstitutions or paymentBlockMapLifelongForInstitutions as param
   *
   * @param paymentBlockMapTypeForInstitutions (paymentBlockMapTypeForInstitutions or paymentBlockMapLifelongForInstitutions)
   * @param paymentBlockAsDate (key of Map<Date, AccumulatedPension> either paymentBlockMapTypeForInstitutions or
   * paymentBlockMapLifelongForInstitutions)
   */
  private static retrieveAmountPensionInOccurenceOfPaymentBlock(paymentBlockMapTypeForInstitutions: Map<string, AccumulatedPension>,
                                                                paymentBlockAsDate: string) {
    let oldAmountPensionInOccurenceOfPaymentBlock = 0;
    const occurenceOfPensionInPaymentBlock: AccumulatedPension = paymentBlockMapTypeForInstitutions.get(paymentBlockAsDate);
    if (occurenceOfPensionInPaymentBlock !== undefined) {
      oldAmountPensionInOccurenceOfPaymentBlock = occurenceOfPensionInPaymentBlock.amount;
    }
    return oldAmountPensionInOccurenceOfPaymentBlock;
  }

  /**
   * This helper method obtains the institutions array specified by the institutionType from the given
   * persona object and returns it.
   *
   * @param persona         The persona object holding the institutions we want to obtain.
   * @param institutionType The type of institution we want to obtain.
   * @returns Returns an array of institutions or null if no type or persona has been provided.
   */
  public static getInstitutionsFromPersona(persona: Persona, institutionType: InstitutionType): Institution[] {

    if (persona == null) {
      throw new Error('Error: Could not obtain an institution from the persona because no persona has been provided.');
    }

    switch (institutionType) {
      case InstitutionType.bav:
        return persona.bAV.institutions;
      case InstitutionType.grv:
        return persona.GRV.institutions;
      case InstitutionType.pav: {
        return persona.pAV.institutions;
      }
      default: {
        throw new Error('Error: Invalid institution type (' + institutionType + ') detected. Stopping getPensionPerInstitution method');
      }
    }
  }

  /**
   * The method converts the given string into the corresponding enum type. If no matching enum type
   * could be found, the method will return null;
   * @param paymentTypeString The string which shall be converted into the enum type.
   * @returns Returns the enum type if a matching type could be found. Otherwise null.
   */
  private static getPaymentTypeFromString(paymentTypeString: string): PaymentType {

    let localPaymentTypeString = '';
    if (paymentTypeString) {
      localPaymentTypeString = paymentTypeString;
    }

    switch (localPaymentTypeString) {
      case PaymentType.single: {
        return PaymentType.single;
      }
      case PaymentType.lifelong: {
        return PaymentType.lifelong;
      }
      case PaymentType.periodic: {
        return PaymentType.periodic;
      }
      default: {
        return PaymentType.lifelong;
      }
    }
  }

  /**
   * The method returns the date of retirement, it adds the given age to the given birthdate and returns the resulting date.
   *
   * @param age       The age when the pension will be payed out.
   * @param birthdate The birthdate of the persona.
   * @returns Returns the date on when the pension will be payed out.
   */
  private static calculateStartDate(age: number, birthdate: string | Date): Date {

    // Check if all parameters have been provided.
    if (age == null || age < 0 || birthdate == null) {
      console.log('Error: calculateStartDate could not calculate anything since the age (' + age + ') or ' +
        'the birthdate (' + birthdate + ') is invalid');
      return null;
    }

    // Calculate the start date.
    let birthdateMoment: moment.Moment = null;
    if (typeof birthdate === 'string') {
      birthdateMoment = moment(birthdate, 'DD.MM.YYYY');
    } else {
      birthdateMoment = moment(birthdate);
    }

    return birthdateMoment.add(age, 'years').toDate();
  }

  /**
   * Obtain the pension for a given institution type for the specified persona.
   * If institutionType is null, return data for all institutionTypes, else only the data for the given institutionType
   *
   * @param persona         The persona object holding the institutions.
   * @param institutionType The institution which shall be accumulated.
   * @returns Returns a AccumulatedPensionPerInstitution object holding the requested information.
   *
   */
  getPensionPerInstitution(persona: Persona, institutionType: InstitutionType): Observable<AccumulatedPensionPerInstitution> {

    const accumulatedPensionPerInstitution: AccumulatedPensionPerInstitution =
      this.getPensionPerSingleInstitution(persona, institutionType);

    return of(accumulatedPensionPerInstitution);
  }

  /**
   * Get the accumulated pension data for all columns separated by payment type for the specified persona
   *
   * @param persona The persona for which the function will return the data
   *
   * @param splitPeriodic wether to split periodic payments into multiple entries
   * @return An Observable for an array of AccumulatedPaymentBlocks which contain the data for all columns
   */
  public getAccumulatedPensionForAllColumns(persona: Persona, splitPeriodic): Observable<AccumulatedPaymentBlocks[]> {

    let accumulatedPensionPerGrv: AccumulatedPensionPerInstitution;
    let accumulatedPensionPerBav: AccumulatedPensionPerInstitution;
    let accumulatedPensionPerPav: AccumulatedPensionPerInstitution;

    accumulatedPensionPerGrv = this.getPensionPerSingleInstitution(persona, InstitutionType.grv, splitPeriodic);
    accumulatedPensionPerBav = this.getPensionPerSingleInstitution(persona, InstitutionType.bav, splitPeriodic);
    accumulatedPensionPerPav = this.getPensionPerSingleInstitution(persona, InstitutionType.pav, splitPeriodic);

    const accGrv: AccumulatedPaymentBlocks = {
      single: this.getAccumulatedPensionData(accumulatedPensionPerGrv.paymentBlock_grv, PaymentType.single),
      lifelong: this.getAccumulatedPensionData(accumulatedPensionPerGrv.paymentBlock_grv, PaymentType.lifelong),
      incompleteContracts: accumulatedPensionPerGrv.paymentBlock_grv ? accumulatedPensionPerGrv.paymentBlock_grv.incompleteContracts : null,
      singlePaymentsHidden: accumulatedPensionPerGrv.paymentBlock_grv ? accumulatedPensionPerGrv.paymentBlock_grv.singlePaymentsHidden :
        null
    };

    const accBav: AccumulatedPaymentBlocks = {
      single: this.getAccumulatedPensionData(accumulatedPensionPerBav.paymentBlock_bav, PaymentType.single),
      lifelong: this.getAccumulatedPensionData(accumulatedPensionPerBav.paymentBlock_bav, PaymentType.lifelong),
      incompleteContracts: accumulatedPensionPerBav.paymentBlock_bav ? accumulatedPensionPerBav.paymentBlock_bav.incompleteContracts : null,
      singlePaymentsHidden: accumulatedPensionPerBav.paymentBlock_bav ?
        accumulatedPensionPerBav.paymentBlock_bav.singlePaymentsHidden : null
    };

    const accPav: AccumulatedPaymentBlocks = {
      single: this.getAccumulatedPensionData(accumulatedPensionPerPav.paymentBlock_pav, PaymentType.single),
      lifelong: this.getAccumulatedPensionData(accumulatedPensionPerPav.paymentBlock_pav, PaymentType.lifelong),
      incompleteContracts: accumulatedPensionPerPav.paymentBlock_pav ? accumulatedPensionPerPav.paymentBlock_pav.incompleteContracts : null,
      singlePaymentsHidden: accumulatedPensionPerPav.paymentBlock_pav ?
        accumulatedPensionPerPav.paymentBlock_pav.singlePaymentsHidden : null
    };

    const accForAllColumns: AccumulatedPaymentBlocks[] = [];
    // tslint:disable-next-line
    accForAllColumns['grv'] = accGrv;
    // tslint:disable-next-line
    accForAllColumns['bav'] = accBav;
    // tslint:disable-next-line
    accForAllColumns['pav'] = accPav;

    if (!splitPeriodic) {
      this.getAccumulatedPensionForAllColumnsDataNotSplit$.next(accForAllColumns);
      return this.getAccumulatedPensionForAllColumnsDataNotSplit$;
    }

    this.getAccumulatedPensionForAllColumnsData$.next(accForAllColumns);
    return this.getAccumulatedPensionForAllColumnsData$;
  }

  /**
   * Get the accumulated pensions for the different payment types
   * added up across all columns of the retirement provision system
   *
   * @param persona The persona for which we want the pension information
   *
   * @param institutionType type of institution
   * @param splitPeriodic wether to split periodic payments into multiple entries
   * @return PaymentBlocks a structure which holds the requested information
   */
  public getPensionPerPaymentType(persona: Persona, institutionType: InstitutionType, splitPeriodic = true): PaymentBlocks {
    let accumulatedPensionPerInstitution: AccumulatedPensionPerInstitution = {
      paymentBlock_grv: null,
      paymentBlock_bav: null,
      paymentBlock_pav: null
    };

    const combinedPaymentBlocks: PaymentBlocks = {
      paymentBlockMapSingle: null,
      paymentBlockMapLifelong: null,
      incompleteContracts: null,
      singlePaymentsHidden: false
    };

    let institutionInformationsArray: Institution[] = [];
    institutionInformationsArray = this.getInstitutions(persona, institutionType);

    // Sort the array
    institutionInformationsArray.sort((entryA, entryB) => {
      if (entryA && entryB) {
        return (entryA.retirementAge as number) - (entryB.retirementAge as number);
      }
    });

    accumulatedPensionPerInstitution =
      this.accumulatePensionForInstitutions((persona ? persona.birthdate : null), institutionInformationsArray,
        null, accumulatedPensionPerInstitution, splitPeriodic);

    const singlePayments: Map<string, AccumulatedPension> = accumulatedPensionPerInstitution.paymentBlock_grv.paymentBlockMapSingle;
    const lifelongPayments: Map<string, AccumulatedPension> = accumulatedPensionPerInstitution.paymentBlock_grv.paymentBlockMapLifelong;
    const incompleteContracts: Institution[] = accumulatedPensionPerInstitution.paymentBlock_grv.incompleteContracts;
    combinedPaymentBlocks.paymentBlockMapSingle = singlePayments;
    combinedPaymentBlocks.paymentBlockMapLifelong = lifelongPayments;
    combinedPaymentBlocks.incompleteContracts = incompleteContracts;

    return combinedPaymentBlocks;
  }

  /**
   * Returns an Observable of the accumulated pension data of the specified persona across all columns of the retirement provision system
   *
   * @param persona The persona for which we want the data
   *
   * @param institutionType Type of the institution
   * @param splitPeriodic   Whether to split a periodic payment block into multiple entries or not
   * @return An behaviour subject containing AccumulatedPaymentBlocks depending on which institution type and
   *         split periodic has been provided.
   */
  public getAccumulatedPensionPerPaymentType(persona: Persona,
                                             institutionType: InstitutionType = InstitutionType.all,
                                             splitPeriodic = true): BehaviorSubject<AccumulatedPaymentBlocks> {
    const combinedPaymentBlocks: PaymentBlocks = this.getPensionPerPaymentType(persona, institutionType, splitPeriodic);

    const accumulatedPaymentData: AccumulatedPaymentBlocks = {
      single: null,
      lifelong: null,
      incompleteContracts: null,
      singlePaymentsHidden: false
    };
    const accumulatedSingle = this.getAccumulatedPensionData(combinedPaymentBlocks, PaymentType.single);
    const accumulatedLifelong = this.getAccumulatedPensionData(combinedPaymentBlocks, PaymentType.lifelong);
    accumulatedPaymentData.single = accumulatedSingle;
    accumulatedPaymentData.lifelong = accumulatedLifelong;
    accumulatedPaymentData.incompleteContracts = combinedPaymentBlocks.incompleteContracts;

    // Depending on which data set has been requested, we update and return the corresponding behaviour subject.
    switch (institutionType) {
      case InstitutionType.bav:
        if (splitPeriodic) {
          this.getAccumulatedPensionPerPaymentTypeDataForBAV$.next(accumulatedPaymentData);
          return this.getAccumulatedPensionPerPaymentTypeDataForBAV$;
        } else {
          this.getAccumulatedPensionPerPaymentTypeDataForBAVNonSplitted$.next(accumulatedPaymentData);
          return this.getAccumulatedPensionPerPaymentTypeDataForBAVNonSplitted$;
        }
      case InstitutionType.grv:
        if (splitPeriodic) {
          this.getAccumulatedPensionPerPaymentTypeDataForGRV$.next(accumulatedPaymentData);
          return this.getAccumulatedPensionPerPaymentTypeDataForGRV$;
        } else {
          this.getAccumulatedPensionPerPaymentTypeDataForGRVNonSplitted$.next(accumulatedPaymentData);
          return this.getAccumulatedPensionPerPaymentTypeDataForGRVNonSplitted$;
        }
      case InstitutionType.pav:
        if (splitPeriodic) {
          this.getAccumulatedPensionPerPaymentTypeDataForPAV$.next(accumulatedPaymentData);
          return this.getAccumulatedPensionPerPaymentTypeDataForPAV$;
        } else {
          this.getAccumulatedPensionPerPaymentTypeDataForPAVNonSplitted$.next(accumulatedPaymentData);
          return this.getAccumulatedPensionPerPaymentTypeDataForPAVNonSplitted$;
        }
      default:
        if (splitPeriodic) {
          this.getAccumulatedPensionPerPaymentTypeData$.next(accumulatedPaymentData);
          return this.getAccumulatedPensionPerPaymentTypeData$;
        } else {
          this.getAccumulatedPensionPerPaymentTypeDataNonSplitted$.next(accumulatedPaymentData);
          return this.getAccumulatedPensionPerPaymentTypeDataNonSplitted$;
        }
    }
  }

  /**
   * Returns an Array of AccumulatedPension entries depending on the PaymentType requested
   * the information provided via paymentBlocks is either just used (single payments) or added (lifelong payments)
   * up according to PaymentType
   *
   * PaymentType.single return the amount of the single payment
   * PaymentType.lifelong adds the amount of the preceding data point into the current data point
   *
   * @param paymentBlocks PaymentBlocks all data points of one of the columns of pension system for the current persona
   * @param paymentType string type of payment
   *
   * @return An Array of AccumulatedPension with the accumulated pension data
   */
  public getAccumulatedPensionData(paymentBlocks: PaymentBlocks, paymentType: PaymentType): AccumulatedPension[] {
    const accumulatedPensionData: AccumulatedPension[] = [];
    let sum = 0;
    let paymentBlock;

    if (paymentType === PaymentType.single) {
      if (paymentBlocks) {
        paymentBlock = paymentBlocks.paymentBlockMapSingle;
      }
    } else if (paymentType === PaymentType.lifelong && paymentBlocks) {
      paymentBlock = paymentBlocks.paymentBlockMapLifelong;
    }
    if (paymentBlock) {
      paymentBlock.forEach((value: AccumulatedPension, key: string) => {
        if (paymentType === PaymentType.lifelong) {
          sum += value.amount;
        } else {
          sum = value.amount;
        }
        // Only add if we have a value
        if (sum !== 0) {
          accumulatedPensionData.push({
            startDate: value.startDate,
            endDate: value.endDate,
            amount: sum,
            age: value.age,
            paymentType: value.paymentType,
            institutionType: value.institutionType,
            incompleteContracts: null
          });
        }
      });
    }
    return accumulatedPensionData;
  }

  /**
   * This method provides access to the annuity factor of the currently
   * logged in persona depending on the given institution.
   * The institution may has a guaranteed annuity factor which can be used. If
   * not, the factor will be provided by the EstimationModel.
   * @param institution The institution with a single payment that needs to be converted.
   */
  public getAnnuityFactor(institution: Institution): number {

    let annuityFactor = institution.annuityFactorGuaranteed;
    if (!annuityFactor) {
      annuityFactor = EstimationModel.calculateAnnuityFactor(institution.retirementAge as number, this.personaService);
    }
    return annuityFactor;
  }

  /**
   * retrieves all contracts of the given persona as an array
   *
   * @param persona the persona for which we want the contracts
   * @param institutionType type of institution for which we want the contracts - defaults to 'all'
   */
  public getInstitutions(persona: Persona, institutionType = InstitutionType.all): Institution[] {
    let bavInformationsArray: Institution[] = [];
    let grvInformationsArray: Institution[] = [];
    let pavInformationsArray: Institution[] = [];
    // Get all necessary information for the different columns

    if (institutionType === InstitutionType.all || institutionType === InstitutionType.bav) {
      bavInformationsArray = PensionService.getInstitutionsFromPersona(persona, InstitutionType.bav);
    }
    if (institutionType === InstitutionType.all || institutionType === InstitutionType.grv) {
      grvInformationsArray = PensionService.getInstitutionsFromPersona(persona, InstitutionType.grv);
    }
    if (institutionType === InstitutionType.all || institutionType === InstitutionType.pav) {
      pavInformationsArray = PensionService.getInstitutionsFromPersona(persona, InstitutionType.pav);
    }

    // Combine the arrays
    let institutionInformationsArray: Institution[] = [];
    institutionInformationsArray = institutionInformationsArray.concat(grvInformationsArray);
    institutionInformationsArray = institutionInformationsArray.concat(bavInformationsArray);
    institutionInformationsArray = institutionInformationsArray.concat(pavInformationsArray);

    return institutionInformationsArray;
  }

  /**
   * This helper method is used to temporarily unsubscribe to any settings changes due to the fact that
   * the persona has been set to null (logged out).
   */
  private unsubscribeToSettings(): void {
    if (this.singleMonthlySubscription) {
      this.singleMonthlySubscription.unsubscribe();
    }
    if (this.hideSinglePaymentsSubscription) {
      this.hideSinglePaymentsSubscription.unsubscribe();
    }
    if (this.annualMonthlyScaleSubscription) {
      this.annualMonthlyScaleSubscription.unsubscribe();
    }
  }

  /**
   * This helper method is used to subscribe to any relevant settings changes for this service.
   */
  private subscribeToSettings(): void {
    this.singleMonthlySubscription = this.storageService.getSingleMonthlyConversion$().subscribe((value) => {
      if (this.settingConvertMonthlySinglePayments !== value) {
        this.settingConvertMonthlySinglePayments = value;
        this.refreshPensionData();
      }
    });

    this.hideSinglePaymentsSubscription = this.storageService.getHideSinglePayments$().subscribe((value) => {
      if (this.settingHideSinglePayments !== value) {
        this.settingHideSinglePayments = value;
        this.refreshPensionData();
      }
    });

    this.annualMonthlyScaleSubscription = this.storageService.getMonthlyAnnualScale$().subscribe((value) => {
      if (this.monthlyAnnualScale !== value) {
        this.monthlyAnnualScale = value;
        this.refreshPensionData();
      }
    });
  }

  /**
   * This method updates the current dataset and thus triggers the observers for this data.
   */
  private refreshPensionData(): void {
    // The method call will trigger the calculation again which will result
    // in the observable being updated with the new calculation.
    this.getAccumulatedPensionForAllColumns(this.persona, false);
    this.getAccumulatedPensionForAllColumns(this.persona, true);
    this.getAccumulatedPensionPerPaymentType(this.persona, InstitutionType.all, false);
    this.getAccumulatedPensionPerPaymentType(this.persona, InstitutionType.all, true);
    this.getAccumulatedPensionPerPaymentType(this.persona, InstitutionType.bav, false);
    this.getAccumulatedPensionPerPaymentType(this.persona, InstitutionType.bav, true);
    this.getAccumulatedPensionPerPaymentType(this.persona, InstitutionType.grv, false);
    this.getAccumulatedPensionPerPaymentType(this.persona, InstitutionType.grv, true);
    this.getAccumulatedPensionPerPaymentType(this.persona, InstitutionType.pav, false);
    this.getAccumulatedPensionPerPaymentType(this.persona, InstitutionType.pav, true);
  }

  /**
   * Get the pension data for a specific column of the retirement provision system
   *
   * @param persona The persona for which we want to retrieve data
   * @param institutionType The column of the retirement provision system
   *
   * @param splitPeriodic wether to split periodic payments into multiple entries
   * @return An AccumulatedPensionPerInstitution structure which holds the requested information
   */
  private getPensionPerSingleInstitution(persona: Persona,
                                         institutionType: InstitutionType,
                                         splitPeriodic = true): AccumulatedPensionPerInstitution {

    // PREPARE the return value
    const accumulatedPensionPerInstitution: AccumulatedPensionPerInstitution = {
      paymentBlock_grv: null,
      paymentBlock_bav: null,
      paymentBlock_pav: null,
    };

    // PERSONA COLLECT - Collect the institutions data from the persona.
    const institutionInformationsArray: Institution[] = PensionService.getInstitutionsFromPersona(persona, institutionType);

    if (institutionInformationsArray == null) {
      // No institutions found means:
      // We dont have do calculate anything here. Leave the function.
      return accumulatedPensionPerInstitution;
    }
    // SORT (institutionInformationsArray) the institutions by the age of when the pension will be payed out (ascending).
    institutionInformationsArray.sort((entryA, entryB) => {
      return (entryA.retirementAge as number) - (entryB.retirementAge as number);
    });

    // CALCULATE accumulatedPensionPerInstitution - Accumulate the pensions for the institution.
    // It looks inside the institutionInformationsArray, adds the pension value1
    // and saves them in the accumulatedPensionPerInstitution (return value) and in this call as passed parameter
    this.accumulatePensionForInstitutions(persona.birthdate, institutionInformationsArray, institutionType,
      accumulatedPensionPerInstitution, splitPeriodic);

    return accumulatedPensionPerInstitution;
  }

  /**
   * The method iterates through the given institutions and accumulates the pension values accordingly.
   * The value of accumulatedPensionPerInstitution as param will be changed inside
   *
   * todo: The method currently does not take the end-date of a pension payout into consideration! Mostly because
   * todo: the end-payout-date is currently not implemented in the personas.json
   *
   * @param birthdate         The birthdate of our persona. This is necessary due to calculating the start date of the pension.
   * @param apInstitutionType Type of the institution
   * @param apInstitutionType if no type is given assumes we want all three columns combined and returns
   * @param apInstitutionType a copy of the result in every PaymentBlock of accumulatedPensionPerInstitution - this is a hack
   * @param institutionInformationsArray  The institutions holding all necessary information.
   * @param accumulatedPensionPerInstitution Consolidated pension data for all columns and contracts/institutions
   * @param splitPeriodic wether to split periodic payments into multiple single entries
   */
  private accumulatePensionForInstitutions(
    birthdate: string | Date,
    institutionInformationsArray: Institution[],
    apInstitutionType: InstitutionType,
    accumulatedPensionPerInstitution: AccumulatedPensionPerInstitution,
    splitPeriodic = true
  ): AccumulatedPensionPerInstitution {
    const accumulatedPensionMap = new Map<string, AccumulatedPension>();
    const paymentBlockMapSingleForInstitutions = new Map<string, AccumulatedPension>();
    const paymentBlockMapLifelongForInstitutions = new Map<string, AccumulatedPension>();
    const incompleteContracts = [];
    let singlePaymentsHidden = false;

    // ITERATE institutionInformationsArray
    for (let institution of institutionInformationsArray) {

      // Check if all necessary values are filled properly (e.g. retirement age and payout amount).
      if (this.checkAndAddErrors(institution, accumulatedPensionMap)) {
        // If an error occurred, we skip this institution and continue with the next one.
        // Add it to the list of incomplete contracts/institutions

        // todo quick hack - one contract should not be presumed damaged since it belongs to another contract with number 1235
        // todo and should not be shown as faulty
        if (institution.id !== 1234) {
          incompleteContracts.push(institution);
        }
        continue;
      }

      // If we have to hide single payments, we are just going to skip the processing of those types.
      if (this.settingHideSinglePayments && institution.paymentCycle === PaymentType.single) {
        singlePaymentsHidden = true;
        continue;
      }

      // Perform any necessary special handling for the institution. This involves for example the
      // modification of the payment amount or the change of the payment cycle.
      institution = this.performSpecialHandling(institution);

      let paymentCycle: PaymentType = PensionService.getPaymentTypeFromString(institution.paymentCycle);
      // Assume lifelong if no payment type could be inferred
      if (paymentCycle === null) {
        paymentCycle = PaymentType.lifelong;
      }
      switch (paymentCycle) {
        case PaymentType.lifelong:
          this.addPaymentBlock(paymentCycle, paymentBlockMapLifelongForInstitutions, institution, birthdate, splitPeriodic);
          break;
        case PaymentType.periodic:
        // fall through case - use single payment BlockMap
        case PaymentType.single:
          this.addPaymentBlock(paymentCycle, paymentBlockMapSingleForInstitutions, institution, birthdate, splitPeriodic);
          break;
      }
    }

    const paymentBlocks: PaymentBlocks = {
      paymentBlockMapSingle: paymentBlockMapSingleForInstitutions,
      paymentBlockMapLifelong: paymentBlockMapLifelongForInstitutions,
      incompleteContracts,
      singlePaymentsHidden
    };

    switch (apInstitutionType) {
      case InstitutionType.grv: {
        accumulatedPensionPerInstitution.paymentBlock_grv = paymentBlocks;
        break;
      }
      case InstitutionType.bav: {
        accumulatedPensionPerInstitution.paymentBlock_bav = paymentBlocks;
        break;
      }
      case InstitutionType.pav: {
        accumulatedPensionPerInstitution.paymentBlock_pav = paymentBlocks;
        break;
      }
      default: {
        // No type given - we assume that we got a combined array of contracts and return our result in all three properties
        accumulatedPensionPerInstitution.paymentBlock_grv = paymentBlocks;
        accumulatedPensionPerInstitution.paymentBlock_bav = paymentBlocks;
        accumulatedPensionPerInstitution.paymentBlock_pav = paymentBlocks;
        break;
      }
    }

    return accumulatedPensionPerInstitution;
  }

  /**
   * This method performs any necessary special handling (e.g. conversions from monthly to yearly...)
   * on the given institution. Therefore it creates a copy of the original object to that the original
   * values get not modified at all.
   *
   * @param originalInstitution The original institution object which needs to be special-handling-performed ;)
   * @returns Returns an updated institution object.
   */
  private performSpecialHandling(originalInstitution: Institution): Institution {

    // Prepare a copy of the original institution so that we do not modify the original values.
    let updatedInstitution = Object.assign({}, originalInstitution);

    // Check if we need to transform the single payment into a lifelong payment.
    if (this.settingConvertMonthlySinglePayments && updatedInstitution.paymentCycle === PaymentType.single) {
      updatedInstitution = this.transformSingleToMonthlyPayment(updatedInstitution);
    }

    // Check if we need to transform the periodic payment into a monthly payment.
    if (this.monthlyAnnualScale === 'monthly' && updatedInstitution.paymentCycle === PaymentType.periodic) {
      updatedInstitution = this.transformPeriodicToMonthlyPayment(updatedInstitution);
    }
    // convert monthly into annualy for all paymentType except single
    if (this.monthlyAnnualScale === 'annual' && updatedInstitution.paymentCycle !== PaymentType.single) {
      updatedInstitution = this.transformLifelongPaymentMonthlyToAnnualy(updatedInstitution);
    }


    return updatedInstitution;
  }

  /**
   * add PaymentBlock in PaymentBlockMap
   *
   * @param paymentCycle (lifelong or single)
   * @param paymentBlockMap (map of PaymentBlock)
   * @param institution (GRV, bAV or pAV)
   * @param birthdate (birthdate)
   * @param splitPeriodic wether to split periodic payments into multiple entries
   *
   *
   * The start and end dates define an interval for the payment. At every edge of an interval, we need to add a new entry
   * into our map. E.g.:
   * I0 |           [ --- 100 € -------------------------------------------------------]
   * I1 |                [ --- 100 € --- ]
   * I2 |                [ --- 200 € --- ]
   * I3 |                        [ --- 100 € ----------]
   * I4 |                                                       [ --- 200 € ---- ]
   * ---|--------------------------------------------------------------------------------
   *                X    X       X        X             X       X                 X
   *                100  400     500      200           100     300               100
   *
   * TODO paymentCycle have to become obsolete!!!!
   * TODO fix date of exit
   * TODO fix paymentCycle as capital
   */
  private addPaymentBlock(paymentCycle: PaymentType, paymentBlockMap: Map<string, AccumulatedPension>,
                          institution: Institution, birthdate: string | Date, splitPeriodic = true) {

    // ----- Prepare the values of the accumulated pension entry -----
    const ageAtStartOfService = institution.retirementAge;
    const payoutStartDate: Date = PensionService.calculateStartDate(ageAtStartOfService as number, birthdate);
    const endOfLifeDate: Date = new Date(this.DATE_END_OF_LIFE);
    // todo: We need the real end-date in the future! (we could calculate one for the periodic contract type)
    const payoutEndDate = endOfLifeDate;

    if (payoutStartDate && payoutEndDate && paymentCycle) {
      // Creating a usable index for the map to write and read values
      const writeStartDateKey = moment(payoutStartDate).format(this.DATE_INDEX_FORMAT);
      const writeEndDateKey = moment(payoutEndDate).format(this.DATE_INDEX_FORMAT);
      const indexEndOfLife = moment(endOfLifeDate).format(this.DATE_INDEX_FORMAT);

      // sums right now before adding up the
      const oldSumStart = PensionService.retrieveAmountPensionInOccurenceOfPaymentBlock(paymentBlockMap, writeStartDateKey);
      const oldSumEnd = PensionService.retrieveAmountPensionInOccurenceOfPaymentBlock(paymentBlockMap, writeEndDateKey);

      let addValue;
      // Use correct value for adding up depending on contract type
      // todo this is a quick 'hack' - time saver since we only have this case
      switch (institution.contractType) {
        case ContractType.fund: {
          addValue = institution.value3_1;
          break;
        }
        default: {
          addValue = institution.value1;
          break;
        }
      }
      // If its a single payment we maybe have to use another value to add up the sum
      if (paymentCycle === PaymentType.single) {
        // todo this is a quick 'hack' - time saver
        // If value1 is empty try for value3_6
        if (institution.value1 === null) {
          addValue = institution.value3_6;
        }
      } else if (paymentCycle === PaymentType.lifelong) {
        // todo this is a quick 'hack' - time saver
        // If value1 is empty try for value2
        if (institution.value1 === null) {
          addValue = institution.value2;
        }
      }

      if (paymentCycle === PaymentType.periodic) {
        paymentBlockMap = this.addPeriodic(
          paymentBlockMap,
          institution,
          payoutStartDate,
          payoutEndDate,
          writeStartDateKey,
          indexEndOfLife,
          splitPeriodic);
      } else {
        const newValueStart = oldSumStart + addValue;
        const newValueEnd = oldSumEnd - addValue;
        // Do not write fiction entries for the fake end of life date
        if (writeStartDateKey !== indexEndOfLife) {
          paymentBlockMap.set(writeStartDateKey, {
            startDate: payoutStartDate,
            endDate: payoutEndDate,
            age: ageAtStartOfService as number,
            paymentType: paymentCycle,
            amount: newValueStart,
            institutionType: InstitutionType[institution.institutionType],
            incompleteContracts: null
          });
        }
        if (writeEndDateKey !== indexEndOfLife) {
          paymentBlockMap.set(writeEndDateKey, {
            startDate: payoutStartDate,
            endDate: payoutEndDate,
            age: ageAtStartOfService as number,
            paymentType: paymentCycle,
            amount: newValueEnd,
            institutionType: InstitutionType[institution.institutionType],
            incompleteContracts: null
          });
        }
      }
    }
  }

  private addPeriodic(paymentBlockMap: Map<string, AccumulatedPension>,
                      institution: Institution,
                      payoutStartDate: Date,
                      payoutEndDate: Date,
                      writeStartDateKey: string,
                      indexEndOfLife: string,
                      splitPeriodic: boolean) {
    // Payout starts one year after entering pension age and repeats for 10 years on the given date
    const startDateForPeriodicPayments = moment(payoutStartDate).add(1, 'year').toDate();
    // Person is one year older then
    let ageIteration = institution.retirementAge as number + 1;

    // As of yet we fake the duration since we only got one contract
    const durationInYears = 10;
    const amountPerYear = institution.value2 as number / durationInYears;
    let newValuePeriodic = PensionService.retrieveAmountPensionInOccurenceOfPaymentBlock(paymentBlockMap, writeStartDateKey);
    newValuePeriodic += amountPerYear;
    let dateForNextPayment = startDateForPeriodicPayments;
    for (let i = 0; i < durationInYears; i++) {
      if (splitPeriodic && (writeStartDateKey !== indexEndOfLife)) {
        paymentBlockMap.set(writeStartDateKey, {
          startDate: dateForNextPayment,
          endDate: payoutEndDate,
          age: ageIteration,
          paymentType: PaymentType.periodic,
          amount: newValuePeriodic,
          institutionType: InstitutionType[institution.institutionType],
          incompleteContracts: null
        });
        // We advance through time - age progresses
        dateForNextPayment = moment(dateForNextPayment).add(1, 'year').toDate();
        ageIteration++;
        writeStartDateKey = moment(dateForNextPayment).format(this.DATE_INDEX_FORMAT);
      }
    }

    if (!splitPeriodic && (writeStartDateKey !== indexEndOfLife)) {
      payoutEndDate = moment(payoutStartDate).add(durationInYears, 'year').toDate();
      paymentBlockMap.set(writeStartDateKey, {
        startDate: startDateForPeriodicPayments,
        endDate: payoutEndDate,
        age: ageIteration,
        paymentType: PaymentType.periodic,
        amount: newValuePeriodic,
        institutionType: InstitutionType[institution.institutionType],
        incompleteContracts: null
      });
    }

    return paymentBlockMap;
  }

  /**
   * In case that no retirement age or value1 has been provided, the contract will be added into the error-entry of
   * the accumulated pension map.
   * @param institution           The institution which may contains errors.
   * @param accumulatedPensionMap The updated pension map.
   * @returns Returns true if an error occurred. Otherwise false.
   */
  private checkAndAddErrors(institution: Institution, accumulatedPensionMap: Map<string, AccumulatedPension>): boolean {

    let errorMessage = '';
    const institutionName: string = institution.name + ' - ' + institution.institutionType;

    // todo replace hard-coded texts with more flexible ones
    // Check if there is any error.
    if ((institution.retirementAge == null || institution.retirementAge <= 0)) {
      errorMessage = 'Für den Vertrag ' + institutionName + ' wurde kein Renteineintrittsalter angegeben';
    }
    if (((institution.value1 === null && institution.value2 === null) && (institution.paymentCycle === PaymentType.lifelong))
      || (((institution.value1 === null) && (institution.paymentCycle === PaymentType.single))
        && ((institution.value3_6 === null) && (institution.paymentCycle === PaymentType.single)))
      || (institution.value2 === null && (institution.paymentCycle === PaymentType.periodic))) {
      if (errorMessage !== '') {
        errorMessage += ' - ';
      } else {
        errorMessage += 'Für den Vertrag ' + institutionName + ' wurde kein Value (1, 2 oder 3_6) angegeben';
      }
    }
    // Leave the method in case that no error occurred.
    if (!errorMessage) {
      return false;
    }

    // Update the error in the pension map.
    let errorEntry = accumulatedPensionMap.get(this.ERROR_KEY);
    if (errorEntry == null) {
      errorEntry = {
        startDate: null,
        endDate: null,
        age: null,
        paymentType: null,
        amount: 0,
        institutionType: null,
        incompleteContracts: []
      };
    }
    errorEntry.incompleteContracts.push({message: errorMessage});
    accumulatedPensionMap.set(this.ERROR_KEY, errorEntry);
    return true;
  }


  /**
   * This method transforms a given single-payment institution into a lifelong-payment institution depending
   * on the annuity factor. This factor will either be obtained by the given institution or calculated depending
   * on the persona.
   * Attention: This method modifies the institution object given as parameter! Consider passing a copy
   * in case that you do not want the original object to be modified!
   *
   * @param institution The institution which shall be transformed into a lifelong payment institution.
   * @returns Returns the new institution which holds the data for a lifelong payment.
   */
  private transformSingleToMonthlyPayment(institution: Institution): Institution {

    const annuityFactor = this.getAnnuityFactor(institution);
    // Special case: Kowalke -> the value is saved in 3_6 instead of 1...
    const singlePaymentAmount = (institution.value1 > 0 ? institution.value1 : institution.value3_6);
    const monthlyPaymentAmount = EstimationModel.getMonthlyAmount(singlePaymentAmount as number, annuityFactor);

    // Transform the institution into a lifelong payout institution.
    institution.paymentCycle = PaymentType.lifelong;
    institution.value1 = monthlyPaymentAmount;
    return institution;
  }

  /**
   * This method transforms the periodic-payment amount into a monthly amount by dividing it by 12.
   * It does not change anything else. So the payment cycle (periodic) stays the same.
   *
   * Attention: This method modifies the institution object given as parameter! Consider passing a copy
   * in case that you do not want the original object to be modified!
   *
   * @param institution The periodic institution whose value2 shall be divided by 12.
   * @returns Returns the modified institution object.
   */
  private transformPeriodicToMonthlyPayment(institution: Institution): Institution {
    // In case of Wöhlert, the actual amount is saved in value2.
    institution.value2 = (institution.value2 as number) / 12;
    return institution;
  }

  /**
   * This method transforms the lifelong payment amount into an annually amount by multiplying it by 12.
   * It does not change anything else. So the payment cycle (periodic) stays the same.
   *
   * Attention: This method modifies the institution object given as parameter! Consider passing a copy
   * in case that you do not want the original object to be modified!
   *
   * @param institution The periodic institution whose payment amount shall be multiplied by 12.
   * @returns Returns the modified institution object.
   */
  private transformLifelongPaymentMonthlyToAnnualy(institution: Institution): Institution {
    // Check which value we need to use. ATTENTION: e.g. Franzi has no value1. So we use value2 instead. BUT this
    // should definetly be fixed properly before production!!!!
    if (institution.contractType === ContractType.fund) {
      // Special Case Wöhlert...
      (institution.value3_1 as number) *= 12;
      return institution;
    }
    if (institution.value1) {
      institution.value1 = institution.value1 as number * 12;
    } else if (institution.value2) {
      // Special case Wöhlert: The payment amount is already annual. So dont calculate it here.
      if (institution.paymentCycle === PaymentType.periodic) {
        return institution;
      }
      institution.value2 = institution.value2 as number * 12;
    }
    return institution;
  }
}
