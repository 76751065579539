import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Routes} from '../../routes';
import {AuthenticationService} from '../../_authentication/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  /**
   * Default constructor
   * @param router                The router object used to identify whether or not we are currently in the process of a login or logout.
   * @param authenticationService The authentication service is necessary to perform the login/logout.
   */
  constructor(private router: Router,
              private authenticationService: AuthenticationService) {
  }

  /**
   * Angular lifecycle method that gets called every time the component is shown.
   */
  ngOnInit(): void {
    // The url starts with a slash. Since we compare the url with our routes, we need to remove it before the comparison.
    const currentUrl = this.router.url.substr(1);

    // Check what we need to do since this component is used for both, the login and logout process.
    if (currentUrl === Routes.LOGOUT_ROUTE) {
      this.logout();
    } else if (currentUrl === Routes.LOGIN_ROUTE) {
      // Redirect to home if we are already logged in.
      if (this.authenticationService.currentUserValue) {
        this.router.navigate([Routes.HOME_ROUTE]);
      }
    }
  }

  /**
   * Performs or at least triggers any necessary operation for the logout process.
   */
  private logout(): void {
    this.authenticationService.logout();
    this.router.navigate([Routes.LOGIN_ROUTE]);
  }

}
