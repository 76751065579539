import {Component, OnDestroy, OnInit} from '@angular/core';
import GlossarJSON from '../../../../../assets/glossar.json';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {Routes} from '../../../../routes';

@Component({
  selector: 'app-entry-links',
  templateUrl: './entry-links.component.html',
  styleUrls: ['./entry-links.component.scss']
})
export class EntryLinksComponent implements OnInit, OnDestroy {

  public glossaryLink: string;
  public links = [];
  private routeSubscription: Subscription;
  private letter;

  /**
   * Default constructor.
   * @param route The current activated route.
   */
  constructor(private route: ActivatedRoute) {
    this.glossaryLink = Routes.GLOSSAR_ROUTE.startsWith('/') ? Routes.GLOSSAR_ROUTE : '/' + Routes.GLOSSAR_ROUTE;
  }

  /**
   *
   */
  ngOnInit() {
    this.routeSubscription = this.route.url.subscribe((url) => {
      // The url object contains of several UrlSegments. We are interested in the first one which in the following
      // example will map to the 'X': -- > /glossary/X/
      if (url.length > 0) {
        this.letter = url[0].path[0];
        this.populateLinksFromJSON();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  /**
   * This private helper method interprets the JSON file and fills the array with all possible anchor references / links.
   */
  private populateLinksFromJSON(): void {
    this.links = [];

    const contentForLetter = GlossarJSON[this.letter];
    if (contentForLetter) {
      // tslint:disable-next-line:forin
      for (const key in contentForLetter) {
        this.links.push(key);
      }
    }
  }

}
