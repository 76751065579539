import {Pipe, PipeTransform} from '@angular/core';

/**
 * replace empty value with '–' (Halbgeviertstrich / en dash, en rule or nut)
 *
 * todo replace emptyValue pipe with mark-empty-fields directive
 */
@Pipe({
  name: 'emptyValue'
})
export class EmptyValuePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (typeof value === 'undefined' || !value) {
        return '–';
    }

    return value;
  }

}
