import {Component, OnInit} from '@angular/core';
import {Persona} from '../../persona.Persona';
import {MenuItem} from 'primeng/api';
import * as institution from '../../institution.Institution';
import {DbkDataViewColumnConfigData} from '@webgui-ng/components/lib/dbk-data-view/dbk-data-view-column-config-data';
import {Observable, Subscription} from 'rxjs';
import {PersonaService} from '../../persona.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PensionService} from '../../pensionService/pension.service';
import {Institution} from '../../institution.Institution';

@Component({
  selector: 'app-bav-summary',
  templateUrl: './bav-summary.component.html',
  styleUrls: ['./bav-summary.component.scss']
})
export class BavSummaryComponent implements OnInit {


  persona: Persona;

  public cols: any;

  pensionTypes: MenuItem[];

  InstitutionLabels = institution.InstitutionLabels;
  InstitutionTypeLabels = institution.InstitutionTypeLabels;
  InstitutionHelpTexts = institution.InstitutionHelpTexts;

  institutions: Institution[];

  // Column configuration for the <dbk-data-view>
  columnsConfigData: DbkDataViewColumnConfigData[] = [
    {
      label: institution.InstitutionLabels.name,
      dataKey: 'name'
    },
    {
      label: institution.InstitutionLabels.retirementAge,
      dataKey: 'retirementAge',
    },
    {
      label: institution.InstitutionLabels.paymentCycle,
      dataKey: 'paymentCycle'
    },
    {
      label: institution.InstitutionLabels.value1,
      dataKey: 'value1',
    }
  ];

  private persona$: Observable<Persona>;
  private personaSubscription: Subscription;


  /**
   * Constructor of this statutory component.
   * @param personaService Service for persona data access
   * @param route The current route which represents the current url
   * @param router The router which allows to navigate and redirect via url manipulation
   * @param pensionService The pension service
   */
  constructor(public personaService: PersonaService,
              public route: ActivatedRoute,
              public router: Router,
              public pensionService: PensionService) {
  }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   * todo: Evtl. persona as @input here and in bav and pav components
   */
  ngOnInit() {
    this.persona$ = this.personaService.usePersona();
    this.personaSubscription = this.persona$.subscribe(value => this.persona = value);


    this.institutions =  this.persona.bAV.institutions.map(obj => ({...obj}));


    for (let i = 0, len = this.institutions.length; i < len; i++) {
      if (this.institutions[i].retirementAge) {
        this.institutions[i].retirementAge += ' Jahre';
      } else {
        this.institutions[i].retirementAge = '-';
      }
      if (this.institutions[i].value1) {
        this.institutions[i].value1 += ' €';
      } else if (this.institutions[i].value2) {
        this.institutions[i].value1 = this.institutions[i].value2 + ' €';
      } else {
        this.institutions[i].value1 = '-';
      }
      if (this.institutions[i].paymentCycle) {
         // this.institutions[i].paymentCycle += ' monatlich';
      } else {
        this.institutions[i].paymentCycle = '-';
      }

    }
  }
}
