import { Component, OnInit } from '@angular/core';
import {DbkDataViewSortHeaderColumnsComponent} from '@webgui-ng/components';
import * as institution from '../../../institution.Institution';

@Component({
  selector: 'app-bav-table-header',
  templateUrl: './bav-table-header.component.html',
  styleUrls: ['./bav-table-header.component.scss']
})
export class BavTableHeaderComponent extends DbkDataViewSortHeaderColumnsComponent implements OnInit {

  InstitutionLabels = institution.InstitutionLabels;

  /**
   * Constructor of this table header component. The table header can be used as an element for the bav table.
   */
  constructor() {
    super();
  }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   */
  ngOnInit() {
  }

}
