import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {AuthenticationService} from '../../_authentication/authentication.service';
import {StorageService} from '../../storageService/storage.service';
import {Routes} from '../../routes';
import {GuidedTourService} from '@webgui-ng/components';
import {InternalMessageService} from '../../shared/internal-message-service/internal-message.service';
import {GuidedToursLabels} from '../../shared/guidedTours/guided-tours-labels';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  private CARRIER_DATA = 'CARRIER_DATA';

  public loginForm: FormGroup;
  public isLoading = false;
  public isSubmitted = false;
  public errorMessage = '';

  GuidedToursLabels = GuidedToursLabels;

  /**
   * After a successful login, the user will be redirected to this url.
   */
  public returnUrl: string;

  /**
   * This switch indicates whether or not the carrier data are currently being obtained.
   * If it is enabled (true), a loading animation will be shown. Otherwise not.
   */
  public isObtainingData: boolean;


  /**
   * Default constructor.
   * @param formBuilder           The form builder is used to create the login form.
   * @param route                 The route holds all information that have been provided in the current url.
   * @param router                The router is used to redirect the user after a successful login.
   * @param authenticationService After submitting the form, the authentication service is used to perform the actual login.
   * @param storageService        With the use of the persona list, there are additional information available, with which person
   *                              the login fields have to be prefilled with.
   * @param guidedTourService     The guided tour service which will explain the application to the user.
   */
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private guidedTourService: GuidedTourService,
    private internalMessageService: InternalMessageService
  ) {
    this.isObtainingData = false;
  }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   */
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // Get the return url from the route parameters or default to home.
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || Routes.HOME_ROUTE;

    // Check the storage service if there is a persona selected.
    const nameKey = 'name';
    const passwordKey = 'password';
    const userDataForPreallocation = this.storageService.getPreallocateLoginWith();
    if (userDataForPreallocation && userDataForPreallocation[nameKey].length > 0) {
      this.getFormControls.username.setValue(userDataForPreallocation[nameKey]);
      this.getFormControls.password.setValue(userDataForPreallocation[passwordKey]);
    }
  }

  /**
   * This helper method returns a reference to the form controls.
   * @returns Returns a reference to the form controls.
   */
  public get getFormControls() {
    return this.loginForm.controls;
  }

  /**
   * This method gets triggered by the form in case that the user clicks the 'submit' button.
   */
  public onSubmit() {

    this.isSubmitted = true;

    // Stop here if the form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading = true;

    // Perform the login process.
    this.authenticationService.login(this.getFormControls.username.value, this.getFormControls.password.value)
      .pipe(first())
      .subscribe(
        async user => {

          // Check if we need to fake the waiting time to obtain carrier data or not.
          let carrierDataArray = JSON.parse(localStorage.getItem(this.CARRIER_DATA));
          if (!carrierDataArray) {
            carrierDataArray = [];
          }

          let currentState = carrierDataArray.find(x => x.personaId === user.id);
          if (!currentState || currentState.dataObtained === false) {
            // Fake a waiting time which will later be used to obtain the data from the carriers.
            this.isObtainingData = true;
            const waitFunction = (ms: number) => {
              return new Promise(resolve => setTimeout(resolve, ms));
            };
            await waitFunction(1500);
            this.isObtainingData = false;

            // Save the state of the carrier data in the storage so that we dont have to obtain the data again.
            currentState = {personaId: user.id, dataObtained: true};
            carrierDataArray.push(currentState);
            localStorage.setItem(this.CARRIER_DATA, JSON.stringify(carrierDataArray));
          }

          // In case that the login is successful, data will be passed and we can navigate to the return url.
          if (this.storageService.isGuidedTourActive()) {
            const message = {
              sender: 'LoginFormComponent',
              receiver: 'AppComponent',
              content: {
                command: 'startLoggedInTour'
              }
            };
            this.internalMessageService.sendMessage(message);
          } else {
            this.router.navigate([this.returnUrl]);
          }
        },
        error => {
          // In case of an error, we show the error in the form and stop the current login.
          this.errorMessage = error.error.message;
          console.log(this.errorMessage);
          this.isLoading = false;
        });
  }
}
