import {Component, OnInit, ViewChild} from '@angular/core';
import {MiscLabels} from '../../misc.labels';
import {PensionChartComponent} from '../../charts/pension-chart/pension-chart.component';
import {InstitutionType} from '../../institution.Institution';
import {AuthenticationService} from '../../_authentication/authentication.service';
import {Persona} from '../../persona.Persona';
import {PersonaService} from '../../persona.service';
import {GuidedToursLabels} from '../../shared/guidedTours/guided-tours-labels';
import {StorageService} from '../../storageService/storage.service';

@Component({
  selector: 'app-pav',
  templateUrl: './pav.component.html',
  styleUrls: ['./pav.component.scss']
})
export class PavComponent implements OnInit {

  public loggedIn = false;
  public persona: Persona;

  MiscLabels = MiscLabels;
  GuidedToursLabels = GuidedToursLabels;

  selectedDesign = 'table';

  public activeTabViewIndex = 0;

  institutionTypeEnum = InstitutionType;

  @ViewChild('pensionChart', {static: false})
  pensionChartComponent: PensionChartComponent;

  constructor(private authenticationService: AuthenticationService, private personaService: PersonaService,
              private storageService: StorageService) {
  }

  ngOnInit() {
    this.loggedIn = this.authenticationService.getCurrentUser$().value !== null;
    this.persona = this.personaService.getPersona$().value;
  }

  /**
   * This event handler gets called in case that the chart-tab has been activated. It is used to
   * redraw the chart depending on the amount of space which will be provided by the tab-area.
   * @param e as event
   */
  handleChange(e) {
    window.dispatchEvent(new Event('resize'));

    if (e.value === undefined) {
      if (this.storageService.getPresentation() === undefined) {
        this.selectedDesign = 'table';
      } else {
        this.selectedDesign = this.storageService.getPresentation();
      }
    } else {
      this.storageService.setPresentation(e.value);
      this.selectedDesign = e.value;
    }

    // if (this.pensionChartComponent) {
    //   this.pensionChartComponent.chart.wrapper.draw(this.pensionChartComponent.chart.getChartElement());
    //
    // }


  }
}
