import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AgencyLogoComponent} from './agency-logo.component';
import {VisualizationToolsModule} from '../../visualization-tools/visualization-tools.module';


@NgModule({
  declarations: [AgencyLogoComponent],
  imports: [
    CommonModule,
    VisualizationToolsModule
  ],
  exports: [
    AgencyLogoComponent
  ]
})
export class AgencyLogoModule {
}
