import { Component, OnInit } from '@angular/core';
import {PersonaService} from '../../persona.service';
import * as institution from '../../institution.Institution';
import {MiscLabels} from '../../misc.labels';

@Component({
  selector: 'app-per-institution',
  templateUrl: './per-institution.component.html',
  styleUrls: ['./per-institution.component.scss']
})
export class PerInstitutionComponent implements OnInit {

  bav = institution.InstitutionType.bav;
  pav = institution.InstitutionType.pav;
  grv = institution.InstitutionType.grv;
  InstitutionTypeLabels = institution.InstitutionTypeLabels;
  MiscLabels = MiscLabels;



  constructor(personaService: PersonaService) { }

  ngOnInit() {
  }

}
