import {Component, OnDestroy, OnInit} from '@angular/core';
import {MiscLabels} from '../../misc.labels';
import {Router} from '@angular/router';
import {Routes} from '../../routes';
import {AccordionModule} from 'primeng/accordion';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  MiscLabels = MiscLabels;
  Routes = Routes;

  constructor(private router: Router) { }

  ngOnInit() {
  }

}
