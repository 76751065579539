import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {BioTypeText, Persona} from '../../persona.Persona';
import {PersonaService} from '../../persona.service';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../_authentication/authentication.service';
import {StorageService} from '../../storageService/storage.service';
import {Routes} from '../../routes';
import {MiscLabels} from '../../misc.labels';

@Component({
  selector: 'app-persona-list',
  templateUrl: './persona-list.component.html',
  styleUrls: ['./persona-list.component.scss']
})
export class PersonaListComponent implements OnInit, OnDestroy {

  public styles: string[] = [
    'default',
    'Cards',
    'Cards + Collapse',
    // 'Table',
    // 'Carousel',
    // 'Filter'
  ];
  // Cards => y*x Matrix bei der alle Informationen auf einen Schlag sichtbar sind
  // Cards + Collapse => y*x Matrix bei der die Personas nur mit Bild und wichtigen Daten abgebildet werden. Bei Klick
  //                    auf die Karte werden dann unterhalt alle weiteren Daten angezeigt
  // Table => sinnlos? Weil man sowieso nicht alle Daten gut abgebildet bekommt?
  // Carousel => 1 'Seite' pro Persona mit großem Bild? Und Informationen schön aufbereitet...
  //             (https://mdbootstrap.com/docs/angular/advanced/mobile/)
  // Filter: Generell überall anwendbar -> Erlaubt dem Benutzer das Selektieren verschiedener Kriterien und daraufhin
  //         lichtet sich eben die Ergebnismenge...

  public backgroundLabel = 'Alter, Ausbildung, Familienstand';
  public pensionBiographyLabel = 'Altersvorsorge-Biographie';
  public GRVLabel = 'Gesetzliche Rente';
  public bAVLabel = 'Betriebliche Vorsorge';
  public pAVLabel = 'Private Vorsorge';
  public bioTypeText = BioTypeText;
  public behaviourAndWishesLabel = 'Informationsverhalten und -wünsche';
  public selectedStyle: string = this.styles[0];
  public personaSubscription: Subscription;
  public selectedPersona: Persona;
  public selectedPersonaId: number;
  public personas: Persona[];
  MiscLabels = MiscLabels;

  /**
   * Constructor.
   * @param personaService A service object which provides access to the persona data.
   * @param router The router which allows to navigate further into the SDAVI depths.
   */
  constructor(
    public personaService: PersonaService,
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    public router: Router
  ) {
    this.storageService.setPreallocateLoginWith('', '');
    this.authenticationService.logout();
  }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   */
  ngOnInit() {
    this.personaSubscription = this.personaService.getPersonas(0).subscribe(value => this.personas = value);
  }

  /**
   * Lifecycle method that will be called if this component gets destroyed.
   */
  ngOnDestroy(): void {
    if (this.personaSubscription) {
      this.personaSubscription.unsubscribe();
    }
  }

  /**
   * Cuts the given text down to the specified length of characters
   *
   * @param text The text which shall be shortened.
   * @param length The length to which the text shall be shortened.
   *
   * @return string The shortened text plus '...' at the end to visualize if more content was available.
   */
  cutText(text: string, length: number): string {
    return text.substr(0, length) + (text.length >= length ? '...' : '');
  }

  /**
   * JS bind function to set the currently selected persona's id
   * and show the additional information for this persona
   *
   * @param selectedPersona The persona object which just got selected in the view.
   */
  onPersonaSelect(selectedPersona: Persona) {
    this.selectedPersona = selectedPersona;
    this.selectedPersonaId = selectedPersona.id;
  }

  /**
   * Removes the selection of a persona and its associated information when mouse leaves table
   */
  onPersonaDeselect() {
    this.selectedPersona = null;
  }

  /**
   * Saves the current selected persona data and navigates to the login process.
   */
  triggerLogin(): void {
    this.storageService.setPreallocateLoginWith(this.selectedPersona.firstname + ' ' + this.selectedPersona.lastname, 'password');
    this.router.navigate([Routes.LOGIN_ROUTE]);
  }

}
