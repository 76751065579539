import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MenuItem} from 'primeng/api';
import {Menus} from './menus';
import {AuthenticationService} from '../_authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class MenuBarService {

  /**
   * The behaviour subject which holds all menu bar items that the user currently can see.
   * It is also used to signal any changes on that model to the navigation bar.
   */
  private readonly menuBarItems$: BehaviorSubject<MenuItem[]>;

  /**
   * Default constructor.
   *
   * @param authenticationService The authentication service which is needed to find out whether or not we are currently logged in.
   */
  constructor(private authenticationService: AuthenticationService) {
    this.menuBarItems$ = new BehaviorSubject<MenuItem[]>([]);

    // Subscribe to the logged in state, so that we can update the menuBar accordingly.
    this.authenticationService.getCurrentUser$().subscribe((user) => {
      this.updateMenuBar(user !== null);
    });
  }

  /**
   * This private method updates the menuBarItems$ behaviour subject depending on the
   * provided logged-in state.
   *
   * Hint: Currently, the enabling/disabling of sub-menu-entries (e.g. File -> Save, File -> Load)
   * depending on the logged-in state is not possible. This requires further logic inside this method.
   *
   * @param isLoggedIn  This switch indicates whether or not we are currently logged in.
   */
  private updateMenuBar(isLoggedIn: boolean): void {

    const menuItems = [];

    Menus.menuEntries.forEach((menuItem) => {
      // Check if this menu item is only allowed for logged in users. If so, we skip
      // the processing of this entry in case that the user is not logged in.
      if (menuItem.requiresAuthentication) {
        // Only show menus that require a logged in state IF we are actually logged in.
        if (menuItem.requiresAuthentication === Menus.MenuItemAuthenticationRequirements.loggedIn && !isLoggedIn) {
          return;
        }
        // Only show menus that require a logged out state IF we are actually logged out.
        if (menuItem.requiresAuthentication === Menus.MenuItemAuthenticationRequirements.loggedOut && isLoggedIn) {
          return;
        }
      }

      // Otherwise, we add it into our new menu bar.
      menuItems.push(menuItem);
    });

    this.menuBarItems$.next(menuItems);
  }

  /**
   * This method returns the behaviour subject which informs about any
   * changes on the menuItem model.
   * @returns Returns a BehaviourSubject<MenuItem[]> which can be used for a dynamic update of a
   * navigation toolbar.
   */
  public getMenuBar$(): BehaviorSubject<MenuItem[]> {
    return this.menuBarItems$;
  }
}
