import {Component, Input, OnInit} from '@angular/core';
import {DbkDataViewColumnConfigData} from '@webgui-ng/components/lib/dbk-data-view/dbk-data-view-column-config-data';
import {AccumulatedPensionDataPerPaymentTypeTableSummary} from './accumulated-pension-data-per-payment-type-table-summary';
import {StorageService} from '../../../storageService/storage.service';
import {InstitutionType} from '../../../institution.Institution';

@Component({
  selector: 'app-table-summary',
  templateUrl: './table-summary.component.html',
  styleUrls: ['./table-summary.component.scss']
})
export class TableSummaryComponent implements OnInit {

  /**
   * informations for view
   */
  @Input() accumulatedPensionDataPerPaymentType: Array<AccumulatedPensionDataPerPaymentTypeTableSummary>;
  /**
   * hint type (warning, info, ...)
   */
  @Input() hintType = 'warning';
  /**
   * hint text
   */
  @Input() hintText: string;
  /**
   * width of tag element (default is auto else in px)
   */
  @Input() maxWidth = 'auto';

  @Input() public showDataForColumn: InstitutionType = InstitutionType.all;

  institutionTypeEnum = InstitutionType;


  columnsConfigData: DbkDataViewColumnConfigData[] = [
    {
      label: ' ',
      dataKey: 'paymentType',
      columnClasses: 'firstColumn'

    },
    {
      label: 'Alter bei Auszahlung',
      dataKey: 'period',
      columnClasses: 'secondColumn'
    },
    {
      label: 'Betrag',
      dataKey: 'amount',
      columnClasses: 'nowrap bold betrag'
    }
  ];

  constructor() {
  }

  ngOnInit() {
  }

}
