import {Component, OnInit} from '@angular/core';
import * as institution from '../../institution.Institution';
import {Institution} from '../../institution.Institution';
import {Persona} from '../../persona.Persona';
import {DbkDataViewColumnConfigData} from '@webgui-ng/components/lib/dbk-data-view/dbk-data-view-column-config-data';
import {Observable, Subscription} from 'rxjs';
import {PersonaService} from '../../persona.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-pav-summary',
  templateUrl: './pav-summary.component.html',
  styleUrls: ['./pav-summary.component.scss']
})
export class PavSummaryComponent implements OnInit {


  InstitutionLabels = institution.InstitutionLabels;
  InstitutionTypeLabels = institution.InstitutionTypeLabels;
  InstitutionHelpTexts = institution.InstitutionHelpTexts;

  persona: Persona;

  institutions: Institution[];

  // Column configuration for the <dbk-data-view>
  columnsConfigData: DbkDataViewColumnConfigData[] = [
    {
      label: institution.InstitutionLabels.name,
      dataKey: 'name'
    },
    {
      label: institution.InstitutionLabels.retirementAge,
      dataKey: 'retirementAge',
    },
    {
      label: institution.InstitutionLabels.paymentCycle,
      dataKey: 'paymentCycle'
    },
    {
      label: institution.InstitutionLabels.value1,
      dataKey: 'value1',
    }
  ];

  private persona$: Observable<Persona>;
  private personaSubscription: Subscription;

  /**
   * Constructor of this statutory component.
   * @param personaService Service for persona data access
   * @param route The current route which represents the current url
   * @param router The router which allows to navigate and redirect via url manipulation
   */
  constructor(public personaService: PersonaService, public route: ActivatedRoute, public router: Router) {


  }


  // changeLayout(event: Event, layout: string) {
  //   this.dv.changeLayout(layout);
  //   event.preventDefault();
  // }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   * todo: Evtl. persona as @input here and in bav and pav components
   */
  ngOnInit() {

    this.persona$ = this.personaService.usePersona();
    this.personaSubscription = this.persona$.subscribe(value => this.persona = value);

    // deep copy with 1 level
    this.institutions = this.persona.pAV.institutions.map(obj => ({...obj}));

    for (let i = 0, len = this.institutions.length; i < len; i++) {
      if (this.institutions[i].retirementAge) {
        this.institutions[i].retirementAge += ' Jahre';
      }
      if (this.institutions[i].value1) {
        this.institutions[i].value1 += ' €';
      } else if (this.institutions[i].value3_6) {
        this.institutions[i].value1 = this.institutions[i].value3_6 + ' €';
      }
    }

  }

}
