import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {delay, mergeMap, materialize, dematerialize} from 'rxjs/operators';
import {User} from './user';

/**
 * Define an array of possible user-password combinations for this application.
 */
const users = [];
users.push(new User(1, 'Joachim Ritter', 'password'));
users.push(new User(2, 'Franzi Kretschmer', 'password'));
users.push(new User(3, 'Lisa Schlau', 'password'));
users.push(new User(4, 'Christina Neubert', 'password'));
users.push(new User(5, 'Julius Barnhardt', 'password'));
users.push(new User(6, 'Kristina Franke', 'password'));
users.push(new User(7, 'Jasmin Wöhlert', 'password'));
users.push(new User(8, 'Jan-Stephan Kowalke', 'password'));
users.push(new User(9, 'Lieschen Ritter', 'password'));
users.push(new User(10, 'Ralf Arnold', 'password'));

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

  /**
   * The interceptor will intercept any http request to this fake backend.
   * This method is used to handle any request that has been intercepted.
   * @param request The http request that has been intercepted and needs handling.
   * @param next    The next http handler in the chain that possibly could be responsible for handling the request.
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const {url, method, headers, body} = request;

    // Wrap in delayed observable to simulate server api call.
    return of(null)
      .pipe(mergeMap(handleRoute))
      .pipe(materialize()) // call materialize and dematerialize to ensure delay even if
      // an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      .pipe(delay(500))
      .pipe(dematerialize());

    function handleRoute() {
      switch (true) {
        case url.endsWith('/users/authenticate') && method === 'POST':
          return authenticate();
        default:
          // Pass through any requests not handled above to the next handler.
          return next.handle(request);
      }
    }

    /**
     * Authentication method which gets called in case that a post request has been sent to /users/authenticate.
     * The method checks whether or not the entered username and password match any of the persona data.
     * @returns Returns an http response which holds the user object or (in case of an error) an error.
     */
    function authenticate() {
      const {username, password} = body;

      // Check if the provided data for username and password are correct.
      const user = users.find(x => x.username === username && x.password === password);
      if (!user) {
        return error('Das angegebene Passwort gehört nicht zu diesem Benutzer. Versuche es statt dessen doch mal mit "Joachim Ritter"');
      }
      // Return the http response with the user object.
      return ok({
        id: user.id,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        token: 'serverside-generated-token'
      });
    }

    /**
     * This helper method wraps a given body into an observable http response.
     * @param body The body (payload) which will be returned inside a http response.
     * @returns Returns an observable containing a http response.
     */
    // tslint:disable-next-line:no-shadowed-variable
    function ok(body?) {
      return of(new HttpResponse({status: 200, body}));
    }

    /**
     * This helper method wraps the given message inside an error object and returns it.
     * @param message The message which provides further information to the error occured.
     * @returns Returns an error with the given message as content.
     */
    function error(message) {
      return throwError({error: {message}});
    }
  }
}

export const fakeBackendProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true
};
