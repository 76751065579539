import {Component, OnDestroy, OnInit} from '@angular/core';
import {Routes} from '../../../../routes';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import GlossarJSON from '../../../../../assets/glossar.json';

export interface Letter {
  letter: string;
  contentAvailable: boolean;
}


@Component({
  selector: 'app-letter-select',
  templateUrl: './letter-select.component.html',
  styleUrls: ['./letter-select.component.scss']
})
export class LetterSelectComponent implements OnInit, OnDestroy {

  private DEFAULT_LETTER = 'A';
  private DEFAULT_CLASS = 'listentry';
  private ACTIVE_CLASS = 'listentry--active';

  public letters = [];
  public glossaryLink: string;

  public currentLetter: string;
  private routeSubscription: Subscription;
  private routeFragmentSubscription: Subscription;

  /**
   * Default constructor.
   * @param route   The url which was used to access this component.
   * @param router  The router used to navigate.
   */
  constructor(private route: ActivatedRoute, private router: Router) {
    this.glossaryLink = Routes.GLOSSAR_ROUTE.startsWith('/') ? Routes.GLOSSAR_ROUTE : '/' + Routes.GLOSSAR_ROUTE;
  }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   */
  ngOnInit(): void {
    this.routeSubscription = this.route.url.subscribe((url) => {
      // The url object contains of several UrlSegments. We are interested in the first one which in the following
      // example will map to the 'X': -- > /glossary/X/
      if (url.length > 0) {
        this.currentLetter = url[0].path;
      }
      this.validateCurrentLetter();
      this.letters = this.getAvailableLetters();
    });

    this.routeFragmentSubscription = this.route.fragment.subscribe(fragment => {
      if (fragment) {
        const element = document.getElementById(fragment);
        if (element) {
          element.scrollIntoView();
        }
      }
    });
  }

  /**
   * Lifecycle method that will be called if this component gets destroyed.
   */
  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.routeFragmentSubscription) {
      this.routeFragmentSubscription.unsubscribe();
    }
  }

  /**
   * This method validates whether or not the currentLetter has a valid value or not. If not,
   * it will automatically mapped to the next valid letter (e.g. Bus -> B, _ -> DEFAULT_LETTER).
   */
  private validateCurrentLetter(): void {
    if (!this.currentLetter) {
      this.currentLetter = this.DEFAULT_LETTER;
      this.router.navigate([Routes.GLOSSAR_ROUTE, this.currentLetter]);
    } else {
      if (this.currentLetter.length > 1) {
        this.currentLetter = this.currentLetter[0];
      } else if (this.currentLetter.length === 0) {
        this.currentLetter = this.DEFAULT_LETTER;
      }
    }

  }

  /**
   * This helper method is used by the template in order to determine the current (style) class for the list item.
   *
   * @param letter The letter of the current list item.
   * @returns Returns an array containing all classes that are necessary for this list item.
   */
  public getClassForLetter(letter: string) {
    const styleClasses = [this.DEFAULT_CLASS];
    if (letter[0] === this.currentLetter[0]) {
      styleClasses.push(this.ACTIVE_CLASS);
    }
    return styleClasses;
  }

  private getAvailableLetters(): Letter[] {
    const letterArray: Letter[] = [];
    for (let a = 0; a < 26; ++a) {
      const char = String.fromCharCode('A'.charCodeAt(0) + a);
      letterArray.push({
        letter: char,
        contentAvailable: Object.keys(GlossarJSON[char]).length > 0
      });
    }
    return letterArray;
  }
}
