/**
 * Payment types
 *
 * single: only one time, for one year
 * lifelong: begin by legal age of retirement
 * periodic: for a determinate lap of time
 */
export enum PaymentType {
  single = 'einmalig',
  lifelong = 'lebenslang monatlich',
  periodic = 'mehrere Jahre jährlich'
}
