import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PensionChartComponent} from './pension-chart/pension-chart.component';
import {DbkInfoboxModule, DbkResponsiveContainerModule} from '@webgui-ng/components';
import {GoogleChartsModule} from 'angular-google-charts';


@NgModule({
  declarations: [
    PensionChartComponent
  ],
  exports: [PensionChartComponent],
  imports: [
    CommonModule,
    DbkResponsiveContainerModule,
    GoogleChartsModule,
    DbkInfoboxModule
  ]
})
export class ChartsModule {
}
