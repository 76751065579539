import {Component, OnInit} from '@angular/core';
import {PersonaService} from '../../persona.service';
import {Persona} from '../../persona.Persona';
import {Observable, Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.scss']
})
export class PersonaComponent implements OnInit {
  private persona$: Observable<Persona>;
  private personaSubscription: Subscription;
  private persona: Persona;

  /**
   * constructor
   *
   * @param personaService service to simulate a login
   * @param router app router
   */
  constructor(public personaService: PersonaService, public router: Router) {
  }

  /**
   * Lifecycle method that will be called if this component gets initialized.
   */
  ngOnInit() {
    this.persona$ = this.personaService.usePersona();
    this.personaSubscription = this.persona$.subscribe(value => this.persona = value);
  }

}
