import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {PersonaService} from './persona.service';
import {StorageService} from './storageService/storage.service';
import {AuthenticationService} from './_authentication/authentication.service';
import {User} from './_authentication/user';
import {MenubarDirective} from './menubar.directive';
import {Subscription} from 'rxjs';
import {MenuBarService} from './menuBarService/menu-bar.service';
import {HeaderComponent} from './header/header.component';
import {GuidedTourService} from '@webgui-ng/components';
import {INTRODUCTION_TOUR_OPTIONS, LOGGED_IN_TOUR_OPTIONS} from './shared/guidedTours/guided-tours';
import {InternalMessageService} from './shared/internal-message-service/internal-message.service';
import {InternalMessage} from './shared/internal-message-service/internal-message';
import {filter} from 'rxjs/operators';
import {Routes} from './routes';
import {GuidedToursLabels} from './shared/guidedTours/guided-tours-labels';
import {TitleService} from './shared/titleService/title.service';
import {MiscLabels} from './misc.labels';
import {NgcCookieConsentService} from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {

  currentUser: User;
  title = 'Säulenübergreifende digitale Altersvorsorgeinformation';

  GuidedToursLabels = GuidedToursLabels;

  applicationMenuModel: MenuItem[];
  @ViewChild(MenubarDirective, {static: true}) menuBarHost: MenubarDirective;
  private applicationMenuModelSubscription: Subscription;

  private internalMessageSubscription: Subscription;
  private guidedToursSubscription: Subscription;

  /**
   * Constructor for the app component.
   */
  constructor(public personaService: PersonaService,
              private storageService: StorageService,
              private authenticationService: AuthenticationService,
              public route: ActivatedRoute,
              public router: Router,
              private menubarService: MenuBarService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private cdr: ChangeDetectorRef,
              private readonly guidedTourService: GuidedTourService,
              private internalMessageService: InternalMessageService,
              private titleService: TitleService,
              private ccService: NgcCookieConsentService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  /**
   * Initialisation with BehaviorSubject on Menubar
   */
  ngOnInit(): void {

    // Initialize the title service so that it listens to any route change events in order to provide a dynamic title.
    this.titleService.init();

    this.applicationMenuModelSubscription = this.menubarService.getMenuBar$().subscribe((newMenuBar) => {
      // new model from subject behavior
      this.applicationMenuModel = newMenuBar;
      // create dynamically the MenuBar for each change of BehaviorSubject
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(HeaderComponent);
      const viewContainerRef = this.menuBarHost.viewContainerRef;
      viewContainerRef.clear();
      this.cdr.detectChanges();
      const componentRef = viewContainerRef.createComponent(componentFactory);
      (componentRef.instance as HeaderComponent).menuModel = this.applicationMenuModel;
    });
    this.setupInternalMessaging();
  }

  /**
   * destroy method
   */
  ngOnDestroy(): void {
    if (this.applicationMenuModelSubscription) {
      this.applicationMenuModelSubscription.unsubscribe();
    }
    if (this.internalMessageSubscription) {
      this.internalMessageSubscription.unsubscribe();
    }
    if (this.guidedToursSubscription) {
      this.guidedToursSubscription.unsubscribe();
    }
  }

  /**
   * This method sets up the internal messaging for the current app component. It subscribes
   * to the internal messaging pipeline and listens for any messages that are sent to 'AppComponent'.
   */
  private setupInternalMessaging(): void {

    // Subscribe to the internal message service so that we can react to any internal messages.
    this.internalMessageSubscription = this.internalMessageService.getMessage().pipe(
      filter((message: InternalMessage) => message.receiver === 'AppComponent')
    ).subscribe((message: InternalMessage) => {

      // Start the guided tour in case that it has been triggered.
      if (message.content.command === 'startTour') {
        this.router.navigate([Routes.HOME_ROUTE]);
        if (this.guidedToursSubscription) {
          this.guidedToursSubscription.unsubscribe();
        }
        this.guidedTourService.startTour(INTRODUCTION_TOUR_OPTIONS);
      } else if (message.content.command === 'startLoggedInTour') {
        this.storageService.setGuidedTourActive(false);
        this.router.navigate([Routes.HOME_ROUTE]);
        this.guidedTourService.startTour(LOGGED_IN_TOUR_OPTIONS);
      }
    });
  }

  /**
   * Every time a new component is instantiated an activate event is emitted and we change the position of the content area to the top
   *
   * @param event an activate event
   */
  private onActivate(event: Event) {
    const x = document.getElementsByClassName('dbk-application-content-inner')[0];
    x.scrollTop = 0;

    // Yeah. Stupid but faster than everything else we could have done to get a "simplified" layout for the info page
    const base = (this.router.url === '/');
    const infoP = (this.router.url === '/' + Routes.INFO_ROUTE);
    const hidden = (base || infoP);

    const loginButton = document.getElementsByClassName('loginAction')[0];
    const menu = document.getElementsByClassName('dbk-menu-bar')[0];
    if (loginButton) {
      if (hidden) {
        loginButton.setAttribute('hidden', 'hidden');
      } else {
        loginButton.removeAttribute('hidden');
      }
    }
    if (menu) {
      if (hidden) {
        menu.setAttribute('hidden', 'hidden');
      } else {
        menu.removeAttribute('hidden');
      }
    }

  }
}
