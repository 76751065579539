import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-stock-foto',
  templateUrl: './stock-foto.component.html',
  styleUrls: ['./stock-foto.component.scss']
})
export class StockFotoComponent implements OnInit {

  @Input() imageNumber: number = null;
  public randomNumber: number;

  constructor() { }

  ngOnInit() {
    this.randomNumber = this.getRandomInt(1, 4);
  }

  /**
   * Returns a random whole number between min (inclusive) and max (inclusive)
   *
   * @param min the minimum whole number to be generated
   * @param max the maximum whole number to be generated
   */
  private getRandomInt(min, max): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    const randomInt = Math.floor(Math.random() * (max - min)) + min;

    return randomInt;
  }

}
