/**
 * The File interface is used to append additional files
 * into an Institution. It consists of a string-pair which
 * hold a descriptive text (for the user) and a path where
 * the file is located at.
 */
export interface File {
  displayText: string;
  path: string;
  date: string;
}

export interface Institution {
  id: number;
  agencyID: number;
  name: string;
  type: string;
  institutionType: string;
  productName: string;
  serviceType: string;
  contractType?: string;
  paymentCycle: string;
  paymentCycleYears: string;
  retirementAge: number | string;
  dateOfEntry: string;
  dateOfExit: string;
  value1: number | string;
  value2: number | string;
  value3_1: number | string;
  value3_2: number | string;
  value3_3: number | string;
  value3_4: number | string;
  value3_5: number | string;
  value3_6: number | string;
  annuityFactorGuaranteed: number;
  annuityFactorNotGuaranteed: number;
  taxAndSocialInsuranceContributions: string;
  additionalInformation: string;
  additionalFiles: File[];
  isExpanded?: boolean;
}

/**
 * Possible Institution Types
 * grv: '1. Säule: Gesetzliche Rentenversicherung'
 * bav: '2. Säule: Betriebliche Altersversorgung'
 * pav: '3. Säule: Private Altersversorgung'
 */
export enum InstitutionType {
  grv = 'GRV',
  bav = 'bAV',
  pav = 'pAV',
  all = 'all'
}

export enum InstitutionTypeLabels {
  grv = 'Gesetzliche Rente',
  bav = 'Betriebliche Vorsorge',
  pav = 'Private Vorsorge'
}

export enum InstitutionLabels {
  name = 'Träger',
  type = 'Träger',
  institutionType = 'Trägerart',
  productName = 'Vertragsart',
  serviceType = 'Art der Leistung',
  safePaymentsIfContinued = 'Sichere Leistung bei Fortführung',
  paymentsAlreadyReached = 'Schon erreichte Leistung',
  paymentCycle = 'Zahlungsart',
  paymentCycleYears = 'bei periodischer Zahlungsweise: Anzahl Jahre',
  retirementAge = 'Alter bei Auszahlung',
  dateOfEntry = 'Datum Eintritt',
  dateOfExit = 'Datum Austritt / Beitragsfrei',
  value1 = 'Sichere Leistung bei Fortführung',
  value2 = 'Schon erreichte Leistung',
  value3_1 = 'Erreichbare, nicht garantierte Leistung | Szenario 1',
  value3_2 = 'Erreichbare, nicht garantierte Leistung | Szenario 2',
  value3_3 = 'Erreichbare, nicht garantierte Leistung | Szenario 3',
  value3_4 = 'Erreichbare, nicht garantierte Leistung | Szenario 4',
  value3_5 = 'Erreichbare, nicht garantierte Leistung | Szenario 5',
  value3_6 = 'Erreichbare, nicht garantierte Leistung | Szenario 6',
  annuityFactorGuaranteed = 'Rentenfaktor garantiert',
  annuityFactorNotGuaranteed = 'Rentenfaktor nicht garantiert',
  taxAndSocialInsuranceContributions = 'Steuer + Sozialabgaben',
  additionalInformation = 'weitere Infos',
  additionalFiles = 'Angehängte Dokumente',
}

export enum InstitutionHelpTexts {
  value1 = 'Sichere Leistung bei Fortführung',
  value2 = 'Schon erreichte Leistung (ohne Beitragszahlung)',
  value3_1 = 'Erreichbare, aber nicht garantierte Werte (Wie könnten sich die Leistungen entwickeln?) | Szenario 1',
  value3_2 = 'Erreichbare, aber nicht garantierte Werte (Wie könnten sich die Leistungen entwickeln?) | Szenario 2',
  value3_3 = 'Erreichbare, aber nicht garantierte Werte (Wie könnten sich die Leistungen entwickeln?) | Szenario 3',
  value3_4 = 'Erreichbare, aber nicht garantierte Werte (Wie könnten sich die Leistungen entwickeln?) | Szenario 4',
  value3_5 = 'Erreichbare, aber nicht garantierte Werte (Wie könnten sich die Leistungen entwickeln?) | Szenario 5',
  value3_6 = 'Erreichbare, aber nicht garantierte Werte (Wie könnten sich die Leistungen entwickeln?) | Szenario 6',
}
