import {Component, Input, OnInit} from '@angular/core';
import {DbkDataViewColumnConfigData} from '@webgui-ng/components/lib/dbk-data-view/dbk-data-view-column-config-data';

@Component({
  selector: 'app-table-summary-column',
  templateUrl: './table-summary-column.component.html',
  styleUrls: ['./table-summary-column.component.scss']
})
export class TableSummaryColumnComponent implements OnInit {

  /**
   * informations for view
   */
  @Input() accumulatedPensionForAllColumns;
  /**
   * hint type (warning, info, ...)
   */
  @Input() hintType = 'warning';
  /**
   * hint text
   */
  @Input() hintText: string;
  /**
   * width of tag element (default is auto else in px)
   */
  @Input() maxWidth = 'auto';


  columnsConfigData: DbkDataViewColumnConfigData[] = [
    {
      label: '',
      dataKey: 'paymentType',
      columnClasses: 'combined-column-paymentcycle firstColumn'
    },
    {
      label: 'Alter bei Auszahlung',
      dataKey: 'age',
      columnClasses: 'zahlungszeitraum secondColumn'
    },
    {
      label: 'Betrag',
      dataKey: 'amount',
      columnClasses: 'nowrap bold betrag'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
