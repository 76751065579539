import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccumulatedPensionModule} from '../../accumulated-pension/accumulated-pension.module';
import {GoogleChartsModule} from 'angular-google-charts';
import {DbkButtonModule, DbkDividerModule, DbkFormsModule, DbkGuidedTourModule, DbkInfoboxModule} from '@webgui-ng/components';
import {ButtonModule, CardModule, SelectButtonModule, TabViewModule} from 'primeng/primeng';
import {FormsModule} from '@angular/forms';
import {ChartsModule} from '../../charts/charts.module';
import {SettingsToolboxModule} from '../../shared/settings-toolbox/settings-toolbox.module';
import {HomeComponent} from './home.component';
import {PensionModule} from '../../pension/pension.module';
import {RouterModule} from '@angular/router';
import {AppModule} from '../../app.module';
import {StockFotoModule} from '../../_components/stock-foto/stock-foto.module';


@NgModule({
  declarations: [HomeComponent,
  ],
  imports: [
    CommonModule,
    AccumulatedPensionModule,
    GoogleChartsModule,
    DbkInfoboxModule,
    SelectButtonModule,
    DbkButtonModule,
    FormsModule,
    ChartsModule,
    SettingsToolboxModule,
    DbkGuidedTourModule.forChild(),
    TabViewModule,
    CardModule,
    PensionModule,
    ButtonModule,
    RouterModule,
    DbkFormsModule,
    StockFotoModule,
    DbkDividerModule
  ]
})
export class HomeModule {
}
