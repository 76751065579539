import { Injectable } from '@angular/core';
import AgenciesJSON from '../assets/agencies.json';
import {Agency} from './agency.Agency';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

  private agencies: Agency[];

  constructor() {
    this.agencies = AgenciesJSON.Agencies;
  }

  /**
   * Returns the agency specified by the identifier
   * @param id the identifier of the agency
   */
  getAgency(id: number): Agency {
    const agency = this.agencies[id - 1];

    return agency;
  }
}
