import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {PersonaService} from '../../persona.service';
import {PensionChartDataService} from './service/pension-chart-data.service';
import {Observable, Subscription} from 'rxjs';
import {Persona} from '../../persona.Persona';
import {GoogleChartComponent} from 'angular-google-charts';
import {StorageService} from '../../storageService/storage.service';

@Component({
  selector: 'app-pension-chart',
  templateUrl: './pension-chart.component.html',
  styleUrls: ['./pension-chart.component.scss']
})
export class PensionChartComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('chart', {static: false}) chart: GoogleChartComponent;

  public persona: Persona = null;
  public chartDataServiceSubscription: Subscription = null;

  @Input() dynamicResize = true;

  // tslint:disable-next-line
  @Input() dynamicResizeStyle = {'width': '100%', 'min-height': '350px'};

  @Input() public hint: string = null;

  @Input() public showDataForColumn = 'all';

  // The google chart expects the following parameters to be filled.
  public chartType = 'SteppedAreaChart';
  public chartHeight = 700;
  public chartWidth = 1200;
  public chartColumns: string[] = [];
  public chartColumnRoles: object[] = [];
  public chartData: any = [];
  public chartOptions: object = {};


  private personaSubscription: Subscription;
  private persona$: Observable<Persona>;

  /**
   * Constructor of the pension chart component.
   * @param personaService The data service necessary to access the current selected persona object.
   * @param dataService The data service responsible for providing all necessary data for the chart.
   */
  constructor(
    private personaService: PersonaService,
    private dataService: PensionChartDataService, private storageService: StorageService
  ) {
    this.persona$ = this.personaService.usePersona();
    this.personaSubscription = this.persona$.subscribe(value => this.persona = value);
  }

  getMonthlyAnnualScale(): string {
    return this.storageService.getMonthlyAnnualScale();
  }

  /**
   * Ng-Lifecycle method for OnInit.
   */
  ngOnInit(): void {
    this.chartDataServiceSubscription = this.dataService.getDataForPersona(this.persona, this.showDataForColumn).subscribe(
      data1 => {

        this.chartData = data1.data;
        this.chartColumns = data1.columns;
        this.chartColumnRoles = data1.columnRoles;
        this.chartOptions = data1.options;
      });
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.chartDataServiceSubscription = this.dataService.getDataForPersona(this.persona, this.showDataForColumn).subscribe(
      data1 => {

        this.chartData = data1.data;
        this.chartColumns = data1.columns;
        this.chartColumnRoles = data1.columnRoles;
        this.chartOptions = data1.options;
      });
    if (this.chart) {
      this.chart.wrapper = this.chart.wrapper.clone();
    }
  }


  /**
   * Ng-Lifecycle method for OnDestroy.
   */
  ngOnDestroy(): void {
    if (this.chartDataServiceSubscription) {
      this.chartDataServiceSubscription.unsubscribe();
    }
  }
}
